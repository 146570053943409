import Map, { Marker, NavigationControl, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { useState } from "react";
import tafas from "./LOGO_TAFAS.png";
import autoone from "./AUTO_ONE_Logo_car_parts.png";
import noli_toni from "./gallery/noli_toni.webp";

export default function Contact() {
  const [selectedMarker, setSelectedMarker] = useState();
  const [markers, setMarkers] = useState([
    {
      id: 1,
      longitude: 21.44931957879079,
      latitude: 42.47036139113119,
      popup_enabled: false,
      desc: "TAFA-S",
      images: [tafas],
    },
    {
      id: 2,
      longitude: 21.116345463003007,
      latitude: 42.644571699554184,
      popup_enabled: false,
      desc: "Auto One - Auto pjese",
      images: [tafas, autoone],
    },
    {
      id: 3,
      longitude: 21.76400344820896,
      latitude: 42.46116693278628,
      popup_enabled: false,
      desc: "Auto pjese & Auto Delovi “Noli Toni”",
      images: [tafas, noli_toni],
    },
  ]);

  const getGoogleMapsUrl = (latitude, longitude) => {
    return window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`
    );
  };

  return (
    <Map
      mapboxAccessToken="pk.eyJ1IjoibmV1ZXIiLCJhIjoiY2twY2p2NGV4MWVlbjJ2cDduYXRpNWhmMSJ9.1Lp7RyWjw0mxnKda0Yfjug"
      // mapboxAccessToken={process.env.REACT_APP_MAP_BOX}
      style={{ width: "100%", height: "500px" }}
      mapLib={import("mapbox-gl")}
      initialViewState={{
        longitude: 21.438315,
        latitude: 42.563486,
        zoom: 8.5,
      }}
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
    >
      {markers?.map((marker, index) => (
        <Marker
          key={index}
          latitude={marker.latitude}
          longitude={marker.longitude}
          onClick={() => setSelectedMarker(marker)}
        >
          <div className="!p-1 w-24 h-8 self-center flex flex-col justify-center items-center rounded-lg shadow-lg">
            {marker?.images?.map((row, index) => {
              return <img src={row} key={index} />;
            })}
          </div>
          {selectedMarker?.id === marker.id && (
            <Popup
              closeButton={false}
              latitude={marker.latitude}
              longitude={marker.longitude}
              onClose={() => setSelectedMarker(null)}
              closeOnClick={false}
            >
              <div className="p-2 relative rounded-md">
                <div
                  onClick={() => setSelectedMarker(null)}
                  className="absolute top-0 right-2 cursor-pointer"
                >
                  x
                </div>
                <div className="mt-3">
                  {marker?.images?.map((row, index) => {
                    const isAutoOne = row.includes("AUTO_ONE_Logo_car_parts");

                    console.log({ isAutoOne, row });
                    return (
                      <img
                        src={row}
                        key={index}
                        className={isAutoOne && "bg-gray-800"}
                      />
                    );
                  })}
                  <div>{marker.desc}</div>
                  <div
                    className="underline cursor-pointer"
                    onClick={() =>
                      getGoogleMapsUrl(marker.latitude, marker.longitude)
                    }
                  >
                    Navigate to this location
                  </div>
                </div>
              </div>
            </Popup>
          )}
        </Marker>
      ))}

      <NavigationControl />
    </Map>
  );
}
