import { useRef } from "react";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";
import { useState } from "react";
import Loading from "./Loading";
import { getMessage } from "./Util";

export default function ContactUs() {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendMail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(form.current);

    if (
      !formData.get("firstname") ||
      !formData.get("email") ||
      !formData.get("comment")
    ) {
      setLoading(false);
      return toast.error(getMessage("contactUsRequirement"));
    }
    const email = await emailjs.sendForm(
      process.env.REACT_APP_EMAIL_SERVICE,
      process.env.REACT_APP_EMAIL_TEMPLATE,
      form.current,
      process.env.REACT_APP_EMAIL_USER
    );

    email.status === 200
      ? toast.success("Email sent successfully!")
      : toast.error("Something went wrong. Please try again later.");
    form.current.reset();

    setLoading(false);
  };

  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full transform translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute right-full bottom-0 transform -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-[#ce1b1b] sm:text-4xl">
            {getMessage("ContactUsPageTitle")}
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            {getMessage("ContactUsPageDesc")}
          </p>
        </div>
        <div className="mt-12">
          <form
            onSubmit={sendMail}
            ref={form}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div>
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                {getMessage("contactUsFirstName")}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  autoComplete="given-name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-[#eb1d1d] focus:border-[#eb1d1d] border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                {getMessage("contactUsLastName")}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  autoComplete="family-name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-[#eb1d1d] focus:border-[#eb1d1d] border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-medium text-gray-700"
              >
                {getMessage("contactUsCompany")}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="company"
                  id="company"
                  autoComplete="organization"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-[#eb1d1d] focus:border-[#eb1d1d] border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                {getMessage("contactUsEmail")}
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-[#eb1d1d] focus:border-[#eb1d1d] border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="phone-number"
                className="block text-sm font-medium text-gray-700"
              >
                {getMessage("contactUsPhoneNumber")}
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="phonenumber"
                  id="phone-number"
                  autoComplete="tel"
                  className="py-3 px-4 block w-full focus:ring-[#eb1d1d] focus:border-[#eb1d1d] border-gray-300 rounded-md"
                  placeholder="+1 (555) 987-6543"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                {getMessage("contactUsMessage")}
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="comment"
                  rows={4}
                  className="py-3 px-4 block w-full shadow-sm focus:ring-[#eb1d1d] focus:border-[#eb1d1d] border border-gray-300 rounded-md"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#ce1b1b] hover:bg-[#eb1d1d] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#eb1d1d] transition-all"
              >
                {loading ? <Loading /> : getMessage("contactUsSend")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
