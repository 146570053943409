import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import sponsorship_1 from "./gallery/124.webp";
import sponsorship_2 from "./gallery/123.webp";
import sponsorship_3 from "./gallery/77.webp";
import sponsorship_4 from "./gallery/73.webp";
import sponsorship_5 from "./gallery/200.webp";
import { getMessage } from "./Util";

const sponsorships = [
  {
    image: sponsorship_1,
    title: getMessage("tributeToEneaKoliqi"),
    description: getMessage("signedJersey"),
  },
  {
    image: sponsorship_2,
    title: getMessage("signedJerseySeason"),
    description: getMessage("scGjilaniJersey"),
  },
  {
    image: sponsorship_3,
    title: getMessage("companySupport"),
    description: null,
  },
  {
    image: sponsorship_4,
    title: getMessage("helpForBoxer"),
    description: getMessage("supportForLeotrimRexhepi"),
  },
  {
    image: sponsorship_5,
    title: getMessage("gratitudeFromLeotrimRexhepi"),
  },
];

export default function Sponsorships() {
  return (
    <section>
      <div className="relative items-center w-full p-8">
        <div className="grid w-full grid-cols-1 gap-6 mx-auto lg:grid-cols-2">
          <PhotoProvider>
            {sponsorships?.map((item, index) => {
              return (
                <div className="p-6" key={index}>
                  <PhotoView src={item?.image}>
                    <img
                      className={`object-cover object-center w-full mb-8 h-96 rounded-lg cursor-pointer`}
                      src={item?.image}
                      alt="blog"
                    />
                  </PhotoView>

                  <h1 className="font-bold sm:text-xl text-2xl mb-4 text-[#ce1b1b]">
                    {item?.title}
                  </h1>

                  {!!item?.description && (
                    <p class="mx-auto text-base leading-relaxed text-gray-500">
                      {item?.description}
                    </p>
                  )}
                </div>
              );
            })}
          </PhotoProvider>
        </div>
      </div>
    </section>
  );
}
