import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import autoOneLogo from "./AUTO_ONE_Logo_car_parts.png";
import fourULogo from "./four_u_logo.png";
import aotsGroupLogo from "./aots_group_logo.png";
import logo_1 from "./logos/logo_1.webp";
import logo_2 from "./logos/logo_2.webp";
import logo_3 from "./logos/logo_3.webp";
import logo_4 from "./logos/logo_4.webp";
import logo_5 from "./logos/logo_5.webp";
import logo_6 from "./logos/logo_6.webp";
import logo_7 from "./logos/logo_7.webp";
import logo_8 from "./logos/logo_8.webp";
import logo_9 from "./logos/logo_9.webp";
import logo_10 from "./logos/logo_10.webp";
import logo_11 from "./logos/logo_11.webp";
import logo_12 from "./logos/logo_12.webp";
import logo_13 from "./logos/logo_13.webp";
import logo_14 from "./logos/logo_14.webp";
import logo_15 from "./logos/logo_15.webp";
import logo_16 from "./logos/logo_16.webp";
import logo_18 from "./logos/logo_18.webp";
import logo_19 from "./logos/logo_19.webp";
import logo_20 from "./logos/logo_20.webp";
import logo_21 from "./logos/logo_21.webp";
import logo_22 from "./logos/logo_22.webp";
import logo_23 from "./logos/logo_23.webp";
import logo_24 from "./logos/logo_24.webp";
import logo_25 from "./logos/logo_25.webp";
import logo_26 from "./logos/logo_26.webp";
import logo_27 from "./logos/logo_27.webp";
import logo_28 from "./logos/logo_28.webp";
import logo_29 from "./logos/logo_29.webp";
import logo_30 from "./logos/logo_30.webp";
import logo_31 from "./logos/logo_31.webp";
import logo_32 from "./logos/logo_32.webp";
import logo_33 from "./logos/logo_33.webp";
import logo_34 from "./logos/logo_34.webp";
import logo_35 from "./logos/logo_35.webp";
import logo_36 from "./logos/logo_36.webp";
import logo_37 from "./logos/logo_37.webp";
import logo_38 from "./logos/logo_38.webp";
import logo_39 from "./logos/logo_39.webp";
import logo_40 from "./logos/logo_40.webp";
import logo_41 from "./logos/logo_41.webp";
import logo_42 from "./logos/logo_42.webp";
import logo_43 from "./logos/logo_43.webp";
import logo_44 from "./logos/logo_44.webp";
import logo_45 from "./logos/logo_45.webp";
import logo_46 from "./logos/logo_46.webp";
import logo_47 from "./logos/logo_47.webp";
import logo_48 from "./logos/logo_48.webp";
import logo_49 from "./logos/logo_49.webp";
import logo_50 from "./logos/logo_50.webp";
import logo_51 from "./logos/logo_51.webp";
import logo_52 from "./logos/logo_52.webp";
import logo_53 from "./logos/logo_53.webp";
import logo_54 from "./logos/logo_54.webp";
import logo_55 from "./logos/logo_55.webp";
import logo_56 from "./logos/logo_56.webp";
import logo_57 from "./logos/logo_57.webp";
import logo_58 from "./logos/logo_58.webp";
import logo_59 from "./logos/logo_59.webp";
import logo_60 from "./logos/logo_60.webp";
import logo_61 from "./logos/logo_61.webp";
import logo_62 from "./logos/logo_62.webp";
import logo_63 from "./logos/logo_63.webp";
import logo_64 from "./logos/logo_64.webp";
import logo_65 from "./logos/logo_65.webp";
import logo_66 from "./logos/logo_66.webp";
import logo_67 from "./logos/logo_67.webp";
import logo_68 from "./logos/logo_68.webp";
import logo_69 from "./logos/logo_69.webp";
import logo_70 from "./logos/logo_70.webp";
import logo_71 from "./logos/logo_71.webp";
import logo_72 from "./logos/logo_72.webp";
import logo_73 from "./logos/logo_73.webp";
import logo_74 from "./logos/logo_74.webp";
import logo_75 from "./logos/logo_75.webp";
import logo_76 from "./logos/logo_76.webp";
import logo_77 from "./logos/logo_77.webp";
import logo_78 from "./logos/logo_78.webp";
import logo_79 from "./logos/logo_79.webp";
import logo_80 from "./logos/logo_80.webp";
import logo_81 from "./logos/logo_81.webp";
import logo_82 from "./logos/logo_82.webp";
import logo_83 from "./logos/logo_83.webp";
import logo_84 from "./logos/logo_84.webp";
import logo_85 from "./logos/logo_85.webp";
import logo_86 from "./logos/logo_86.webp";
import logo_87 from "./logos/logo_87.webp";
import logo_88 from "./logos/logo_88.webp";
import logo_89 from "./logos/logo_89.webp";
import logo_90 from "./logos/logo_90.webp";
import logo_92 from "./logos/logo_92.webp";
import logo_93 from "./logos/logo_93.webp";
import logo_94 from "./logos/logo_94.webp";
import logo_95 from "./logos/logo_95.webp";
import logo_96 from "./logos/logo_96.webp";
import logo_97 from "./logos/logo_97.webp";
import logo_99 from "./logos/logo_99.webp";
import logo_100 from "./logos/logo_100.webp";
import logo_101 from "./logos/logo_101.webp";
import logo_102 from "./logos/logo_102.webp";
import logo_103 from "./logos/logo_103.webp";
import logo_104 from "./logos/logo_104.webp";
import logo_105 from "./logos/logo_105.webp";
import logo_106 from "./logos/logo_106.webp";
import logo_107 from "./logos/logo_107.webp";
import logo_108 from "./logos/logo_108.webp";
import logo_110 from "./logos/logo_110.webp";
import logo_111 from "./logos/logo_111.webp";
import logo_112 from "./logos/logo_112.webp";
import logo_114 from "./logos/logo_114.webp";
import logo_115 from "./logos/logo_115.webp";
import logo_116 from "./logos/logo_116.webp";
import logo_118 from "./logos/logo_118.webp";
import logo_119 from "./logos/logo_119.webp";
import logo_120 from "./logos/logo_120.webp";
import logo_121 from "./logos/logo_121.webp";
import logo_122 from "./logos/logo_122.webp";
import logo_123 from "./logos/logo_123.webp";
import logo_124 from "./logos/logo_124.webp";
import logo_125 from "./logos/logo_125.webp";
import logo_126 from "./logos/logo_126.webp";
import logo_127 from "./logos/logo_127.webp";
import logo_128 from "./logos/logo_128.webp";
import logo_130 from "./logos/logo_130.webp";
import logo_131 from "./logos/logo_131.webp";
import logo_132 from "./logos/logo_132.webp";
import logo_133 from "./logos/logo_133.webp";
import logo_135 from "./logos/logo_135.webp";
import logo_138 from "./logos/logo_138.webp";
import logo_139 from "./logos/logo_139.webp";
import logo_140 from "./logos/logo_140.webp";
import logo_141 from "./logos/logo_141.webp";
import logo_142 from "./logos/logo_142.webp";
import logo_143 from "./logos/logo_143.webp";
import logo_144 from "./logos/logo_144.webp";
import logo_145 from "./logos/logo_145.webp";
import logo_146 from "./logos/logo_146.webp";
import logo_147 from "./logos/logo_147.webp";
import logo_148 from "./logos/logo_148.webp";
import logo_149 from "./logos/logo_149.webp";
import logo_150 from "./logos/logo_150.webp";
import logo_151 from "./logos/logo_151.webp";
import logo_152 from "./logos/logo_152.webp";
import logo_153 from "./logos/logo_153.webp";
import logo_154 from "./logos/logo_154.webp";
import logo_155 from "./logos/logo_155.webp";

// import auto_one from "./logos/auto_one.webp";
import noli_toni from "./gallery/noli_toni.webp";
import rentcar4u from "./gallery/rentcar4u.webp";
import tafas_new from "./gallery/tafas_new.webp";

import { getMessage } from "./Util";

const exclusive = [
  {
    image: fourULogo,
    backgroud: "bg-white",
  },
  {
    image: autoOneLogo,
    backgroud: "bg-gray-800",
  },
  {
    image: aotsGroupLogo,
    backgroud: "bg-white",
  },
  {
    image: noli_toni,
    backgroud: "bg-white",
  },
  {
    image: rentcar4u,
    backgroud: "bg-white",
  },
  {
    image: tafas_new,
    backgroud: "bg-white",
  },
];

const others = [
  { image: logo_1 },
  { image: logo_2 },
  { image: logo_3 },
  { image: logo_4 },
  { image: logo_5 },
  { image: logo_6 },
  { image: logo_7 },
  { image: logo_8 },
  { image: logo_9 },
  { image: logo_10 },
  { image: logo_11 },
  { image: logo_12 },
  { image: logo_13 },
  { image: logo_14 },
  { image: logo_15 },
  { image: logo_16 },
  { image: logo_18 },
  { image: logo_19 },
  { image: logo_20 },
  { image: logo_21 },
  { image: logo_22 },
  { image: logo_23 },
  { image: logo_24 },
  { image: logo_25 },
  { image: logo_26 },
  { image: logo_27 },
  { image: logo_28 },
  { image: logo_29 },
  { image: logo_30 },
  { image: logo_31 },
  { image: logo_32 },
  { image: logo_33 },
  { image: logo_34 },
  { image: logo_35 },
  { image: logo_36 },
  { image: logo_37 },
  { image: logo_38 },
  { image: logo_39 },
  { image: logo_40 },
  { image: logo_41 },
  { image: logo_42 },
  { image: logo_43 },
  { image: logo_44 },
  { image: logo_45 },
  { image: logo_46 },
  { image: logo_47 },
  { image: logo_48 },
  { image: logo_49 },
  { image: logo_50 },
  { image: logo_51 },
  { image: logo_52 },
  { image: logo_53 },
  { image: logo_54 },
  { image: logo_55 },
  { image: logo_56 },
  { image: logo_57 },
  { image: logo_58 },
  { image: logo_59 },
  { image: logo_60 },
  { image: logo_61 },
  { image: logo_62 },
  { image: logo_63 },
  { image: logo_64 },
  { image: logo_65 },
  { image: logo_66 },
  { image: logo_67 },
  { image: logo_68 },
  { image: logo_69 },
  { image: logo_70 },
  { image: logo_71 },
  { image: logo_72 },
  { image: logo_73 },
  { image: logo_74 },
  { image: logo_75 },
  { image: logo_76 },
  { image: logo_77 },
  { image: logo_78 },
  { image: logo_79 },
  { image: logo_80 },
  { image: logo_81 },
  { image: logo_82 },
  { image: logo_83 },
  { image: logo_84 },
  { image: logo_85 },
  { image: logo_86 },
  { image: logo_87 },
  { image: logo_88 },
  { image: logo_89 },
  { image: logo_90 },
  { image: logo_92 },
  { image: logo_93 },
  { image: logo_94 },
  { image: logo_95 },
  { image: logo_96 },
  { image: logo_97 },
  { image: logo_99 },
  { image: logo_100 },
  { image: logo_101 },
  { image: logo_102 },
  { image: logo_103 },
  { image: logo_104 },
  { image: logo_105 },
  { image: logo_106 },
  { image: logo_107 },
  { image: logo_108 },
  { image: logo_110 },
  { image: logo_111 },
  { image: logo_112 },
  { image: logo_114 },
  { image: logo_115 },
  { image: logo_116 },
  { image: logo_118 },
  { image: logo_119 },
  { image: logo_120 },
  { image: logo_121 },
  { image: logo_122 },
  { image: logo_123 },
  { image: logo_124 },
  { image: logo_125 },
  { image: logo_126 },
  { image: logo_127 },
  { image: logo_128 },
  { image: logo_130 },
  { image: logo_131 },
  { image: logo_132 },
  { image: logo_133 },
  { image: logo_135 },
  { image: logo_138 },
  { image: logo_139 },
  { image: logo_140 },
  { image: logo_141 },
  { image: logo_142 },
  { image: logo_143 },
  { image: logo_144 },
  { image: logo_145 },
  { image: logo_146 },
  { image: logo_147 },
  { image: logo_148 },
  { image: logo_149 },
  { image: logo_150 },
  { image: logo_151 },
  { image: logo_152 },
  { image: logo_153 },
  { image: logo_154 },
  { image: logo_155 },
];

const Brand = ({ title = getMessage("brandExclusive"), data = [] }) => {
  return (
    <>
      <p className="text-center text-base md:text-2xl font-bold uppercase text-[#eb1d1d] tracking-wider">
        {title}
      </p>
      <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
        <PhotoProvider>
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className={`col-span-1 flex justify-center py-8 px-8 ${
                  item?.backgroud ?? "bg-gray-50"
                }`}
              >
                <PhotoView src={item?.image}>
                  <img
                    className="max-h-20 object-contain cursor-pointer"
                    src={item?.image}
                    alt={"logo_" + index}
                  />
                </PhotoView>
              </div>
            );
          })}
        </PhotoProvider>
      </div>
    </>
  );
};

export default function Brands() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <Brand data={exclusive} />
        <div className="mt-8">
          <Brand title={getMessage("brandOther")} data={others} />
        </div>
      </div>
    </div>
  );
}
