import Footer from "./Footer";
import NavBar from "./NavBar";
import Blog from "./Blog";
import QualityPolicy from "./QualityPolicy";
import Brands from "./Brands";
import ContactUs from "./ContactUs";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Home";
import { Toaster } from "react-hot-toast";
import Gallery from "./Gallery";
import Sponsorships from "./Sponsorships";
import Loader from "./Loader";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };

    window.addEventListener("load", handleLoad);

    return () => window.removeEventListener("load", handleLoad);
  }, []);

  return (
    <>
      <Loader loaded={loading}/>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/quality-policy" element={<QualityPolicy />} />
        <Route path="/company" element={<Blog />} />
        <Route path="/sponsorship" element={<Sponsorships />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
      <Toaster position="bottom-center" />
      <Footer />
    </>
  );
}

export default App;
