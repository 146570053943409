import gallery_4 from "./gallery/4.webp";
import gallery_9 from "./gallery/9.webp";
import gallery_12 from "./gallery/12.webp";
import gallery_14 from "./gallery/14.webp";
import gallery_20 from "./gallery/20.webp";
import gallery_22 from "./gallery/22.webp";
import gallery_23 from "./gallery/23.webp";
import gallery_25 from "./gallery/25.webp";
import gallery_30 from "./gallery/30.webp";
import gallery_31 from "./gallery/31.webp";
import gallery_33 from "./gallery/33.webp";
import gallery_35 from "./gallery/35.webp";
import gallery_36 from "./gallery/36.webp";
import gallery_38 from "./gallery/38.webp";
import gallery_39 from "./gallery/39.webp";
import gallery_42 from "./gallery/42.webp";
import gallery_43 from "./gallery/43.webp";
import gallery_44 from "./gallery/44.webp";
import gallery_45 from "./gallery/45.webp";
import gallery_47 from "./gallery/47.webp";
import gallery_48 from "./gallery/48.webp";
import gallery_49 from "./gallery/49.webp";
import gallery_50 from "./gallery/50.webp";
import gallery_51 from "./gallery/51.webp";
import gallery_52 from "./gallery/52.webp";
import gallery_56 from "./gallery/56.webp";
import gallery_57 from "./gallery/57.webp";
import gallery_59 from "./gallery/59.webp";
import gallery_61 from "./gallery/61.webp";
import gallery_62 from "./gallery/62.webp";
import gallery_63 from "./gallery/63.webp";
import gallery_64 from "./gallery/64.webp";
import gallery_65 from "./gallery/65.webp";
import gallery_66 from "./gallery/66.webp";
import gallery_68 from "./gallery/68.webp";
import gallery_69 from "./gallery/69.webp";
import gallery_70 from "./gallery/70.webp";
import gallery_76 from "./gallery/76.webp";
import gallery_78 from "./gallery/78.webp";
import gallery_79 from "./gallery/79.webp";
import gallery_95 from "./gallery/95.webp";
import gallery_97 from "./gallery/97.webp";
import gallery_99 from "./gallery/99.webp";
import gallery_100 from "./gallery/100.webp";
import gallery_101 from "./gallery/101.webp";
import gallery_102 from "./gallery/102.webp";
import gallery_103 from "./gallery/103.webp";
import gallery_104 from "./gallery/104.webp";
import gallery_105 from "./gallery/105.webp";
import gallery_106 from "./gallery/106.webp";
import gallery_107 from "./gallery/107.webp";
import gallery_108 from "./gallery/108.webp";
import gallery_109 from "./gallery/109.webp";
import gallery_112 from "./gallery/112.webp";
import gallery_113 from "./gallery/113.webp";
import gallery_114 from "./gallery/114.webp";
import gallery_116 from "./gallery/116.webp";
import gallery_117 from "./gallery/117.webp";
import gallery_118 from "./gallery/118.webp";
import gallery_119 from "./gallery/119.webp";
import gallery_120 from "./gallery/120.webp";
import gallery_121 from "./gallery/121.webp";
import gallery_122 from "./gallery/122.webp";
import gallery_125 from "./gallery/125.webp";
import gallery_126 from "./gallery/126.webp";
import gallery_127 from "./gallery/127.webp";
import gallery_131 from "./gallery/131.webp";
import gallery_133 from "./gallery/133.webp";

import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const data = [
  { image: gallery_4 },
  { image: gallery_9 },
  { image: gallery_12 },
  { image: gallery_14 },
  { image: gallery_20 },
  { image: gallery_22 },
  { image: gallery_23 },
  { image: gallery_25 },
  { image: gallery_30 },
  { image: gallery_31 },
  { image: gallery_33 },
  { image: gallery_35 },
  { image: gallery_36 },
  { image: gallery_38 },
  { image: gallery_39 },
  { image: gallery_42 },
  { image: gallery_43 },
  { image: gallery_44 },
  { image: gallery_45 },
  { image: gallery_47 },
  { image: gallery_48 },
  { image: gallery_49 },
  { image: gallery_50 },
  { image: gallery_51 },
  { image: gallery_52 },
  { image: gallery_56 },
  { image: gallery_57 },
  { image: gallery_59 },
  { image: gallery_61 },
  { image: gallery_62 },
  { image: gallery_63 },
  { image: gallery_64 },
  { image: gallery_65 },
  { image: gallery_66 },
  { image: gallery_68 },
  { image: gallery_69 },
  { image: gallery_70 },
  { image: gallery_76 },
  { image: gallery_78 },
  { image: gallery_79 },
  { image: gallery_95 },
  { image: gallery_97 },
  { image: gallery_99 },
  { image: gallery_100 },
  { image: gallery_101 },
  { image: gallery_102 },
  { image: gallery_103 },
  { image: gallery_104 },
  { image: gallery_105 },
  { image: gallery_106 },
  { image: gallery_107 },
  { image: gallery_108 },
  { image: gallery_109 },
  { image: gallery_112 },
  { image: gallery_113 },
  { image: gallery_114 },
  { image: gallery_116 },
  { image: gallery_117 },
  { image: gallery_118 },
  { image: gallery_119 },
  { image: gallery_120 },
  { image: gallery_121 },
  { image: gallery_122 },
  { image: gallery_125 },
  { image: gallery_126 },
  { image: gallery_127 },
  { image: gallery_131 },
  { image: gallery_133 },
];

export default function Gallery() {
  return (
    <div class="flex flex-wrap gap-4 p-8 justify-center">
      <PhotoProvider>
        {data.map((item, index) => {
          const widthClasses = ["w-80", "w-96", "w-[520px]"];
          const widthClass = widthClasses[(index % widthClasses.length) * 2];

          return (
            <PhotoView src={item.image}>
              <img
                key={index}
                className={`rounded-lg ${widthClass} h-80 object-cover cursor-pointer`}
                src={item.image}
                alt={index}
              />
            </PhotoView>
          );
        })}
      </PhotoProvider>
    </div>
  );
}
