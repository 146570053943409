import Contact from "./Contact";
import Part from "./Part";
import {useEffect, useState} from "react";
import FadeInOut from "./FadeInOut";

export default function Loader({loaded}) {
    const [active,setActive] = useState(false);
    const [doneAnimation, setDoneAnimation] = useState(false)
    useEffect(() => {
        setTimeout(()=>{
            setActive(true)
        })
        setTimeout(()=>{
            setDoneAnimation(true)
        }, 1800)
    }, []);
    return (
        <FadeInOut show={loaded || !doneAnimation} className={'absolute inset-0 z-10 bg-white'} onClick={()=>setActive(!active)}>
            <svg width="100%" height="100%" version="1.1" viewBox="0 0 99.963 18.541" id="svg4" className={active && 'active'} style={{maxWidth:'200px',margin:'0 auto'}}>
                <defs id="defs4"></defs>
                <g transform="translate(-54.504 -1752.6)" stroke="#000" strokeWidth=".4" id="g4">
                    <g transform="matrix(.26458 0 0 .26458 283.81 1666.9)" fill="#e91d25" id="g3" style={{stroke:'#e91d25',strokeOpacity:1}}>
                        <path transform="scale(.99079 1.0093)" d="m-864.59 321.69-10.124 13.146h23.722v37.397h15.639v-37.397h18.056l10.124-13.146zm123.89 50.542-31.58-45.707c-2.1909-3.1731-5.2884-5.6662-9.9725-5.6662-4.9107 0-8.0082 2.7198-10.124 5.6662l-32.411 45.707h18.51l23.722-33.544 9.0659 13.372c1.6621 2.4176 3.7775 5.5151 5.1373 7.3283-2.4176-0.1511-5.9684-0.1511-8.9903-0.1511h-12.692l-9.4436 12.994zm3.0906-50.542v50.542h15.714v-18.51h28.558l9.5192-12.314h-38.077v-6.5728h30.824l10.124-13.146zm126.84 50.542-31.58-45.707c-2.1909-3.1731-5.2884-5.6662-9.9725-5.6662-4.9107 0-8.0082 2.7198-10.124 5.6662l-32.411 45.707h18.51l23.722-33.544 9.0659 13.372c1.6621 2.4176 3.7775 5.5151 5.1373 7.3283-2.4176-0.1511-5.9684-0.1511-8.9903-0.1511h-12.692l-9.4436 12.994z" strokeWidth="1.5118" aria-label="TAFA " id="path1" style={{stroke: 'rgb(233, 29, 37)', strokeOpacity: 1}} className="svg-elem-1"></path>
                        <path transform="scale(.95151 1.051)" d="m-560.04 328.16c-3.6126 0-5.3436-0.97841-5.3436-3.5373s1.731-3.462 5.3436-3.462h34.922l10.01-13.096h-43.727c-15.278 0-21.6 6.1715-21.6 15.655 0 8.6551 5.9457 14.45 18.891 14.45h27.395c3.6878 0 5.3436 1.0537 5.3436 3.6126 0 2.6342-1.6558 3.6878-5.3436 3.6878h-38.083l-10.01 12.945h46.888c15.353 0 21.6-6.1715 21.6-16.482 0-9.182-6.6231-13.773-18.891-13.773z" strokeWidth="1.5118" aria-label="s" id="path2" style={{stroke: 'rgb(233, 29, 37)', strokeOpacity: 1}} className="svg-elem-2"></path>
                        <path transform="scale(.90188 1.1088)" d="m-653.84 310.18-8.2776 10.572h27.373l8.2776-10.572z" strokeWidth="1.5118" aria-label="-" id="path3" style={{stroke: 'rgb(233, 29, 37)', strokeOpacity: 1}} className="svg-elem-3"></path>
                    </g>
                    <path transform="scale(.94459 1.0587)" d="m145.45 1671.2c-0.21584 0-0.31926-0.059-0.31926-0.2114 0-0.1529 0.10342-0.2068 0.31926-0.2068h2.0864l0.59805-0.7824h-2.6125c-0.91281 0-1.2905 0.3687-1.2905 0.9353 0 0.5171 0.35523 0.8633 1.1286 0.8633h1.6368c0.22034 0 0.31926 0.063 0.31926 0.2158 0 0.1574-0.0989 0.2204-0.31926 0.2204h-2.2753l-0.59805 0.7734h2.8014c0.9173 0 1.2905-0.3687 1.2905-0.9848 0-0.5485-0.3957-0.8228-1.1286-0.8228zm6.8303-1.2006h-0.92629v1.0657h-1.7896v-1.0657h-0.93529v3.0082h0.93529v-1.1601h1.7896v1.1601h0.92629zm0.47664 2.626c0 0.2743 0.22483 0.4317 0.50362 0.4317 0.28328 0 0.50811-0.1574 0.50811-0.4317 0-0.2788-0.22483-0.4407-0.50811-0.4407-0.27879 0-0.50362 0.1619-0.50362 0.4407zm5.4544-1.4974c0-0.67-0.54409-1.1286-1.4749-1.1286h-1.8886l-0.61603 0.7824h2.59c0.28778 0 0.45415 0.1304 0.45415 0.3417 0 0.2069-0.16637 0.3463-0.45415 0.3463h-2.59v1.5378h0.9263v-0.7689h1.5738c0.93529 0 1.4794-0.4452 1.4794-1.1107zm0.0315 1.4974c0 0.2743 0.22483 0.4317 0.50362 0.4317 0.28329 0 0.50812-0.1574 0.50812-0.4317 0-0.2788-0.22483-0.4407-0.50812-0.4407-0.27879 0-0.50362 0.1619-0.50362 0.4407zm4.0334-0.6116c-0.3867-0.3147-0.62053-0.4811-0.7914-0.598 0.19336-0.1169 0.37772-0.2518 0.77791-0.5531l1.1511-0.8633h-1.349l-0.86784 0.7329c-0.27429 0.2293-0.43617 0.3822-0.55308 0.4901 9e-3 -0.1573 0.018-0.3417 0.018-0.5261v-0.6969h-0.93529v3.0082h0.93529v-0.8634c0-0.1438-9e-3 -0.2922-0.0135-0.4316 0.10342 0.1034 0.24731 0.2338 0.55758 0.5036l0.90831 0.7914h1.4074z" strokeWidth=".39999" aria-label="SH.P.K" id="path4" style={{strokeWidth: 0.199996}} className="svg-elem-4"></path>
                </g>
            </svg>

        </FadeInOut>
    );
}
