import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  ShieldCheckIcon,
  ViewGridIcon,
  XIcon,
  MailIcon,
  ChevronDownIcon,
  CameraIcon
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import {getLocalizer, getMessage, setLocalizer, languages, getActiveLanguage} from "./Util";
import logo from "./logo_tafa.svg";
import albania from "./albania.svg";
import england from "./england.svg";
import Flag from "./Flag";

const AboutUs = ({ isMobile = false, closeParent = () => null }) => {
  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button className="flex outline-none">
            <div
              className={`cursor-pointer flex text-base font-medium text-gray-500 hover:text-gray-900 ${
                isMobile && "text-base font-medium text-gray-900"
              }`}
            >
              {getMessage("AboutUs")}
            </div>
            <ChevronDownIcon
              aria-hidden="true"
              className={"ml-1 h-5 w-3 text-black"}
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 flex mt-3">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative bg-white p-4 w-32">
                  <Link
                    to="/company"
                    onClick={() => {
                      close();
                      closeParent();
                    }}
                    className="p-1 cursor-pointer flex text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    {getMessage("Company")}
                  </Link>
                  <Link
                    to="/sponsorship"
                    onClick={() => {
                      close();
                      closeParent();
                    }}
                    className="p-1 cursor-pointer flex text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    {getMessage("Sponsorship")}
                  </Link>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

const features = [
  {
    name: getMessage("Home"),
    href: "/",
    icon: ChartBarIcon,
  },
  {
    name: getMessage("Policy"),
    href: "/quality-policy",
    icon: CursorClickIcon,
  },
  {
    name: getMessage("Brands"),
    href: "/brands",
    icon: ShieldCheckIcon,
  },
  {
    name: getMessage("Gallery"),
    href: "/gallery",
    icon: CameraIcon,
  },
  {
    name: e=> <AboutUs isMobile={true} closeParent={e?.closeParent} />,
    isComponent: true,
    icon: ViewGridIcon,
  },
  {
    name: getMessage("ContactUs"),
    href: "/contact-us",
    icon: MailIcon,
  },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const LocalizerDropDown = () => {
  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-500",
              "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            )}
          >
            <div className="md:-m-3 p-3 flex items-center rounded-md">
              <Flag country={getActiveLanguage().icon}/>
              <span className="ml-3 text-base font-medium text-gray-900">
                {getLocalizer()}
              </span>
            </div>
            <ChevronDownIcon
              className={"md:ml-2 h-5 w-5 text-black"}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 right-0 flex mt-3">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative bg-white p-1">
                  {languages.map((item) => (
                    <div
                      key={item.name}
                      onClick={() => {
                        setLocalizer(item.value);
                        close();
                        window.location.reload();
                      }}
                      className="flex items-center rounded-md hover:bg-gray-50 cursor-pointer mt-1 first:mt-0"
                    >
                      <div className="w-full flex p-2 gap-2 justify-center">
                        <Flag country={item.icon}/>
                        <span className="text-base font-medium text-gray-900">
                          {item.name}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default function NavBar() {
  return (
    <div className="relative bg-gray-50">
      <Popover className="relative bg-white shadow">
        {({ close }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
              <div className="flex justify-between items-center py-6 md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <Link to="/">
                    <img
                      className="h-8 w-auto sm:h-10"
                      src={logo}
                      alt="TAFA-S"
                    />
                  </Link>
                </div>
                <div className="-mr-2 -my-2 lg:hidden flex self-center gap-4 justify-center items-center">
                  <LocalizerDropDown />
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <Popover.Group
                  as="nav"
                  className="hidden lg:flex space-x-10 items-center"
                >
                  <Link
                    to="/"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    {getMessage("Home")}
                  </Link>
                  <Link
                    to="/quality-policy"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    {getMessage("Policy")}
                  </Link>
                  <Link
                    to="/brands"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    {getMessage("Brands")}
                  </Link>
                  <Link
                    to="/gallery"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    {getMessage("Gallery")}
                  </Link>

                  <AboutUs />

                  <Link
                    to="/contact-us"
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    {getMessage("ContactUs")}
                  </Link>
                  <LocalizerDropDown />
                </Popover.Group>
              </div>
            </div>
            <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right lg:hidden shadow-lg"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto object-contain"
                          src={logo}
                          alt="Workflow"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-black hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        {features.map((item) => (
                          <Link
                            key={item.name}
                            onClick={() => item?.href && close()}
                            to={item.href}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray outline-none focus:!bg-transparent"
                          >
                            <item.icon
                              className="flex-shrink-0 h-6 w-6 text-black"
                              aria-hidden="true"
                            />
                            <span className="ml-3 text-base font-medium text-gray-900">
                              {item?.isComponent ? <item.name closeParent={close}/> : item?.name}
                            </span>
                          </Link>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
