import { getMessage } from "./Util";

export default function QualityPolicy() {
  return (
    <section className="text-gray-600 body-font max-w-7xl mx-auto p-8">
      <h1 className="font-bold sm:text-4xl text-3xl mb-4 text-[#ce1b1b]">
        {getMessage("QualityFirstTitle")}
      </h1>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityPolicyP1")}
      </p>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityPolicyP2")}
      </p>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityPolicyP3")}
      </p>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityPolicyP4")}
      </p>

      <h2 className="font-bold sm:text-4xl text-3xl mb-4 text-[#ce1b1b]">
        {getMessage("QualitySecondTitle")}
      </h2>

      <ul className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        <li>{getMessage("QualityP1")}</li>
        <li>{getMessage("QualityP2")}</li>
        <li>{getMessage("QualityP3")}</li>
        <li>{getMessage("QualityP4")}</li>
        <li>{getMessage("QualityP5")}</li>
      </ul>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityP6")}
      </p>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityP7")}
      </p>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityP8")}
      </p>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityP9")}
      </p>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityP10")}
      </p>

      <p className="mb-8 leading-relaxed hover:text-gray-900 transition-all">
        {getMessage("QualityP11")}
      </p>
    </section>
  );
}
