import { messages } from "./localizer/localizedText";


export const languages = [
  {
    name: "AL",
    value: "al",
    icon: "AL",
  },
  {
    name: "EN",
    value: "en",
    icon: "US",
  },
  {
    name:'DE',
    value:'de',
    icon:'DE'
  },
  {
    name:'FR',
    value:'fr',
    icon:'FR'

  },
  {
    name:'IT',
    value:'it',
    icon:'IT'
  },
  {
    name: 'TR',
    value: 'tr',
    icon: 'TR'
  },
  {
    name: 'RU',
    value: 'ru',
    icon: 'RU'
  },
  {
    name: 'ES',
    value: 'es',
    icon: 'ES'
  },
  {
    name: 'PT',
    value: 'pt',
    icon: 'PT'
  },
  {
    name: 'GR',
    value: 'gr',
    icon: 'GR'
  },
  {
    name: 'RU',
    value: 'ru',
    icon: 'RU'
  },
  {
    name:'RS',
    value:'rs',
    icon:'RS'
  }
];

export function getMessage(id) {
  const localizer = localStorage.getItem("localizer") ?? "en";

  if (!messages[id]) return;
  return messages[id][localizer];
}

export function getLocalizer() {
  const localizer = localStorage.getItem("localizer") ?? "en";
  return localizer.toUpperCase();
}

export function setLocalizer(localizer) {
  localStorage.setItem("localizer", localizer);
}

export function getActiveLanguage(){
    const localizer = localStorage.getItem("localizer") ?? "en";
    return languages.find((lang) => lang.value === localizer) || languages[1];
}