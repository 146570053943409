import * as React from 'react'

export default function Flag({
                                 flagUrl = 'https://purecatamphetamine.github.io/country-flag-icons/3x2/{XX}.svg',
                                 country = 'AL',
                                 countryName,
    style,
                                 ...rest
                             }) {
    return (
        <img
            style={{ width: 24, height: 24, marginRight: '8px', ...style }}
            {...rest}
            alt={countryName}
            role={countryName ? undefined : 'presentation'}
            src={flagUrl
                .replace('{XX}', country)}
        />
    )
}
