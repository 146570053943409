export const messages =  {
  Home: {
    en: "Home",
    al: "Ballina",
    de: "Startseite",
    tr: "Ana Sayfa",
    fr: "Accueil",
    it: "Home",
    rs: "Početna",
    ru: "Главная",
    es: "Inicio",
    pt: "Início",
    gr: "Αρχική",
  },
  Policy: {
    en: "Policy",
    al: "Politika",
    de: "Richtlinie",
    tr: "Politika",
    fr: "Politique",
    it: "Politica",
    rs: "Politika",
    ru: "Политика",
    es: "Política",
    pt: "Política",
    gr: "Πολιτική",
  },
  Brands: {
    en: "Brands",
    al: "Brendet",
    de: "Marken",
    tr: "Markalar",
    fr: "Marques",
    it: "Marchi",
    rs: "Brendovi",
    ru: "Бренды",
    es: "Marcas",
    pt: "Marcas",
    gr: "Μάρκες",
  },
  AboutUs: {
    en: "About Us",
    al: "Rreth Nesh",
    de: "Über Uns",
    tr: "Hakkımızda",
    fr: "À Propos",
    it: "Chi Siamo",
    rs: "O Nama",
    ru: "О Нас",
    es: "Sobre Nosotros",
    pt: "Sobre Nós",
    gr: "Σχετικά Με Εμάς",
  },
  Company: {
    en: "Company",
    al: "Kompania",
    de: "Unternehmen",
    tr: "Şirket",
    fr: "Entreprise",
    it: "Azienda",
    rs: "Kompanija",
    ru: "Компания",
    es: "Compañía",
    pt: "Empresa",
    gr: "Εταιρεία",
  },
  Sponsorship: {
    en: "Sponsorship",
    al: "Sponsorimet",
    de: "Sponsoring",
    tr: "Sponsorlu",
    fr: "Parrainage",
    it: "Sponsorizzazione",
    rs: "Sponzorstvo",
    ru: "Спонсорство",
    es: "Patrocinio",
    pt: "Patrocínio",
    gr: "Χορηγία",
  },
  ContactUs: {
    en: "Contact Us",
    al: "Kontakti",
    de: "Kontaktieren Sie uns",
    tr: "Bize Ulaşın",
    fr: "Contactez-nous",
    it: "Contattaci",
    rs: "Kontaktirajte Nas",
    ru: "Свяжитесь с Нами",
    es: "Contáctenos",
    pt: "Contate-Nos",
    gr: "Επικοινωνήστε Μαζί Μας",
  },
  Gallery: {
    en: "Gallery",
    al: "Galeria",
    de: "Galerie",
    tr: "Galeri",
    fr: "Galerie",
    it: "Galleria",
    rs: "Galerija",
    ru: "Галерея",
    es: "Galería",
    pt: "Galeria",
    gr: "Συλλογή",
  },
  HistoryFoundation: {
    en: "History - Foundation",
    al: "Themelimi / Historiku",
    de: "Geschichte - Gründung",
    tr: "Tarih - Kuruluş",
    fr: "Histoire - Fondation",
    it: "Storia - Fondazione",
    rs: "Istorija - Osnivanje",
    ru: "История - Основание",
    es: "Historia - Fundación",
    pt: "História - Fundação",
    gr: "Ιστορία - Ίδρυση",
  },
  HistoryFirstParagraph: {
    en: "Previously with another name Elita sh.p.k. has a rich history that dates back to 1969, when its founder Halit Shabani began his entrepreneurial journey in Stuttgart, Germany. Initially, he started by importing new parts and selling them through retail outlets, including spare parts exchanges for passenger cars and commercial vehicles. Over time, the company grew and began to expand its offerings, becoming a trusted supplier of automotive parts and accessories.",
    al: "Më parë me emër tjetër Elita sh.p.k. ka një histori të pasur që daton që nga viti 1969, kur themeluesi i saj Halit Shabani filloi udhëtimin e tij ndërmarrës në Stuttgart, Gjermani. Fillimisht, ai nisi duke importuar pjesë të reja dhe duke i shitur ato përmes dyqaneve me paketa rezervash, duke përfshirë edhe shkëmbimet e pjesshmeve për automjete personale dhe komerciale. Me kalimin e kohës, kompania rritet dhe fillon të zgjerojë ofertat e saj, duke u bërë një furnizues besnik i pjesëve dhe aksesoreve për automjete.",
    de: "Früher unter einem anderen Namen bekannt, hat Elita sh.p.k. eine reiche Geschichte, die bis ins Jahr 1969 zurückreicht, als der Gründer Halit Shabani seine unternehmerische Reise in Stuttgart, Deutschland, begann. Zunächst begann er mit dem Import neuer Teile und dem Verkauf über Einzelhandelsgeschäfte, einschließlich Ersatzteilbörsen für Personen- und Nutzfahrzeuge. Im Laufe der Zeit wuchs das Unternehmen und begann, sein Angebot zu erweitern, und wurde zu einem vertrauenswürdigen Lieferanten von Autoteilen und Zubehör.",
    tr: "Önceden başka bir isimle tanınan Elita sh.p.k.'nin zengin bir geçmişi vardır ve bu geçmiş 1969 yılına kadar uzanır, kurucusu Halit Shabani'nin Stuttgart, Almanya'da girişimcilik yolculuğuna başladığı zaman. Başlangıçta, yeni parçaları ithal edip perakende satış noktaları aracılığıyla satmaya başladı, bu satış noktaları arasında binek ve ticari araçlar için yedek parça değişim merkezleri de vardı. Zamanla, şirket büyüdü ve tekliflerini genişletmeye başladı, güvenilir bir otomotiv parça ve aksesuar tedarikçisi haline geldi.",
    fr: "Autrefois connu sous un autre nom, Elita sh.p.k. possède une riche histoire remontant à 1969, lorsque son fondateur Halit Shabani a commencé son aventure entrepreneuriale à Stuttgart, en Allemagne. Au début, il a commencé par importer de nouvelles pièces et les vendre dans des points de vente au détail, y compris des échanges de pièces détachées pour voitures particulières et véhicules commerciaux. Au fil du temps, l'entreprise a grandi et commencé à élargir ses offres, devenant un fournisseur de confiance de pièces et accessoires automobiles.",
    it: "Precedentemente con un altro nome, Elita sh.p.k. ha una storia ricca che risale al 1969, quando il suo fondatore Halit Shabani iniziò il suo viaggio imprenditoriale a Stoccarda, in Germania. Inizialmente, iniziò importando nuove parti e vendendole attraverso punti vendita al dettaglio, inclusi scambi di ricambi per auto e veicoli commerciali. Nel tempo, l'azienda è cresciuta e ha iniziato ad espandere le sue offerte, diventando un fornitore affidabile di parti e accessori per auto.",
    rs: "Ranije pod drugim imenom, Elita sh.p.k. ima bogatu istoriju koja datira od 1969. godine, kada je njen osnivač Halit Šabani započeo svoje preduzetničko putovanje u Štutgartu, Nemačka. U početku je započeo uvozom novih delova i njihovom prodajom putem maloprodajnih objekata, uključujući razmene rezervnih delova za putnička i komercijalna vozila. Vremenom je kompanija rasla i počela da širi svoju ponudu, postavši pouzdan dobavljač automobilskih delova i dodataka.",
    ru: "Ранее под другим названием Elita sh.p.k. имеет богатую историю, которая восходит к 1969 году, когда ее основатель Халит Шабани начал свое предпринимательское путешествие в Штутгарте, Германия. Первоначально он начал с импорта новых деталей и продажи их через розничные магазины, включая обмен запчастями для легковых и коммерческих автомобилей. Со временем компания росла и начала расширять свои предложения, став надежным поставщиком автомобильных запчастей и аксессуаров.",
    es: "Anteriormente con otro nombre, Elita sh.p.k. tiene una rica historia que se remonta a 1969, cuando su fundador Halit Shabani comenzó su viaje empresarial en Stuttgart, Alemania. Inicialmente, comenzó importando piezas nuevas y vendiéndolas a través de puntos de venta al por menor, incluyendo intercambios de repuestos para automóviles y vehículos comerciales. Con el tiempo, la empresa creció y comenzó a expandir su oferta, convirtiéndose en un proveedor de confianza de piezas y accesorios automotrices.",
    pt: "Anteriormente com outro nome, Elita sh.p.k. tem uma rica história que remonta a 1969, quando seu fundador Halit Shabani iniciou sua jornada empreendedora em Stuttgart, Alemanha. Inicialmente, ele começou importando novas peças e vendendo-as através de pontos de venda a retalho, incluindo trocas de peças sobressalentes para carros de passageiros e veículos comerciais. Com o tempo, a empresa cresceu e começou a expandir suas ofertas, tornando-se um fornecedor confiável de peças e acessórios automotivos.",
    gr: "Προηγουμένως με άλλο όνομα, η Elita sh.p.k. έχει μια πλούσια ιστορία που χρονολογείται από το 1969, όταν ο ιδρυτής της, Χαλίτ Σαμπάνι, ξεκίνησε το επιχειρηματικό του ταξίδι στη Στουτγάρδη της Γερμανίας. Αρχικά, ξεκίνησε εισάγοντας νέα μέρη και πουλώντας τα μέσω καταστημάτων λιανικής, συμπεριλαμβανομένων των ανταλλαγών ανταλλακτικών για επιβατικά και εμπορικά οχήματα. Με την πάροδο του χρόνου, η εταιρεία μεγάλωσε και άρχισε να επεκτείνει τις προσφορές της, καθιστώντας αξιόπιστο προμηθευτή ανταλλακτικών και αξεσουάρ αυτοκινήτων.",
  },
  HistorySecondParagraph: {
    en: "For 56 years, the company has remained family-owned, with Mustafa and Rexhep Shabani taking over the reins from their father. The family's commitment to quality and customer service has been the driving force behind the company's success, making it a trusted partner to many businesses in the automotive industry.",
    al: "Për 56 vjet, kompania ka mbetur në pronësi të familjes, me Mustafën dhe Rexhep Shabanin që morën drejtimin nga babai i tyre. Përkushtimi i familjes ndaj cilësisë dhe shërbimit ndaj klientit ka qenë forca lëvizëse pas suksesit të kompanisë, duke e bërë atë një partner të besuar për shumë biznese në industrinë e automobilave.",
    de: "Seit 56 Jahren ist das Unternehmen in Familienbesitz geblieben, wobei Mustafa und Rexhep Shabani das Ruder von ihrem Vater übernommen haben. Das Engagement der Familie für Qualität und Kundendienst war die treibende Kraft hinter dem Erfolg des Unternehmens und hat es zu einem vertrauenswürdigen Partner für viele Unternehmen in der Automobilindustrie gemacht.",
    tr: "56 yıl boyunca, şirket aileye ait olarak kalmıştır ve Mustafa ve Rexhep Shabani, babalarından yönetimi devralmıştır. Ailenin kalite ve müşteri hizmetlerine olan bağlılığı, şirketin başarısının arkasındaki itici güç olmuş ve otomotiv endüstrisindeki birçok işletme için güvenilir bir ortak haline gelmiştir.",
    fr: "Depuis 56 ans, l'entreprise est restée familiale, avec Mustafa et Rexhep Shabani prenant la relève de leur père. L'engagement de la famille envers la qualité et le service client a été le moteur du succès de l'entreprise, en faisant un partenaire de confiance pour de nombreuses entreprises de l'industrie automobile.",
    it: "Per 56 anni, l'azienda è rimasta di proprietà familiare, con Mustafa e Rexhep Shabani che hanno preso le redini dal loro padre. L'impegno della famiglia per la qualità e il servizio clienti è stato il motore del successo dell'azienda, rendendola un partner affidabile per molte aziende nel settore automobilistico.",
    rs: "Tokom 56 godina, kompanija je ostala u vlasništvu porodice, sa Mustafom i Rexhepom Šabanijem koji su preuzeli upravljanje od svog oca. Posvećenost porodice kvalitetu i korisničkoj službi bila je pokretačka snaga iza uspeha kompanije i postala je poznata kao pouzdan partner mnogim preduzećima u automobilskoj industriji.",
    ru: "В течение 56 лет компания оставалась в семейной собственности, причем Мустафа и Реджеп Шабани взяли на себя руководство от своего отца. Приверженность семьи качеству и обслуживанию клиентов была движущей силой успеха компании, сделав ее надежным партнером для многих предприятий в автомобильной промышленности.",
    es: "Durante 56 años, la empresa ha permanecido en manos de la familia, con Mustafa y Rexhep Shabani tomando las riendas de su padre. El compromiso de la familia con la calidad y el servicio al cliente ha sido la fuerza impulsora detrás del éxito de la empresa, convirtiéndola en un socio de confianza para muchas empresas en la industria automotriz.",
    pt: "Durante 56 anos, a empresa permaneceu de propriedade familiar, com Mustafa e Rexhep Shabani assumindo as rédeas de seu pai. O compromisso da família com a qualidade e o atendimento ao cliente tem sido a força motriz por trás do sucesso da empresa, tornando-a um parceiro de confiança para muitas empresas da indústria automotiva.",
    gr: "Για 56 χρόνια, η εταιρεία παρέμεινε οικογενειακή, με τους Μουσταφά και Ρέτζεπ Σαμπάνι να αναλαμβάνουν τα ηνία από τον πατέρα τους. Η αφοσίωση της οικογένειας στην ποιότητα και την εξυπηρέτηση πελατών ήταν η κινητήρια δύναμη πίσω από την επιτυχία της εταιρείας, καθιστώντας την έναν αξιόπιστο συνεργάτη για πολλές επιχειρήσεις στη βιομηχανία αυτοκινήτων.",
  },
  HistoryThirdParagraph: {
    en: "As the company continued to grow, it underwent significant changes, including a transition to a new generation of leadership. Today, TAFA-S sh.p.k. is led by Kushtrim Shabani and Ilir Shabani, both of whom bring a wealth of experience and expertise to the table. Under their leadership, the company has continued to evolve, with a renewed focus on technology and logistics, which has helped streamline operations and improve efficiency.",
    al: "Ndërsa kompania vazhdoi të rritet, ajo përjetoi ndryshime të rëndësishme, duke përfshirë një kalim në një brez të ri të udhëheqjes. Sot, TAFA-S sh.p.k. drejtohet nga Kushtrim Shabani dhe Ilir Shabani, të dy të cilët sjellin një pasuri të madhe përvoje dhe ekspertize. Nën udhëheqjen e tyre, kompania ka vazhduar të evoluojë, me një fokus të ri në teknologjinë dhe logjistikën, që ka ndihmuar të përmirësohen operacionet dhe të rritet efikasiteti.",
    de: "Während das Unternehmen weiter wuchs, durchlief es bedeutende Veränderungen, einschließlich des Übergangs zu einer neuen Führungsgeneration. Heute wird TAFA-S sh.p.k. von Kushtrim Shabani und Ilir Shabani geleitet, die beide eine Fülle von Erfahrung und Fachwissen mitbringen. Unter ihrer Führung hat sich das Unternehmen weiterentwickelt, mit einem erneuten Fokus auf Technologie und Logistik, was zur Optimierung der Abläufe und zur Verbesserung der Effizienz beigetragen hat.",
    tr: "Şirket büyümeye devam ederken, yeni bir liderlik nesline geçiş de dahil olmak üzere önemli değişikliklerden geçti. Bugün, TAFA-S sh.p.k., masaya büyük bir deneyim ve uzmanlık getiren Kushtrim Shabani ve Ilir Shabani tarafından yönetilmektedir. Onların liderliğinde, şirket teknoloji ve lojistiğe yeniden odaklanarak evrim geçirmeye devam etti, bu da operasyonları kolaylaştırmaya ve verimliliği artırmaya yardımcı oldu.",
    fr: "À mesure que l'entreprise continuait de croître, elle a connu des changements significatifs, y compris la transition vers une nouvelle génération de leadership. Aujourd'hui, TAFA-S sh.p.k. est dirigée par Kushtrim Shabani et Ilir Shabani, qui apportent tous deux une grande expérience et expertise. Sous leur direction, l'entreprise a continué à évoluer, avec un nouveau focus sur la technologie et la logistique, ce qui a permis de rationaliser les opérations et d'améliorer l'efficacité.",
    it: "Con la continua crescita dell'azienda, essa ha subito cambiamenti significativi, inclusa la transizione a una nuova generazione di leadership. Oggi, TAFA-S sh.p.k. è gestita da Kushtrim Shabani e Ilir Shabani, entrambi portano una grande esperienza e competenza. Sotto la loro guida, l'azienda ha continuato ad evolversi, con un rinnovato focus su tecnologia e logistica, che ha contribuito a snellire le operazioni e migliorare l'efficienza.",
    rs: "Kako je kompanija nastavila da raste, prošla je kroz značajne promene, uključujući prelazak na novu generaciju liderstva. Danas, TAFA-S sh.p.k. vodi Kushtrim Šabani i Ilir Šabani, koji obojica donose bogato iskustvo i stručnost. Pod njihovim vođstvom, kompanija je nastavila da se razvija, sa novim fokusom na tehnologiju i logistiku, što je pomoglo da se operacije unaprede i poveća efikasnost.",
    ru: "По мере того, как компания продолжала расти, она претерпела значительные изменения, включая переход к новому поколению руководства. Сегодня TAFA-S sh.p.k. возглавляют Куштрим Шабани и Илир Шабани, которые привносят огромный опыт и знания. Под их руководством компания продолжала развиваться, с новым акцентом на технологии и логистику, что помогло оптимизировать операции и повысить эффективность.",
    es: "A medida que la empresa continuaba creciendo, experimentó cambios significativos, incluida la transición a una nueva generación de liderazgo. Hoy en día, TAFA-S sh.p.k. está dirigida por Kushtrim Shabani e Ilir Shabani, ambos aportando una gran experiencia y conocimientos. Bajo su liderazgo, la empresa ha seguido evolucionando, con un renovado enfoque en la tecnología y la logística, lo que ha ayudado a agilizar las operaciones y mejorar la eficiencia.",
    pt: "À medida que a empresa continuava a crescer, passou por mudanças significativas, incluindo a transição para uma nova geração de liderança. Hoje, a TAFA-S sh.p.k. é liderada por Kushtrim Shabani e Ilir Shabani, ambos trazendo uma grande riqueza de experiência e conhecimento. Sob sua liderança, a empresa continuou a evoluir, com um foco renovado em tecnologia e logística, o que ajudou a simplificar as operações e melhorar a eficiência.",
    gr: "Καθώς η εταιρεία συνέχιζε να αναπτύσσεται, υπέστη σημαντικές αλλαγές, συμπεριλαμβανομένης της μετάβασης σε μια νέα γενιά ηγεσίας. Σήμερα, η TAFA-S sh.p.k. ηγείται από τον Κουστρίμ Σαμπάνι και τον Ιλίρ Σαμπάνι, και οι δύο φέρνουν πλούσια εμπειρία και εξειδίκευση. Υπό την ηγεσία τους, η εταιρεία συνέχισε να εξελίσσεται, με ανανεωμένη έμφαση στην τεχνολογία και την εφοδιαστική, γεγονός που βοήθησε στην απλοποίηση των λειτουργιών και στη βελτίωση της αποτελεσματικότητας.",
  },
  HistoryFourthParagraph: {
    en: "One of the key milestones in the company's history came in 1999, when it became the authorized distributor for General Motors and Opel spare parts in Kosovo. This partnership marked a significant step forward for the company, allowing it to offer a wider range of high-quality parts to its customers.",
    al: "Një nga pikat kyçe në historinë e kompanisë erdhi në vitin 1999, kur ajo u bë distributori i autorizuar për pjesë këmbimi të General Motors dhe Opel në Kosovë. Ky partneritet shënoi një hap të rëndësishëm përpara për kompaninë, duke i lejuar të ofrojë një gamë më të gjerë të pjesëve të cilësisë së lartë për klientët e saj.",
    de: "Einer der wichtigsten Meilensteine in der Geschichte des Unternehmens kam 1999, als es zum autorisierten Vertriebspartner für Ersatzteile von General Motors und Opel im Kosovo wurde. Diese Partnerschaft markierte einen bedeutenden Schritt nach vorne für das Unternehmen und ermöglichte es ihm, seinen Kunden eine breitere Palette hochwertiger Teile anzubieten.",
    tr: "Şirketin tarihindeki önemli dönüm noktalarından biri 1999 yılında geldi, General Motors ve Opel yedek parçalarının Kosova'daki yetkili distribütörü olduğunda. Bu ortaklık, şirket için ileriye doğru atılmış önemli bir adım oldu ve müşterilerine daha geniş bir yelpazede yüksek kaliteli parçalar sunmasına olanak tanıdı.",
    fr: "L'un des moments clés de l'histoire de l'entreprise est survenu en 1999, lorsqu'elle est devenue le distributeur autorisé des pièces détachées de General Motors et Opel au Kosovo. Ce partenariat a marqué une étape importante pour l'entreprise, lui permettant d'offrir une gamme plus large de pièces de haute qualité à ses clients.",
    it: "Una delle pietre miliari nella storia dell'azienda è arrivata nel 1999, quando è diventata il distributore autorizzato per i ricambi di General Motors e Opel in Kosovo. Questa partnership ha segnato un passo significativo per l'azienda, permettendole di offrire una gamma più ampia di ricambi di alta qualità ai suoi clienti.",
    rs: "Jedna od ključnih prekretnica u istoriji kompanije dogodila se 1999. godine, kada je postala ovlašćeni distributer za rezervne delove General Motors-a i Opel-a na Kosovu. Ovo partnerstvo je označilo značajan korak napred za kompaniju, omogućivši joj da svojim klijentima ponudi širi spektar visokokvalitetnih delova.",
    ru: "Одной из ключевых вех в истории компании стал 1999 год, когда она стала авторизованным дистрибьютором запасных частей General Motors и Opel в Косово. Это партнерство стало значительным шагом вперед для компании, позволив предложить клиентам более широкий ассортимент высококачественных запчастей.",
    es: "Uno de los hitos clave en la historia de la empresa llegó en 1999, cuando se convirtió en el distribuidor autorizado de repuestos de General Motors y Opel en Kosovo. Esta asociación marcó un paso significativo para la empresa, permitiéndole ofrecer una gama más amplia de piezas de alta calidad a sus clientes.",
    pt: "Um dos marcos importantes na história da empresa ocorreu em 1999, quando se tornou o distribuidor autorizado de peças sobressalentes da General Motors e Opel no Kosovo. Esta parceria marcou um passo significativo para a empresa, permitindo-lhe oferecer uma gama mais ampla de peças de alta qualidade aos seus clientes.",
    gr: "Ένα από τα βασικά ορόσημα στην ιστορία της εταιρείας ήρθε το 1999, όταν έγινε ο εξουσιοδοτημένος διανομέας ανταλλακτικών της General Motors και της Opel στο Κοσσυφοπέδιο. Αυτή η συνεργασία αποτέλεσε σημαντικό βήμα προς τα εμπρός για την εταιρεία, επιτρέποντάς της να προσφέρει μια ευρύτερη γκάμα υψηλής ποιότητας ανταλλακτικών στους πελάτες της.",
  },

  Mission: {
    en: "Mission",
    al: "Misioni",
    de: "Mission",
    tr: "Misyon",
    fr: "Mission",
    it: "Missione",
    es: "Misión",
    pt: "Missão",
    gr: "Αποστολή",
    ru: "Миссия",
    rs: "Misija"
  },
  MissionOne: {
    en: "At our company, we believe that the success of our business partners is crucial to our own success. That's why our primary focus is on increasing the profitability of our partners while ensuring customer satisfaction remains a top priority.",
    al: "Në kompaninë tonë, ne besojmë se suksesi i partnerëve tanë të biznesit është i rëndësishëm për suksesin tonë. Prandaj, fokusi ynë kryesor është në rritjen e rentabilitetit të partnerëve tanë duke siguruar që kënaqësia e klientit mbetet një prioritet i lartë.",
    de: "In unserem Unternehmen glauben wir, dass der Erfolg unserer Geschäftspartner entscheidend für unseren eigenen Erfolg ist. Deshalb liegt unser Hauptaugenmerk darauf, die Rentabilität unserer Partner zu erhöhen, während gleichzeitig die Kundenzufriedenheit oberste Priorität hat.",
    tr: "Şirketimizde, iş ortaklarımızın başarısının kendi başarımız için hayati öneme sahip olduğuna inanıyoruz. Bu nedenle, ana odak noktamız iş ortaklarımızın karlılığını artırmak olup müşteri memnuniyetinin önemini korumak en üst önceliğimizdir.",
    fr: "Dans notre entreprise, nous croyons que le succès de nos partenaires commerciaux est crucial pour notre propre succès. C'est pourquoi notre principal objectif est d'augmenter la rentabilité de nos partenaires tout en veillant à ce que la satisfaction de la clientèle reste une priorité absolue.",
    it: "Nella nostra azienda, crediamo che il successo dei nostri partner commerciali sia cruciale per il nostro successo. Ecco perché il nostro focus principale è aumentare la redditività dei nostri partner garantendo al contempo che la soddisfazione del cliente rimanga una priorità assoluta.",
    es: "En nuestra empresa, creemos que el éxito de nuestros socios comerciales es crucial para nuestro propio éxito. Por eso, nuestro enfoque principal está en aumentar la rentabilidad de nuestros socios mientras se asegura que la satisfacción del cliente siga siendo una prioridad principal.",
    pt: "Na nossa empresa, acreditamos que o sucesso dos nossos parceiros de negócios é crucial para o nosso próprio sucesso. Por isso, o nosso foco principal está em aumentar a rentabilidade dos nossos parceiros, garantindo ao mesmo tempo que a satisfação do cliente permanece uma prioridade máxima.",
    gr: "Στην εταιρεία μας, πιστεύουμε ότι η επιτυχία των επιχειρηματικών μας εταίρων είναι κρίσιμη για τη δική μας επιτυχία. Γι' αυτό ο κύριος στόχος μας είναι η αύξηση της κερδοφορίας των εταίρων μας ενώ διασφαλίζουμε ότι η ικανοποίηση του πελάτη παραμένει υψηλή προτεραιότητα.",
    ru: "В нашей компании мы считаем, что успех наших деловых партнеров крайне важен для нашего собственного успеха. Поэтому наш основной упор сделан на повышение прибыльности наших партнеров, при этом обеспечивая сохранение высокого приоритета удовлетворенности клиентов.",
    rs: "U našoj kompaniji verujemo da je uspeh naših poslovnih partnera ključan za naš sopstveni uspeh. Zato je naš primarni fokus na povećanju profitabilnosti naših partnera uz istovremeno osiguravanje da zadovoljstvo korisnika ostane glavni prioritet."
  },
  MissionTwo: {
    en: "We understand that the automotive industry plays a critical role in the national economy, and we take our responsibility to contribute to its development seriously. Our goal is to be at the forefront of sectoral development by continuously improving our products and services and investing in research and development.",
    al: "Ne kuptojmë se industrinë e automjeteve luan një rol kritik në ekonominë kombëtare, dhe ne marrim seriozisht përgjegjësinë tonë për të kontribuar në zhvillimin e saj. Qëllimi ynë është të jemi në krye të zhvillimit sektorial duke përmirësuar vazhdimisht produktet dhe shërbimet tona dhe investuar në kërkim dhe zhvillim.",
    de: "Wir verstehen, dass die Automobilindustrie eine entscheidende Rolle in der nationalen Wirtschaft spielt, und wir nehmen unsere Verantwortung, zu ihrer Entwicklung beizutragen, ernst. Unser Ziel ist es, an vorderster Front der sektoralen Entwicklung zu stehen, indem wir kontinuierlich unsere Produkte und Dienstleistungen verbessern und in Forschung und Entwicklung investieren.",
    tr: "Otomotiv endüstrisinin ulusal ekonomide kritik bir rol oynadığını anlıyoruz ve katkı sağlama sorumluluğumuzu ciddiye alıyoruz. Amacımız, ürünlerimizi ve hizmetlerimizi sürekli olarak iyileştirerek ve araştırma ve geliştirmeye yatırım yaparak sektörel gelişimin öncüsü olmaktır.",
    fr: "Nous comprenons que l'industrie automobile joue un rôle crucial dans l'économie nationale, et nous prenons notre responsabilité de contribuer à son développement au sérieux. Notre objectif est d'être à l'avant-garde du développement sectoriel en améliorant continuellement nos produits et services et en investissant dans la recherche et le développement.",
    it: "Siamo consapevoli che l'industria automobilistica svolge un ruolo critico nell'economia nazionale, e prendiamo seriamente la nostra responsabilità di contribuire al suo sviluppo. Il nostro obiettivo è essere all'avanguardia dello sviluppo settoriale migliorando continuamente i nostri prodotti e servizi e investendo nella ricerca e nello sviluppo.",
    es: "Entendemos que la industria automotriz desempeña un papel crítico en la economía nacional, y asumimos seriamente nuestra responsabilidad de contribuir a su desarrollo. Nuestro objetivo es estar a la vanguardia del desarrollo sectorial mediante la mejora continua de nuestros productos y servicios e invirtiendo en investigación y desarrollo.",
    pt: "Entendemos que a indústria automobilística desempenha um papel crítico na economia nacional e levamos a sério nossa responsabilidade de contribuir para o seu desenvolvimento. Nosso objetivo é estar na vanguarda do desenvolvimento setorial, melhorando continuamente nossos produtos e serviços e investindo em pesquisa e desenvolvimento.",
    gr: "Κατανοούμε ότι η αυτοκινητοβιομηχανία διαδραματίζει κρίσιμο ρόλο στην εθνική οικονομία και προσλαμβάνουμε σοβαρά την ευθύνη μας να συμβάλουμε στην ανάπτυξή της. Ο στόχος μας είναι να βρισκόμαστε στην πρωτοπορία της τομεακής ανάπτυξης, βελτιώνοντας συνεχώς τα προϊόντα και τις υπηρεσίες μας και επενδύοντας στην έρευνα και ανάπτυξη.",
    ru: "Мы понимаем, что автомобильная промышленность играет критическую роль в национальной экономике, и мы серьезно относимся к нашей ответственности вкладывать в ее развитие. Наша цель - быть на передовой отраслевого развития, постоянно улучшая наши продукты и услуги и инвестируя в научно-исследовательскую работу и разработки.",
    rs: "Shvatamo da autoindustrija igra ključnu ulogu u nacionalnoj ekonomiji i ozbiljno shvatamo našu odgovornost da doprinesemo njenom razvoju. Naš cilj je biti u vrhu sektorskog razvoja kontinuiranim poboljšanjem naših proizvoda i usluga i ulaganjem u istraživanje i razvoj."
  },
  MissionThree: {
    en: "Our employees are our most valuable asset, and we recognize that their growth and development are essential to our continued success. We are committed to providing our employees with opportunities for professional development and personal growth through ongoing training programs and proper investments in their careers.",
    al: "Punonjësit tanë janë pasuria jonë më e çmuar, dhe ne e njohim se rritja dhe zhvillimi i tyre janë të domosdoshme për suksesin tonë të vazhdueshëm. Ne jemi të angazhuar për të siguruar punonjësve tanë mundësi për zhvillim profesional dhe rritje personale përmes programeve të trajnimit të vazhdueshëm dhe investimeve të duhura në karrierat e tyre.",
    de: "Unsere Mitarbeiter sind unser wertvollstes Gut, und wir erkennen, dass ihr Wachstum und ihre Entwicklung entscheidend für unseren kontinuierlichen Erfolg sind. Wir sind bestrebt, unseren Mitarbeitern durch kontinuierliche Schulungsprogramme und angemessene Investitionen in ihre Karrieren Möglichkeiten zur beruflichen Entwicklung und persönlichen Wachstum zu bieten.",
    tr: "Çalışanlarımız en değerli varlığımızdır ve onların büyüme ve gelişiminin, sürekli başarımız için hayati olduğunun farkındayız. Çalışanlarımıza sürekli eğitim programları ve kariyerlerine uygun yatırımlarla profesyonel gelişim ve kişisel büyüme fırsatları sunmaya kararlıyız.",
    fr: "Nos employés sont notre atout le plus précieux, et nous reconnaissons que leur croissance et leur développement sont essentiels à notre succès continu. Nous nous engageons à offrir à nos employés des opportunités de développement professionnel et de croissance personnelle grâce à des programmes de formation continue et à des investissements appropriés dans leur carrière.",
    it: "I nostri dipendenti sono il nostro bene più prezioso, e riconosciamo che la loro crescita e sviluppo sono essenziali per il nostro successo continuo. Ci impegniamo a fornire ai nostri dipendenti opportunità di sviluppo professionale e crescita personale attraverso programmi di formazione continua e investimenti adeguati nelle loro carriere.",
    es: "Nuestros empleados son nuestro activo más valioso, y reconocemos que su crecimiento y desarrollo son esenciales para nuestro éxito continuo. Estamos comprometidos a proporcionar a nuestros empleados oportunidades de desarrollo profesional y crecimiento personal a través de programas de capacitación continuos e inversiones adecuadas en sus carreras.",
    pt: "Nossos funcionários são nosso ativo mais valioso, e reconhecemos que o crescimento e o desenvolvimento deles são essenciais para nosso sucesso contínuo. Estamos comprometidos em oferecer oportunidades de desenvolvimento profissional e crescimento pessoal aos nossos funcionários por meio de programas de treinamento contínuo e investimentos adequados em suas carreiras.",
    gr: "Οι εργαζόμενοί μας αποτελούν το πιο πολύτιμο περιουσιακό στοιχείο μας, και αναγνωρίζουμε ότι η ανάπτυξή τους είναι ουσιώδης για την συνεχή επιτυχία μας. Δεσμευόμαστε να παρέχουμε στους εργαζόμενούς μας ευκαιρίες για επαγγελματική ανάπτυξη και προσωπική ανέλιξη μέσω συνεχών εκπαιδευτικών προγραμμάτων και κατάλληλων επενδύσεων στις καριέρες τους.",
    ru: "Наши сотрудники - наш самый ценный ресурс, и мы понимаем, что их рост и развитие являются ключевыми для нашего продолжающегося успеха. Мы обязуемся предоставлять нашим сотрудникам возможности для профессионального развития и личного роста через программы постоянного обучения и правильные инвестиции в их карьеры.",
    rs: "Naši zaposleni su naša najvrednija imovina, i svesni smo da je njihov rast i razvoj ključan za naš kontinuirani uspeh. Obavezujemo se da našim zaposlenima pružimo prilike za profesionalni razvoj i lični rast kroz stalne obuke i pravilne investicije u njihove karijere."
  },
  MissionFour: {
    en: "As a socially responsible company, we also care deeply about our impact on society and the environment. We are committed to operating in an ethical and sustainable manner and strive to minimize our environmental footprint wherever possible.",
    al: "Si një kompani me përgjegjësi sociale, ne gjithashtu kujdesemi thellësisht për ndikimin tonë në shoqëri dhe mjedisin. Jemi të angazhuar për të vepruar në mënyrë etike dhe të qëndrueshme dhe përpiqemi të minimizojmë ndikimin tonë në mjedis kudo që është e mundur.",
    de: "Als sozial verantwortungsbewusstes Unternehmen liegt uns auch der Einfluss auf die Gesellschaft und die Umwelt sehr am Herzen. Wir verpflichten uns, ethisch und nachhaltig zu handeln, und streben danach, unseren ökologischen Fußabdruck überall dort zu minimieren, wo es möglich ist.",
    tr: "Sosyal sorumluluk sahibi bir şirket olarak, toplum ve çevre üzerindeki etkimizle de derin bir şekilde ilgileniyoruz. Etik ve sürdürülebilir bir şekilde faaliyet göstermeyi taahhüt ediyoruz ve mümkün olduğu kadar çevresel etkimizi en aza indirmeye çalışıyoruz.",
    fr: "En tant qu'entreprise socialement responsable, nous nous soucions également profondément de notre impact sur la société et l'environnement. Nous nous engageons à opérer de manière éthique et durable et nous efforçons de minimiser notre empreinte environnementale partout où cela est possible.",
    it: "Come azienda socialmente responsabile, ci preoccupiamo anche profondamente del nostro impatto sulla società e sull'ambiente. Ci impegniamo a operare in modo etico e sostenibile e cerchiamo di ridurre al minimo la nostra impronta ambientale ovunque sia possibile.",
    es: "Como empresa socialmente responsable, también nos preocupamos profundamente por nuestro impacto en la sociedad y el medio ambiente. Estamos comprometidos a operar de manera ética y sostenible y nos esforzamos por minimizar nuestra huella ambiental siempre que sea posible.",
    pt: "Como uma empresa socialmente responsável, também nos preocupamos profundamente com nosso impacto na sociedade e no meio ambiente. Comprometemo-nos a operar de maneira ética e sustentável e nos esforçamos para minimizar nossa pegada ambiental sempre que possível.",
    gr: "Ως μια κοινωνικά υπεύθυνη εταιρεία, μας ενδιαφέρει επίσης βαθιά ο αντίκτυπός μας στην κοινωνία και το περιβάλλον. Δεσμευόμαστε να λειτουργούμε με ηθικό και βιώσιμο τρόπο και προσπαθούμε να ελαχιστοποιήσουμε το περιβαλλοντικό μας αποτύπωμα οπουδήποτε είναι δυνατόν.",
    ru: "Как социально ответственная компания, мы также очень заботимся о нашем влиянии на общество и окружающую среду. Мы обязуемся работать этично и устойчиво и стремимся минимизировать наш экологический след везде, где это возможно.",
    rs: "Kao društveno odgovorna kompanija, takođe duboko brinemo o našem uticaju na društvo i životnu sredinu. Obavezujemo se da poslujemo na etičan i održiv način i težimo da minimiziramo naš ekološki otisak gde god je to moguće."
  },
  MissionFive: {
    en: "To achieve these goals, we have established a culture of continuous improvement, where we regularly evaluate our performance against established targets and seek out opportunities to improve our processes and operations. By doing so, we are confident that we can achieve our mission of becoming a leading force in the automotive industry while making a positive contribution to society and the environment.",
    al: "Për të arritur këta qëllime, ne kemi vendosur një kulturë të përmirësimit të vazhdueshëm, ku ne në mënyrë rregullt e vlerësojmë performancën tonë në krahasim me objektivat e vendosura dhe kërkojmë mundësi për të përmirësuar proceset dhe operacionet tona. Duke e bërë këtë, jemi të sigurt se mund të arrijmë misionin tonë për të qenë një forcë udhëheqëse në industrinë e automjeteve duke kontribuar në një mënyrë pozitive në shoqëri dhe mjedis.",
    de: "Um diese Ziele zu erreichen, haben wir eine Kultur der kontinuierlichen Verbesserung etabliert, in der wir regelmäßig unsere Leistung gegenüber festgelegten Zielen evaluieren und Möglichkeiten zur Verbesserung unserer Prozesse und Operationen suchen. Dadurch sind wir zuversichtlich, dass wir unsere Mission, eine führende Kraft in der Automobilindustrie zu werden, erreichen können, während wir einen positiven Beitrag zur Gesellschaft und zur Umwelt leisten.",
    tr: "Bu hedeflere ulaşmak için sürekli iyileştirme kültürü oluşturduk, burada düzenli olarak performansımızı belirlenen hedeflere karşı değerlendiriyor ve süreçlerimizi ve operasyonlarımızı iyileştirmek için fırsatlar arıyoruz. Böyle yaparak, otomotiv endüstrisinde öncü bir güç haline gelme misyonumuzu başarabileceğimize ve topluma ve çevreye olumlu bir katkı yapabileceğimize eminiz.",
    fr: "Pour atteindre ces objectifs, nous avons mis en place une culture d'amélioration continue, où nous évaluons régulièrement notre performance par rapport aux objectifs établis et cherchons des opportunités pour améliorer nos processus et nos opérations. En agissant ainsi, nous sommes convaincus que nous pouvons accomplir notre mission de devenir une force de premier plan dans l'industrie automobile tout en contribuant positivement à la société et à l'environnement.",
    it: "Per raggiungere questi obiettivi, abbiamo stabilito una cultura del miglioramento continuo, in cui valutiamo regolarmente le nostre performance rispetto agli obiettivi stabiliti e cerchiamo opportunità per migliorare i nostri processi e le nostre operazioni. Facendo ciò, siamo convinti di poter raggiungere la nostra missione di diventare una forza trainante nell'industria automobilistica contribuendo positivamente alla società e all'ambiente.",
    es: "Para alcanzar estos objetivos, hemos establecido una cultura de mejora continua, donde evaluamos regularmente nuestro desempeño frente a los objetivos establecidos y buscamos oportunidades para mejorar nuestros procesos y operaciones. Al hacerlo, estamos seguros de que podemos lograr nuestra misión de convertirnos en una fuerza líder en la industria automotriz mientras contribuimos positivamente a la sociedad y al medio ambiente.",
    pt: "Para alcançar esses objetivos, estabelecemos uma cultura de melhoria contínua, onde avaliamos regularmente nosso desempenho em relação às metas estabelecidas e buscamos oportunidades para melhorar nossos processos e operações. Ao fazer isso, temos confiança de que podemos alcançar nossa missão de nos tornarmos uma força líder na indústria automotiva, contribuindo positivamente para a sociedade e o meio ambiente.",
    gr: "Για να επιτύχουμε αυτούς τους στόχους, έχουμε θεσπίσει μια κουλτούρα συνεχούς βελτίωσης, όπου αξιολογούμε τακτικά την απόδοσή μας έναντι των καθορισμένων στόχων και αναζητούμε ευκαιρίες για να βελτιώσουμε τις διαδικασίες και τις λειτουργίες μας. Με αυτόν τον τρόπο, είμαστε βέβαιοι ότι μπορούμε να επιτύχουμε τον στόχο μας να γίνουμε μια κορυφαία δύναμη στη βιομηχανία των αυτοκινήτων, ενώ συνεισφέρουμε θετικά στην κοινωνία και το περιβάλλον.",
    ru: "Для достижения этих целей мы создали культуру непрерывного совершенствования, где мы регулярно оцениваем нашу производительность по сравнению с установленными целями и ищем возможности для улучшения наших процессов и операций. Таким образом, мы уверены, что сможем достичь нашей миссии стать ведущей силой в автомобильной промышленности, привнося позитивный вклад в общество и окружающую среду.",
    rs: "Za postizanje ovih ciljeva, uspostavili smo kulturu kontinuiranog poboljšanja, gde redovno procenjujemo našu performansu u odnosu na postavljene ciljeve i tražimo prilike za poboljšanje naših procesa i operacija. Na taj način, uvereni smo da možemo ostvariti našu misiju da postanemo vodeća snaga u automobilskoj industriji, pritom praveći pozitivan doprinos društvu i okolini.",
  },
  Vision: {
    en: "Vision",
    al: "Vizioni",
    de: "Vision",
    tr: "Vizyon",
    fr: "Vision",
    it: "Visione",
    es: "Visión",
    pt: "Visão",
    gr: "Όραμα",
    ru: "Видение",
    rs: "Vizija",
  },
  VisionOne: {
    en: "Our goal at TAFA-S, also known as AUTO ONE and AOTS, is to establish ourselves as the premier brand in the Independent Aftermarket for Automotive Industry. We aim to achieve this by providing our customers with the widest range of high-quality products at affordable prices, ensuring that they are always available when needed, and delivering exceptional service quality that exceeds their expectations.",
    al: "Qëllimi ynë në TAFA-S, njohur gjithashtu si AUTO ONE dhe AOTS, është që të ndërtojmë veten tonë si marka kryesore në tregun e paslëndëve të pavarura për industrinë e automjeteve. Ne synojmë të arrijmë këtë duke ofruar klientëve tanë gamën më të gjerë të produkteve të cilësisë së lartë me çmime të arsyeshme, duke siguruar që ato janë gjithmonë në dispozicion kur nevojiten dhe duke ofruar cilësi shërbimi jashtëzakonisht të mirë që tejkalon pritshmëritë e tyre.",
    de: "Unser Ziel bei TAFA-S, auch bekannt als AUTO ONE und AOTS, ist es, uns als die führende Marke im unabhängigen Aftermarket für die Automobilindustrie zu etablieren. Dies möchten wir erreichen, indem wir unseren Kunden das breiteste Sortiment an hochwertigen Produkten zu erschwinglichen Preisen anbieten, sicherstellen, dass sie immer dann verfügbar sind, wenn sie benötigt werden, und eine außergewöhnliche Servicequalität bieten, die ihre Erwartungen übertrifft.",
    tr: "TAFA-S'deki amacımız, aynı zamanda AUTO ONE ve AOTS olarak da bilinen, Kendi Bağımsız Otomotiv Pazarı'nda öncü marka olarak kendimizi kurmaktır. Bunun için müşterilerimize uygun fiyatlarla en geniş yelpazede yüksek kaliteli ürünler sunarak, ihtiyaç duyulduğunda her zaman mevcut olduklarını sağlayarak ve beklentilerini aşan olağanüstü hizmet kalitesi sunarak bunu başarmayı hedefliyoruz.",
    fr: "Notre objectif chez TAFA-S, également connu sous le nom d'AUTO ONE et AOTS, est de nous établir comme la marque de premier plan sur le marché secondaire indépendant de l'industrie automobile. Nous visons à y parvenir en proposant à nos clients la gamme la plus large de produits de haute qualité à des prix abordables, en veillant à ce qu'ils soient toujours disponibles en cas de besoin et en offrant une qualité de service exceptionnelle qui dépasse leurs attentes.",
    it: "Il nostro obiettivo presso TAFA-S, noto anche come AUTO ONE e AOTS, è quello di imporci come marchio principale nel mercato secondario indipendente dell'industria automobilistica. Miriamo a raggiungere questo obiettivo offrendo ai nostri clienti la più ampia gamma di prodotti di alta qualità a prezzi accessibili, garantendo che siano sempre disponibili quando necessario e offrendo un servizio di qualità eccezionale che supera le loro aspettative.",
    es: "Nuestro objetivo en TAFA-S, también conocido como AUTO ONE y AOTS, es establecernos como la marca líder en el mercado secundario independiente de la industria automotriz. Nuestro objetivo es lograr esto ofreciendo a nuestros clientes la gama más amplia de productos de alta calidad a precios asequibles, asegurando que estén siempre disponibles cuando sea necesario y ofreciendo una calidad de servicio excepcional que supere sus expectativas.",
    pt: "Nosso objetivo na TAFA-S, também conhecida como AUTO ONE e AOTS, é nos estabelecer como a principal marca no mercado secundário independente da indústria automobilística. Pretendemos alcançar isso oferecendo aos nossos clientes a mais ampla gama de produtos de alta qualidade a preços acessíveis, garantindo que estejam sempre disponíveis quando necessário e oferecendo uma qualidade de serviço excepcional que supere suas expectativas.",
    gr: "Ο στόχος μας στην TAFA-S, επίσης γνωστή ως AUTO ONE και AOTS, είναι να εδραιωθούμε ως η κορυφαία μάρκα στην ανεξάρτητη αγορά ανταλλακτικών για την αυτοκινητοβιομηχανία. Στοχεύουμε να το επιτύχουμε παρέχοντας στους πελάτες μας την πληρέστερη γκάμα υψηλής ποιότητας προϊόντων σε προσιτές τιμές, εξασφαλίζοντας ότι είναι πάντα διαθέσιμα",
    ru: "Наша цель в TAFA-S, также известной как AUTO ONE и AOTS, - утвердиться как ведущий бренд на рынке независимого запчастей для автомобильной промышленности. Мы стремимся достичь этого, предоставляя нашим клиентам самый широкий ассортимент высококачественной продукции по доступным ценам, гарантируя, что они всегда доступны",
    rs: "Циљ у TAFA-S, такође познатом као AUTO ONE и AOTS, је да се утврди као вођећи бренд на тржишту независних делова за аутомобилску индустрију. Циљ нам је да то постигнемо пружајући нашим клијентима најшироки асортиман висококвалитетних производа по доступним ценама, осигуравајући да су увек доступни",
  },
  VisionTwo: {
    en: "To accomplish this objective, we have a dedicated team of professionals who work tirelessly to source the best products from reputable suppliers around the world. We are committed to maintaining strong relationships with our suppliers to ensure that we can deliver on our promise of offering the best range of products to our customers.",
    al: "Për të arritur këtë objektiv, ne kemi një ekip të specializuar të profesionistëve që punojnë pa pushim për të gjetur produktet më të mira nga furnizuesit e besueshëm në të gjithë botën. Ne jemi të angazhuar për të mbajtur marrëdhëniet e forta me furnizuesit tanë për të siguruar se mund të ofrojmë gamën më të mirë të produkteve për klientët tanë.",
    de: "Um dieses Ziel zu erreichen, haben wir ein engagiertes Team von Fachleuten, die unermüdlich daran arbeiten, die besten Produkte von seriösen Lieferanten auf der ganzen Welt zu beschaffen. Wir sind entschlossen, starke Beziehungen zu unseren Lieferanten aufrechtzuerhalten, um sicherzustellen, dass wir unser Versprechen einhalten können, unseren Kunden die beste Produktpalette anzubieten.",
    tr: "Bu hedefi gerçekleştirmek için dünya çapında saygın tedarikçilerden en iyi ürünleri temin etmek için gayretle çalışan uzman bir ekibimiz var. Müşterilerimize en iyi ürün yelpazesini sunma sözümüzü yerine getirebilmek için tedarikçilerimizle güçlü ilişkiler sürdürme konusunda kararlıyız.",
    fr: "Pour atteindre cet objectif, nous disposons d'une équipe dédiée de professionnels qui travaillent sans relâche pour sourcer les meilleurs produits auprès de fournisseurs réputés du monde entier. Nous nous engageons à maintenir des relations solides avec nos fournisseurs pour nous assurer que nous pouvons tenir notre promesse d'offrir la meilleure gamme de produits à nos clients.",
    it: "Per raggiungere questo obiettivo, abbiamo un team dedicato di professionisti che lavorano instancabilmente per reperire i migliori prodotti dai fornitori affidabili in tutto il mondo. Siamo impegnati a mantenere forti relazioni con i nostri fornitori per garantire di poter rispettare la nostra promessa di offrire la migliore gamma di prodotti ai nostri clienti.",
    es: "Para lograr este objetivo, contamos con un equipo dedicado de profesionales que trabajan incansablemente para obtener los mejores productos de proveedores confiables en todo el mundo. Estamos comprometidos a mantener relaciones sólidas con nuestros proveedores para asegurarnos de poder cumplir nuestra promesa de ofrecer la mejor gama de productos a nuestros clientes.",
    pt: "Para alcançar este objetivo, temos uma equipe dedicada de profissionais que trabalham incansavelmente para obter os melhores produtos de fornecedores confiáveis em todo o mundo. Estamos comprometidos em manter relacionamentos sólidos com nossos fornecedores para garantir que possamos cumprir nossa promessa de oferecer a melhor variedade de produtos aos nossos clientes.",
    gr: "Για να επιτύχουμε αυτόν τον στόχο, έχουμε μια αφοσιωμένη ομάδα επαγγελματιών που εργάζονται αδιάκοπα για να πηγαίνουν τα καλύτερα προϊόντα από αξιόπιστους προμηθευτές σε όλο τον κόσμο. Είμαστε αφοσιωμένοι στη διατήρηση ισχυρών σχέσεων με τους προμηθευτές μας για να εξασφαλίσουμε ότι μπορούμε να τηρήσουμε την υπόσχεσή μας να προσφέρουμε την καλύτερη ποικιλία προϊόντων στους πελάτες μας.",
    ru: "Для достижения этой цели у нас есть специальная команда профессионалов, которые неустанно работают над тем, чтобы находить лучшие продукты у надежных поставщиков по всему миру. Мы обязуемся поддерживать крепкие отношения с нашими поставщиками, чтобы гарантировать, что мы сможем выполнить наше обещание предложить нашим клиентам лучший ассортимент продукции.",
    rs: "За постизање овог циља имамо посебан тим професионалаца који неуморно раде на обезбеђивању најбољих производа од поузданих добављача широм света. Залажемо се за одржавање јаких односа са нашим добављачима како бисмо обезбедили да можемо испунити наше обећање да нашим клијентима понудимо најбољи асортиман производа.",

  },
  VisionThree: {
    en: "We also understand the importance of affordability for our customers, which is why we continuously strive to keep our prices competitive without sacrificing quality. We believe that everyone should have access to high-quality automotive products, regardless of their budget.",
    al: "Ne gjithashtu kuptojmë rëndësinë e arsyeshmërisë për klientët tanë, për këtë arsye ne vazhdimisht punojmë që të mbajmë çmimet tona konkurruese pa sakrifikuar cilësinë. Ne besojmë se secili duhet të ketë akses në produkte të automobilistikës me cilësi të lartë, pavarësisht nga buxheti i tyre.",
    de: "Wir verstehen auch die Bedeutung von Erschwinglichkeit für unsere Kunden, weshalb wir kontinuierlich daran arbeiten, unsere Preise wettbewerbsfähig zu halten, ohne die Qualität zu beeinträchtigen. Wir glauben, dass jeder Zugang zu hochwertigen Automobilprodukten haben sollte, unabhängig von ihrem Budget.",
    tr: "Müşterilerimiz için uygun fiyatlı olmanın önemini de anlıyoruz, bu nedenle sürekli olarak kaliteyi feda etmeden rekabetçi fiyatlarımızı korumaya çalışıyoruz. Herkesin bütçesinden bağımsız olarak yüksek kaliteli otomotiv ürünlerine erişimi olması gerektiğine inanıyoruz.",
    fr: "Nous comprenons également l'importance de l'accessibilité financière pour nos clients, c'est pourquoi nous nous efforçons continuellement de maintenir nos prix compétitifs sans sacrifier la qualité. Nous croyons que tout le monde devrait avoir accès à des produits automobiles de haute qualité, quel que soit leur budget.",
    it: "Comprendiamo anche l'importanza dell'accessibilità economica per i nostri clienti, ecco perché ci sforziamo continuamente di mantenere i nostri prezzi competitivi senza sacrificare la qualità. Crediamo che tutti dovrebbero avere accesso a prodotti automobilistici di alta qualità, indipendentemente dal loro budget.",
    es: "También entendemos la importancia de la asequibilidad para nuestros clientes, por eso nos esforzamos continuamente por mantener nuestros precios competitivos sin sacrificar la calidad. Creemos que todos deberían tener acceso a productos automotrices de alta calidad, independientemente de su presupuesto.",
    pt: "Entendemos também a importância da acessibilidade para os nossos clientes, por isso lutamos continuamente para manter os nossos preços competitivos sem sacrificar a qualidade. Acreditamos que todos devem ter acesso a produtos automotivos de alta qualidade, independentemente do seu orçamento.",
    gr: "Κατανοούμε επίσης τη σημασία της προσιτότητας για τους πελάτες μας, γι' αυτό και συνεχώς προσπαθούμε να διατηρούμε τις τιμές μας ανταγωνιστικές χωρίς να θυσιάζουμε την ποιότητα. Πιστεύουμε ότι όλοι πρέπει να έχουν πρόσβαση σε υψηλής ποιότητας προϊόντα αυτοκινήτου, ανεξαρτήτως του προϋπολογισμού τους.",
    ru: "Мы также понимаем важность доступности для наших клиентов, поэтому мы постоянно стремимся сохранять конкурентоспособные цены, не жертвуя качеством. Мы считаем, что каждый должен иметь доступ к высококачественным автомобильным продуктам, независимо от их бюджета.",
    rs: "Такође разумемо значај доступности за наше клијенте, зато се постојано трудимо да задржимо наше цене конкурентнима без угрожавања квалитета. Верујемо да сви треба да имају приступ висококвалитетним аутомобилским производима, независно од њиховог буџета.",
  },
  VisionFour: {
    en: "Moreover, we realize that timely availability is crucial in the automotive industry, where every minute counts. Therefore, we maintain a robust inventory management system that ensures that our products are always in stock and ready to ship whenever our customers need them.",
    al: "Për më tepër, ne kuptojmë se disponueshmëria kohore është e rëndësishme në industrinë e automjeteve, ku çdo minutë llogaritet. Prandaj, ne mbajmë një sistem të fortë menaxhimi inventari që siguron që produktet tona janë gjithmonë në dispozicion dhe të gatshme për dërgesë kurdo që klientët tanë i kanë nevojë.",
    de: "Darüber hinaus erkennen wir, dass rechtzeitige Verfügbarkeit in der Automobilindustrie, wo jede Minute zählt, entscheidend ist. Daher unterhalten wir ein robustes Inventarmanagementsystem, das sicherstellt, dass unsere Produkte immer vorrätig sind und sofort versandbereit sind, wenn unsere Kunden sie benötigen.",
    tr: "Ayrıca, her dakikanın önemli olduğu otomotiv endüstrisinde zamanında bulunabilirliğin kritik olduğunun farkındayız. Bu nedenle, ürünlerimizin her zaman stokta olduğundan ve müşterilerimizin ihtiyaç duyduğunda hemen sevk edilebilecek durumda olduğundan emin olmak için sağlam bir envanter yönetim sistemini sürdürüyoruz.",
    fr: "De plus, nous réalisons que la disponibilité en temps opportun est cruciale dans l'industrie automobile, où chaque minute compte. Par conséquent, nous maintenons un système robuste de gestion des stocks qui garantit que nos produits sont toujours en stock et prêts à être expédiés dès que nos clients en ont besoin.",
    it: "Inoltre, ci rendiamo conto che la disponibilità tempestiva è cruciale nell'industria automobilistica, dove ogni minuto conta. Pertanto, manteniamo un robusto sistema di gestione dell'inventario che garantisce che i nostri prodotti siano sempre disponibili e pronti per la spedizione ogni volta che i nostri clienti ne hanno bisogno.",
    es: "Además, nos damos cuenta de que la disponibilidad oportuna es crucial en la industria automotriz, donde cada minuto cuenta. Por lo tanto, mantenemos un sólido sistema de gestión de inventario que garantiza que nuestros productos estén siempre en stock y listos para enviar siempre que nuestros clientes los necesiten.",
    pt: "Além disso, percebemos que a disponibilidade oportuna é crucial na indústria automotiva, onde cada minuto conta. Portanto, mantemos um robusto sistema de gerenciamento de estoque que garante que nossos produtos estejam sempre em estoque e prontos para envio sempre que nossos clientes precisarem.",
    gr: "Επιπλέον, συνειδητοποιούμε ότι η έγκαιρη διαθεσιμότητα είναι κρίσιμη στην αυτοκινητοβιομηχανία, όπου κάθε λεπτό μετράει. Γι' αυτό, διατηρούμε ένα ανθεκτικό σύστημα διαχείρισης αποθεμάτων που εξασφαλίζει ότι τα προϊόντα μας είναι πάντα σε απόθεμα και έτοιμα για αποστολή όποτε οι πελάτες μας τα χρειάζονται.",
    ru: "Более того, мы понимаем, что своевременная доступность имеет решающее значение в автомобильной промышленности, где каждая минута имеет значение. Поэтому мы поддерживаем надежную систему управления запасами, которая гарантирует, что наши продукты всегда в наличии и готовы к отправке в любое время, когда это нужно нашим клиентам.",
    rs: "Поред тога, схватамо значај својевремене доступности у аутомобилској индустрији, где свака минута има значај. Зато имамо јак систем управљања инвентаром који осигурава да наши производи увек буду на залихи и спремни за испоруку када наши клијенти их требају.",
  },
  VisionFive: {
    en: "Finally, our commitment to exceptional service quality is reflected in everything we do. From our knowledgeable customer service representatives to our fast and reliable shipping, we go above and beyond to ensure that our customers have a positive experience every time they interact with us.",
    al: "Fundja, angazhimi ynë për cilësi të jashtëzakonshme të shërbimit gjendet në çdo gjë që bëjmë. Nga përfaqësuesit tanë të njohur të shërbimit ndaj klientëve deri te dërgesat tona të shpejta dhe të besueshme, ne bëjmë më shumë se sa i kërkon detyra për të siguruar që klientët tanë kanë një përvojë pozitive çdo herë që ndërveprojnë me ne.",
    de: "Schließlich spiegelt sich unser Engagement für außergewöhnliche Servicequalität in allem wider, was wir tun. Von unseren sachkundigen Kundenbetreuern bis hin zu unserem schnellen und zuverlässigen Versand tun wir alles, um sicherzustellen, dass unsere Kunden bei jeder Interaktion mit uns eine positive Erfahrung machen.",
    tr: "Son olarak, olağanüstü hizmet kalitesine olan bağlılığımız yaptığımız her şeyde yansır. Bilgili müşteri hizmetleri temsilcilerimizden hızlı ve güvenilir kargomuza kadar, müşterilerimizin her etkileşiminde olumlu bir deneyim yaşamalarını sağlamak için gerekenin ötesine geçiyoruz.",
    fr: "Enfin, notre engagement envers la qualité de service exceptionnelle se reflète dans tout ce que nous faisons. De nos représentants du service clientèle compétents à notre expédition rapide et fiable, nous faisons tout notre possible pour garantir que nos clients aient une expérience positive à chaque interaction avec nous.",
    it: "Infine, il nostro impegno per la qualità del servizio eccezionale si riflette in tutto ciò che facciamo. Dai nostri competenti rappresentanti del servizio clienti alla nostra spedizione veloce e affidabile, facciamo il massimo per garantire che i nostri clienti abbiano un'esperienza positiva ogni volta che interagiscono con noi.",
    es: "Finalmente, nuestro compromiso con la calidad excepcional del servicio se refleja en todo lo que hacemos. Desde nuestros representantes de servicio al cliente conocedores hasta nuestro envío rápido y confiable, hacemos todo lo posible para garantizar que nuestros clientes tengan una experiencia positiva cada vez que interactúan con nosotros.",
    pt: "Finalmente, nosso compromisso com a qualidade excepcional do serviço é refletido em tudo o que fazemos. Desde nossos representantes de serviço ao cliente experientes até nosso envio rápido e confiável, fazemos o máximo para garantir que nossos clientes tenham uma experiência positiva toda vez que interagem conosco.",
    gr: "Τέλος, το δέσμευτήριό μας για εξαιρετική ποιότητα εξυπηρέτησης αντανακλάται σε ό,τι κάνουμε. Από τους εμπειρογνώμονες αντιπροσώπους των πελατών μας έως τη γρήγορη και αξιόπιστη αποστολή μας, κάνουμε ό,τι είναι απαραίτητο για να διασφαλίσουμε ότι οι πελάτες μας έχουν θετική εμπειρία κάθε φορά που αλληλεπιδρούν μαζί μας.",
    ru: "Наконец, наша приверженность высокому качеству обслуживания отражается во всем, что мы делаем. От наших компетентных представителей обслуживания клиентов до быстрой и надежной доставки, мы делаем все возможное, чтобы обеспечить положительный опыт наших клиентов при каждом взаимодействии с нами.",
    rs: "Коначно, наше обећање изузетном квалитету услуга одражава се у свему што радимо. Од наших познатих представника за корисничку подршку до наше брзе и популарне испоруке, ми идемо далеко како бисмо обезбедили да наши клијенти имају позитивно искуство сваки пут када комуницирају с нама.",
  },
  VisionSix: {
    en: "In summary, at TAFA-S, AUTO ONE, and AOTS, we are dedicated to becoming the leading brand in the Independent Aftermarket for Automotive Industry by offering the best range of products, affordability, availability, and service quality.",
    al: "Në krye të të gjitha, në TAFA-S, AUTO ONE dhe AOTS, ne jemi të dedikuar për të bërë veten tonë një markë udhëheqëse në tregun e paslëndëve të pavarura për industrinë e automjeteve duke ofruar gamën më të mirë të produkteve, arsyeshmëri, disponueshmëri dhe cilësi të shërbimit.",
    de: "Zusammenfassend sind wir bei TAFA-S, AUTO ONE und AOTS bestrebt, die führende Marke im unabhängigen Aftermarket der Automobilindustrie zu werden, indem wir das beste Sortiment an Produkten, bezahlbare Preise, Verfügbarkeit und Servicequalität anbieten.",
    tr: "Özetle, TAFA-S, AUTO ONE ve AOTS'ta, otomotiv endüstrisi için Bağımsız Satış Sonrası Pazarında öncü marka olma hedefimize, en iyi ürün yelpazesini, uygun fiyatları, erişilebilirliği ve hizmet kalitesini sunarak ulaşmayı taahhüt ediyoruz.",
    fr: "En résumé, chez TAFA-S, AUTO ONE et AOTS, nous nous engageons à devenir la marque leader sur le marché secondaire indépendant de l'industrie automobile en offrant la meilleure gamme de produits, des prix abordables, une disponibilité et une qualité de service.",
    it: "In sintesi, presso TAFA-S, AUTO ONE e AOTS, ci impegniamo a diventare il marchio leader nel mercato secondario indipendente dell'industria automobilistica offrendo la migliore gamma di prodotti, prezzi accessibili, disponibilità e qualità del servizio.",
    es: "En resumen, en TAFA-S, AUTO ONE y AOTS, nos dedicamos a convertirnos en la marca líder en el mercado de posventa independiente para la industria automotriz ofreciendo la mejor gama de productos, asequibilidad, disponibilidad y calidad de servicio.",
    pt: "Resumidamente, na TAFA-S, AUTO ONE e AOTS, estamos empenhados em nos tornar a marca líder no mercado secundário independente da indústria automotiva, oferecendo a melhor gama de produtos, acessibilidade, disponibilidade e qualidade de serviço.",
    gr: "Συνοπτικά, στην TAFA-S, AUTO ONE και AOTS, αφιερώνουμε τον εαυτό μας για να γίνουμε η κορυφαία μάρκα στην ανεξάρτητη αγορά μεταπωλήσεων για την αυτοκινητοβιομηχανία προσφέροντας την καλύτερη ποικιλία προϊόντων, προσιτές τιμές, διαθεσιμότητα και ποιότητα εξυπηρέτησης.",
    ru: "В заключение, в TAFA-S, AUTO ONE и AOTS мы стремимся стать ведущим брендом на независимом рынке послепродажного обслуживания автомобильной промышленности, предлагая лучший ассортимент продукции, доступные цены, наличие и качество обслуживания.",
    rs: "Укратко, наша ангажованост да будемо вођајући бренд на независном послепродажном тржишту аутомобилске индустрије рефлектира се у свему што радимо. Од нашег најбољег асортимана производа, доступних цена, до испоруке и квалитета услуга, ми се обављамо дужност да обезбедимо позитивно искуство за наше клијенте у сваком контакту с нама.",
  },
  ContactUsPageTitle: {
    en: "Contact Us",
    al: "Na Kontaktoni",
    de: "Kontaktiere uns",
    tr: "Bize Ulaşın",
    fr: "Contactez-nous",
    it: "Contattaci",
    es: "Contáctenos",
    pt: "Entre em contato",
    gr: "Επικοινωνήστε μαζί μας",
    ru: "Свяжитесь с нами",
    rs: "Контактирајте нас",
  },
  ContactUsPageDesc: {
    en: "Please feel free to contact us with any questions, suggestions, or business opportunities using the form below or by emailing us directly. We appreciate your interest and look forward to hearing from you!",
    al: "Për çdo pyetje, sugjerim ose mundësi biznesi, ju lutemi të na kontaktoni duke përdorur formularin e mëposhtëm ose të na shkruani në adresën tonë email. Ju falënderojmë për interesimin tuaj dhe presim me padurim të dëgjojmë nga ju!",
    de: "Bitte zögern Sie nicht, uns bei Fragen, Anregungen oder Geschäftsmöglichkeiten über das untenstehende Formular oder direkt per E-Mail zu kontaktieren. Wir schätzen Ihr Interesse und freuen uns darauf, von Ihnen zu hören!",
    tr: "Lütfen aşağıdaki formu kullanarak veya doğrudan bize e-posta göndererek herhangi bir soru, öneri veya iş fırsatı için bizimle iletişime geçmekten çekinmeyin. İlgilenmenizden dolayı teşekkür ederiz ve sizden duymak için sabırsızlanıyoruz!",
    fr: "N'hésitez pas à nous contacter pour toute question, suggestion ou opportunité commerciale en utilisant le formulaire ci-dessous ou en nous envoyant un e-mail directement. Nous apprécions votre intérêt et avons hâte de vous entendre!",
    it: "Non esitate a contattarci per qualsiasi domanda, suggerimento o opportunità di business utilizzando il modulo qui sotto o inviandoci un'email direttamente. Apprezziamo il vostro interesse e non vediamo l'ora di sentire da voi!",
    es: "No dude en contactarnos con cualquier pregunta, sugerencia u oportunidad de negocio utilizando el formulario a continuación o enviándonos un correo electrónico directamente. ¡Apreciamos su interés y esperamos tener noticias suyas!",
    pt: "Não hesite em nos contatar com qualquer pergunta, sugestão ou oportunidade de negócio usando o formulário abaixo ou nos enviando um e-mail diretamente. Agradecemos o seu interesse e aguardamos o seu contato!",
    gr: "Μη διστάσετε να επικοινωνήσετε μαζί μας για οποιαδήποτε ερώτηση, πρόταση ή επιχειρηματική ευκαιρία χρησιμοποιώντας την παρακάτω φόρμα ή αποστέλλοντάς μας άμεσα ηλεκτρονικό ταχυδρομείο. Εκτιμούμε το ενδιαφέρον σας και ανυπομονούμε να ακούσουμε από εσάς!",
    ru: "Не стесняйтесь связаться с нами с любыми вопросами, предложениями или бизнес-возможностями, используя форму ниже или написав нам напрямую на электронную почту. Мы благодарим вас за проявленный интерес и с нетерпением ждем вашего ответа!",
    rs: "Не се колебајте да нас контактирате са било каквим питањима, предлозима или пословним приликама користећи форму испод или нам директно пошаљите е-пошту. Ценимо ваш интерес и очекујемо ваше обраћање!",

  },
  QualityFirstTitle: {
    en: "Quality Policy for an Auto Parts and Mechanic Company",
    al: "Politika e Cilësisë për një Kompani Pjesësh Automobilistike dhe Mekanike",
    de: "Qualitätspolitik für ein Unternehmen für Autoersatzteile und Mechanik",
    tr: "Oto Parça ve Mekanik Şirketi için Kalite Politikası",
    fr: "Politique Qualité pour une Entreprise de Pièces Automobiles et de Mécanique",
    it: "Politica della Qualità per un'azienda di Ricambi Auto e Meccanica",
    es: "Política de Calidad para una Empresa de Repuestos de Automóviles y Mecánica",
    pt: "Política de Qualidade para uma Empresa de Peças de Automóveis e Mecânica",
    gr: "Πολιτική Ποιότητας για μια Εταιρεία Ανταλλακτικών Αυτοκινήτων και Μηχανικής",
    ru: "Политика качества для компании по автозапчастям и механике",
    rs: "Политика квалитета за компанију за аутомобилске делове и механику",

  },
  QualitySecondTitle: {
    en: "Quality Policy",
    al: "Politika e Cilësisë",
    de: "Qualitätspolitik",
    tr: "Kalite Politikası",
    fr: "Politique Qualité",
    it: "Politica della Qualità",
    es: "Política de Calidad",
    pt: "Política de Qualidade",
    gr: "Πολιτική Ποιότητας",
    ru: "Политика качества",
    rs: "Политика квалитета",
  },
  QualityPolicyP1: {
    en: "At our auto parts and mechanic company, our top priority is providing our customers with high-quality products and services that are safe, reliable, and meet their needs. We are committed to achieving excellence in everything we do, and we believe that this commitment to quality is a key driver of our success.",
    al: "Në kompaninë tonë të pjesëve automobilistike dhe mekanike, prioriteti ynë kryesor është ofrimi i produkteve dhe shërbimeve të cilësisë së lartë për klientët tanë, të sigurta, të besueshme dhe që përmbushin nevojat e tyre. Jemi të përkushtuar për të arritur shkëlqimin në çdo gjë që bëjmë, dhe besojmë se ky përkushtim për cilësinë është një shofer kryesor i suksesit tonë.",
    de: "Bei unserem Unternehmen für Autoersatzteile und Mechanik ist unsere oberste Priorität, unseren Kunden hochwertige Produkte und Dienstleistungen anzubieten, die sicher, zuverlässig und ihren Bedürfnissen entsprechen. Wir sind entschlossen, in allem, was wir tun, Exzellenz zu erreichen, und wir glauben, dass dieses Bekenntnis zur Qualität ein wesentlicher Treiber unseres Erfolgs ist.",
    tr: "Oto yedek parça ve mekanik şirketimizde, müşterilerimize güvenli, güvenilir ve ihtiyaçlarını karşılayan yüksek kaliteli ürünler ve hizmetler sunmak en öncelikli hedefimizdir. Yaptığımız her şeyde mükemmeliyeti elde etmeye kararlıyız ve kaliteye olan bu bağlılığın başarımızın ana itici gücü olduğuna inanıyoruz.",
    fr: "Dans notre entreprise de pièces automobiles et mécaniques, notre priorité absolue est de fournir à nos clients des produits et services de haute qualité qui sont sûrs, fiables et répondent à leurs besoins. Nous nous engageons à atteindre l'excellence dans tout ce que nous faisons, et nous croyons que cet engagement envers la qualité est un moteur clé de notre succès.",
    it: "Presso la nostra azienda di ricambi auto e meccanica, la nostra priorità assoluta è fornire ai nostri clienti prodotti e servizi di alta qualità che siano sicuri, affidabili e soddisfino le loro esigenze. Siamo impegnati a raggiungere l'eccellenza in tutto ciò che facciamo, e crediamo che questo impegno per la qualità sia un driver chiave del nostro successo.",
    es: "En nuestra empresa de repuestos de automóviles y mecánica, nuestra principal prioridad es proporcionar a nuestros clientes productos y servicios de alta calidad que sean seguros, confiables y satisfagan sus necesidades. Estamos comprometidos a alcanzar la excelencia en todo lo que hacemos, y creemos que este compromiso con la calidad es un impulsor clave de nuestro éxito.",
    pt: "Em nossa empresa de peças de automóveis e mecânica, nossa principal prioridade é fornecer aos nossos clientes produtos e serviços de alta qualidade que sejam seguros, confiáveis e atendam às suas necessidades. Estamos comprometidos em alcançar a excelência em tudo o que fazemos, e acreditamos que esse compromisso com a qualidade é um impulsionador chave de nosso sucesso.",
    gr: "Στην εταιρεία ανταλλακτικών αυτοκινήτων και μηχανικής μας, η κύρια προτεραιότητά μας είναι να παρέχουμε στους πελάτες μας υψηλής ποιότητας προϊόντα και υπηρεσίες που είναι ασφαλή, αξιόπιστα και ανταποκρίνονται στις ανάγκες τους. Είμαστε αφοσιωμένοι στην επίτευξη της αριστείας σε ό,τι κάνουμε, και πιστεύουμε ότι αυτή η δέσμευση στην ποιότητα είναι ένας κύριος κινητήριος παράγοντας της επιτυχίας μας.",
    ru: "В нашей компании по продаже автозапчастей и механике нашим главным приоритетом является предоставление нашим клиентам высококачественных продуктов и услуг, которые безопасны, надежны и соответствуют их потребностям. Мы стремимся к достижению превосходства во всем, что мы делаем, и мы верим, что это стремление к качеству является ключевым фактором нашего успеха.",
    rs: "U našoj kompaniji za auto delove i mehaniku, naš najvažniji prioritet je pružanje našim kupcima visokokvalitetnih proizvoda i usluga koji su sigurni, pouzdani i zadovoljavaju njihove potrebe. Posvećeni smo postizanju izvrsnosti u svemu što radimo, i verujemo da je ovo opredeljenje ka kvalitetu ključni pokretač našeg uspeha.",

  },
  QualityPolicyP2: {
    en: "To demonstrate this commitment, we have developed and implemented a comprehensive Quality Management System (QMS) that covers every aspect of our business. Our QMS is designed to ensure that we consistently meet or exceed customer expectations, comply with all relevant regulations, and continuously improve our processes, systems, and products.",
    al: "Për të treguar këtë përkushtim, kemi zhvilluar dhe zbatuar një Sistem Menaxhimi Cilësie (QMS) i gjerë që mbulon çdo aspekt të biznesit tonë. QMS ynë është projektuar për të siguruar që ne konsistentisht përmbushim ose tejkalohemi pritjet e klientëve, përmbushim të gjitha rregulloret relevante, dhe përmirësim të vazhdueshëm të proceseve, sistemeve, dhe produkteve tona.",
    de: "Um dieses Engagement zu demonstrieren, haben wir ein umfassendes Qualitätsmanagementsystem (QMS) entwickelt und implementiert, das jeden Aspekt unseres Geschäfts abdeckt. Unser QMS ist darauf ausgelegt, sicherzustellen, dass wir kontinuierlich die Erwartungen unserer Kunden erfüllen oder übertreffen, alle relevanten Vorschriften einhalten und unsere Prozesse, Systeme und Produkte kontinuierlich verbessern.",
    tr: "Bu taahhüdü göstermek için işimizin her yönünü kapsayan kapsamlı bir Kalite Yönetim Sistemi (QMS) geliştirdik ve uyguladık. QMS'ımız, müşteri beklentilerini sürekli olarak karşılayıp aşmamızı, tüm ilgili düzenlemelere uygun olmamızı ve süreçlerimizi, sistemlerimizi ve ürünlerimizi sürekli olarak iyileştirmemizi sağlamak amacıyla tasarlanmıştır.",
    fr: "Pour démontrer cet engagement, nous avons développé et mis en place un système complet de gestion de la qualité (QMS) qui couvre tous les aspects de notre activité. Notre QMS est conçu pour garantir que nous répondons ou dépassons constamment les attentes de nos clients, respectons toutes les réglementations pertinentes et améliorons continuellement nos processus, systèmes et produits.",
    it: "Per dimostrare questo impegno, abbiamo sviluppato e implementato un completo Sistema di Gestione della Qualità (QMS) che copre ogni aspetto del nostro business. Il nostro QMS è progettato per garantire che soddisfiamo o superiamo costantemente le aspettative dei nostri clienti, rispettiamo tutte le normative pertinenti e miglioriamo continuamente i nostri processi, sistemi e prodotti.",
    es: "Para demostrar este compromiso, hemos desarrollado e implementado un completo Sistema de Gestión de la Calidad (QMS) que cubre todos los aspectos de nuestro negocio. Nuestro QMS está diseñado para garantizar que cumplimos o superamos constantemente las expectativas de nuestros clientes, cumplimos con todas las regulaciones pertinentes y mejoramos continuamente nuestros procesos, sistemas y productos.",
    pt: "Para demonstrar esse compromisso, desenvolvemos e implementamos um abrangente Sistema de Gerenciamento da Qualidade (QMS) que abrange todos os aspectos de nosso negócio. Nosso QMS é projetado para garantir que atendemos ou excedemos consistentemente as expectativas de nossos clientes, cumprimos todas as regulamentações relevantes e melhoramos continuamente nossos processos, sistemas e produtos.",
    gr: "Για να επιδείξουμε αυτή τη δέσμευση, έχουμε αναπτύξει και υλοποιήσει ένα συνεκτικό Σύστημα Διαχείρισης Ποιότητας (QMS) που καλύπτει κάθε πτυχή της επιχείρησής μας. Το QMS μας σχεδιάστηκε για να εξασφαλίζει ότι πληρούμε συνεχώς ή υπερβαίνουμε τις προσδοκίες των πελατών μας, συμμορφωνόμαστε με όλους τους σχετικούς κανονισμούς και βελτιώνουμε συνεχώς τις διαδικασίες, τα συστήματα και τα προϊόντα μας.",
    ru: "Для демонстрации этого обязательства мы разработали и внедрили комплексную Систему управления качеством (QMS), которая охватывает все аспекты нашего бизнеса. Наша Система управления качеством спроектирована для того, чтобы гарантировать, что мы постоянно соответствуем или превосходим ожидания клиентов, соблюдаем все соответствующие нормативы и непрерывно улучшаем наши процессы, системы и продукты.",
    rs: "Da bismo demonstrirali ovu posvećenost, razvili smo i implementirali sveobuhvatan Sistem upravljanja kvalitetom (QMS) koji pokriva svaki aspekt našeg poslovanja. Naš QMS je dizajniran da osigura da konstantno ispunjavamo ili prevazilazimo očekivanja naših kupaca, poštujemo sve relevantne propise i neprekidno unapređujemo naše procese, sisteme i proizvode.",

  },
  QualityPolicyP3: {
    en: "One of the most important aspects of our QMS is our focus on continuous improvement. We believe that there is always room for improvement, and we are constantly seeking out new ways to enhance our products and services. This includes analyzing customer feedback, monitoring industry trends, and evaluating our own performance metrics to identify areas where we can make changes and improvements.",
    al: "Një nga aspektet më të rëndësishme të QMS tonë është fokusi ynë në përmirësimin e vazhdueshëm. Ne besojmë se gjithmonë ka hapësirë për përmirësime, dhe ne vazhdimisht po kërkojmë mënyra të reja për të përmirësuar produktet dhe shërbimet tona. Kjo përfshin analizimin e reagimeve të klientëve, monitorimin e tendencave në industrinë tonë, dhe vlerësimin e metrikave të performancës sonë për të identifikuar fushat ku mund të bëjmë ndryshime dhe përmirësime.",
    de: "Einer der wichtigsten Aspekte unseres QMS ist unser Fokus auf kontinuierliche Verbesserung. Wir glauben, dass es immer Raum für Verbesserungen gibt, und wir suchen ständig nach neuen Möglichkeiten, unsere Produkte und Dienstleistungen zu verbessern. Dazu gehört die Analyse von Kundenfeedback, die Überwachung von Branchentrends und die Bewertung unserer eigenen Leistungsmetriken, um Bereiche zu identifizieren, in denen wir Änderungen und Verbesserungen vornehmen können.",
    tr: "QMS'mizin en önemli yönlerinden biri sürekli iyileştirmeye odaklanmamızdır. Her zaman iyileştirme için alan olduğuna inanıyoruz ve ürünlerimizi ve hizmetlerimizi geliştirmek için sürekli yeni yollar arıyoruz. Bu, müşteri geri bildirimlerini analiz etmeyi, endüstri trendlerini izlemeyi ve kendi performans metriklerimizi değerlendirmeyi içerir, böylece değişiklikler ve iyileştirmeler yapabileceğimiz alanları belirleyebiliriz.",
    fr: "L'un des aspects les plus importants de notre QMS est notre focus sur l'amélioration continue. Nous croyons qu'il y a toujours place à l'amélioration, et nous recherchons constamment de nouvelles façons d'améliorer nos produits et services. Cela inclut l'analyse des retours clients, la surveillance des tendances de l'industrie et l'évaluation de nos propres indicateurs de performance pour identifier les domaines où nous pouvons apporter des changements et des améliorations.",
    it: "Uno degli aspetti più importanti del nostro QMS è il nostro focus sull'incremento continuo. Crediamo che ci sia sempre spazio per migliorare, e siamo costantemente alla ricerca di nuovi modi per migliorare i nostri prodotti e servizi. Questo include l'analisi dei feedback dei clienti, il monitoraggio delle tendenze del settore e la valutazione delle nostre metriche di performance per individuare le aree in cui possiamo apportare modifiche e miglioramenti.",
    es: "Uno de los aspectos más importantes de nuestro QMS es nuestro enfoque en la mejora continua. Creemos que siempre hay espacio para mejorar, y estamos constantemente buscando nuevas formas de mejorar nuestros productos y servicios. Esto incluye analizar los comentarios de los clientes, monitorear las tendencias de la industria y evaluar nuestras propias métricas de rendimiento para identificar áreas donde podemos realizar cambios y mejoras.",
    pt: "Um dos aspectos mais importantes do nosso QMS é o nosso foco na melhoria contínua. Acreditamos que sempre há espaço para melhorias, e estamos constantemente buscando novas formas de melhorar nossos produtos e serviços. Isso inclui analisar o feedback dos clientes, monitorar as tendências da indústria e avaliar nossas próprias métricas de desempenho para identificar áreas onde podemos fazer mudanças e melhorias.",
    gr: "Ένας από τους πιο σημαντικούς τομείς του QMS μας είναι ο εστίασμάς μας στη συνεχή βελτίωση. Πιστεύουμε ότι υπάρχει πάντα χώρος για βελτιώσεις, και αναζητούμε συνεχώς νέους τρόπους για να βελτιώσουμε τα προϊόντα και τις υπηρεσίες μας. Αυτό περιλαμβάνει την ανάλυση των σχολίων των πελατών, την παρακολούθηση των τάσεων της βιομηχανίας και την αξιολόγηση των δικών μας μετρήσεων απόδοσης για την εντοπισμό περιοχών όπου μπορούμε να κάνουμε αλλαγές και βελτιώσεις.",
    ru: "Одним из самых важных аспектов нашей Системы управления качеством (QMS) является наше внимание на непрерывное улучшение. Мы считаем, что всегда есть место для улучшения, и поэтому постоянно ищем новые способы улучшения наших продуктов и услуг. Это включает анализ обратной связи от клиентов, отслеживание тенденций в отрасли и оценку наших собственных показателей производительности, чтобы выявить области, где мы можем внести изменения и улучшения.",
    rs: "Jedan od najvažnijih aspekata našeg QMS-a je naš fokus na kontinuirano poboljšanje. Verujemo da uvek ima mesta za poboljšanje, i stalno tražimo nove načine za unapređenje naših proizvoda i usluga. To uključuje analizu povratnih informacija od kupaca, praćenje trendova u industriji i evaluaciju naših sopstvenih metrika performansi kako bismo identifikovali oblasti u kojima možemo napraviti promene i poboljšanja.",
  },
  QualityPolicyP4: {
    en: "In addition to our focus on continuous improvement, we also prioritize employee involvement in our Quality Management System (QMS). We recognize that our employees are our most valuable asset, and we are committed to providing them with the training, resources, and support they need to excel in their roles. By empowering our employees to take ownership of their work and contribute to the success of the company, we are able to create a culture of excellence that is driven by our shared commitment to quality.",
    al: "Përveç fokusit tonë në përmirësimin e vazhdueshëm, gjithashtu kemi prioritet pjesëmarrjen e punonjësve në Sistemin tonë të Menaxhimit të Cilësisë (QMS). Ne e njohim se punonjësit tanë janë pasuria jonë më e çmuar, dhe jemi të përkushtuar të sigurojmë për ta trajnimin, burimet, dhe mbështetjen që kanë nevojë për të shkëlqyer në rolet e tyre. Duke fuqizuar punonjësit tanë të marrin përgjegjësinë për punën e tyre dhe të kontribuojnë në suksesin e kompanisë, ne jemi në gjendje të krijojmë një kulturë shkëlqimi që është ndihmuar nga përkushtimi ynë i përbashkët ndaj cilësisë.",
    de: "Neben unserem Fokus auf kontinuierliche Verbesserung priorisieren wir auch die Mitarbeiterbeteiligung an unserem Qualitätsmanagementsystem (QMS). Wir erkennen an, dass unsere Mitarbeiter unser wertvollstes Kapital sind, und wir sind entschlossen, ihnen die Schulungen, Ressourcen und Unterstützung zu bieten, die sie benötigen, um in ihren Rollen herausragende Leistungen zu erbringen. Indem wir unsere Mitarbeiter dazu ermächtigen, Verantwortung für ihre Arbeit zu übernehmen und zum Erfolg des Unternehmens beizutragen, können wir eine Kultur der Exzellenz schaffen, die von unserem gemeinsamen Einsatz für Qualität getragen wird.",
    tr: "Sürekli iyileştirmeye odaklanmanın yanı sıra, Çalışan Katılımını da Kalite Yönetim Sistemimizde (QMS) önceliklendiriyoruz. Çalışanlarımızın en değerli varlığımız olduğunu kabul ediyor ve rollerinde başarılı olmaları için ihtiyaç duydukları eğitim, kaynak ve destekleri sağlama konusunda kararlıyız. Çalışanlarımıza işlerinin sorumluluğunu almaları ve şirketin başarısına katkıda bulunmaları için güç vererek, ortak kaliteye olan bağlılığımızla yönlendirilen bir mükemmellik kültürü yaratabiliyoruz.",
    fr: "En plus de notre focalisation sur l'amélioration continue, nous accordons également la priorité à l'implication des employés dans notre système de gestion de la qualité (QMS). Nous reconnaissons que nos employés sont notre atout le plus précieux, et nous nous engageons à leur fournir la formation, les ressources et le soutien dont ils ont besoin pour exceller dans leurs rôles. En donnant à nos employés le pouvoir de prendre en charge leur travail et de contribuer au succès de l'entreprise, nous sommes en mesure de créer une culture d'excellence qui est motivée par notre engagement commun envers la qualité.",
    it: "Oltre al nostro focus sull'incremento continuo, diamo anche priorità al coinvolgimento dei dipendenti nel nostro Sistema di Gestione della Qualità (QMS). Riconosciamo che i nostri dipendenti sono il nostro asset più prezioso, e siamo impegnati a fornire loro la formazione, le risorse e il supporto di cui hanno bisogno per eccellere nei loro ruoli. Empowerando i nostri dipendenti a prendere in mano il loro lavoro e contribuire al successo dell'azienda, siamo in grado di creare una cultura dell'eccellenza guidata dal nostro impegno condiviso per la qualità.",
    es: "Además de nuestro enfoque en la mejora continua, también priorizamos la participación de los empleados en nuestro Sistema de Gestión de Calidad (QMS). Reconocemos que nuestros empleados son nuestro activo más valioso, y estamos comprometidos a proporcionarles la formación, recursos y apoyo que necesitan para destacar en sus roles. Al empoderar a nuestros empleados para que asuman la responsabilidad de su trabajo y contribuyan al éxito de la empresa, podemos crear una cultura de excelencia que esté impulsada por nuestro compromiso compartido con la calidad.",
    pt: "Além do nosso foco na melhoria contínua, também priorizamos o envolvimento dos funcionários em nosso Sistema de Gerenciamento da Qualidade (QMS). Reconhecemos que nossos funcionários são nosso ativo mais valioso, e estamos comprometidos em fornecer a eles o treinamento, os recursos e o suporte de que precisam para se destacar em seus papéis. Ao capacitar nossos funcionários para assumir a responsabilidade por seu trabalho e contribuir para o sucesso da empresa, podemos criar uma cultura de excelência impulsionada por nosso compromisso compartilhado com a qualidade.",
    gr: "Εκτός από την εστίασή μας στη συνεχή βελτίωση, δίνουμε προτεραιότητα και στην συμμετοχή των εργαζομένων στο Σύστημα Διαχείρισης Ποιότητας (QMS) μας. Αναγνωρίζουμε ότι οι εργαζόμενοι μας είναι το πολυτιμότερο μας κεφάλαιο, και είμαστε αφοσιωμένοι στο να τους παρέχουμε την κατάρτιση, τους πόρους και την υποστήριξη που χρειάζονται για να ξεχωρίσουν στους ρόλους τους. Ενθαρρύνοντας τους εργαζομένους μας να αναλάβουν την ευθύνη της δουλειάς τους και να συμβάλλουν στην επιτυχία της εταιρείας, μπορούμε να δημιουργήσουμε μια κουλτούρα αριστείας που οδηγείται από την κοινή μας δέσμευση για την ποιότητα.",
    ru: "Помимо нашего фокуса на непрерывное улучшение, мы также придаем приоритет участию сотрудников в нашей Системе управления качеством (QMS). Мы признаем, что наши сотрудники - наше самое ценное достояние, и мы обязуемся обеспечить их обучением, ресурсами и поддержкой, необходимыми для успешного выполнения их ролей. Давая нашим сотрудникам возможность брать ответственность за свою работу и вносить вклад в успех компании, мы способны создать культуру превосходства, которую определяет наше общее стремление к качеству.",
    rs: "Pored našeg fokusa na kontinuirano unapređenje, takođe dajemo prioritet uključivanju zaposlenih u naš Sistem upravljanja kvalitetom (QMS). Prepoznajemo da su naši zaposleni naše najvrednije bogatstvo, i obavezujemo se da im pružimo obuku, resurse i podršku koji su im potrebni da se istaknu u svojim ulogama. Omogućavanjem našim zaposlenima da preuzmu odgovornost za svoj rad i doprinesu uspehu kompanije, sposobni smo da stvorimo kulturu izvrsnosti koju pokreće naše zajedničko opredeljenje ka kvalitetu."
  },
  QualityP1: {
    en: "We are committed to meeting or exceeding customer expectations for quality, safety, and reliability in all of our products and services.",
    al: " Jemi të përkushtuar për të përmbushur ose tejkaluar pritjet e klientëve për cilësi, siguri dhe besueshmëri në të gjitha produktet dhe shërbimet tona.",
    de: "Wir sind verpflichtet, die Erwartungen unserer Kunden an Qualität, Sicherheit und Zuverlässigkeit in all unseren Produkten und Dienstleistungen zu erfüllen oder zu übertreffen.",
    tr: "Tüm ürün ve hizmetlerimizde müşteri beklentilerini kalite, güvenlik ve güvenilirlik açısından karşılamayı veya aşmayı taahhüt ediyoruz.",
    fr: "Nous nous engageons à répondre aux attentes de nos clients en matière de qualité, de sécurité et de fiabilité dans tous nos produits et services.",
    it: "Ci impegniamo a soddisfare o superare le aspettative dei nostri clienti in termini di qualità, sicurezza e affidabilità in tutti i nostri prodotti e servizi.",
    es: "Nos comprometemos a cumplir o superar las expectativas de nuestros clientes en cuanto a calidad, seguridad y fiabilidad en todos nuestros productos y servicios.",
    pt: "Comprometemo-nos a atender ou exceder as expectativas dos nossos clientes em termos de qualidade, segurança e confiabilidade em todos os nossos produtos e serviços.",
    gr: "Δεσμευόμαστε να ικανοποιούμε ή να υπερβαίνουμε τις προσδοκίες των πελατών μας για ποιότητα, ασφάλεια και αξιοπιστία σε όλα τα προϊόντα και τις υπηρεσίες μας.",
    ru: "Мы обязаны соответствовать или превышать ожидания клиентов по качеству, безопасности и надежности всех наших продуктов и услуг.",
    rs: "Obavezujemo se da ispunimo ili premašimo očekivanja kupaca u pogledu kvaliteta, sigurnosti i pouzdanosti svih naših proizvoda i usluga."

  },
  QualityP2: {
    en: "We comply with all applicable regulations and industry standards related to our products and services.",
    al: "Ne përmbushim të gjitha rregulloret dhe standardet e industrisë të aplikueshme lidhur me produktet dhe shërbimet tona.",
    de: "Wir entsprechen allen geltenden Vorschriften und Branchenstandards in Bezug auf unsere Produkte und Dienstleistungen.",
    tr: "Ürün ve hizmetlerimizle ilgili tüm geçerli düzenlemelere ve endüstri standartlarına uyuyoruz.",
    fr: "Nous respectons toutes les réglementations et normes de l'industrie applicables à nos produits et services.",
    it: "Ci atteniamo a tutti i regolamenti e standard del settore applicabili ai nostri prodotti e servizi.",
    es: "Cumplimos con todas las regulaciones y normas de la industria aplicables a nuestros productos y servicios.",
    pt: "Cumprimos todas as regulamentações e normas da indústria aplicáveis aos nossos produtos e serviços.",
    gr: "Τηρούμε όλους τους ισχύοντες κανονισμούς και πρότυπα της βιομηχανίας που σχετίζονται με τα προϊόντα και τις υπηρεσίες μας.",
    ru: "Мы соблюдаем все применимые нормативные акты и стандарты отрасли, касающиеся наших продуктов и услуг.",
    rs: "Poštujemo sve primenljive propise i standarde industrije koji se odnose na naše proizvode i usluge."

  },
  QualityP3: {
    en: "We continually monitor and improve our performance through the use of data-driven metrics and regular reviews of our Quality Management System (QMS).",
    al: "Ne vazhdimisht monitorojmë dhe përmirësojmë performancën tonë përmes përdorimit të metrikave të bazuar në të dhëna dhe përmes shqyrtimit të rregullt të Sistemit tonë të Menaxhimit të Cilësisë (QMS).",
    de: "Wir überwachen und verbessern kontinuierlich unsere Leistung durch die Verwendung von datengesteuerten Metriken und regelmäßige Überprüfungen unseres Qualitätsmanagementsystems (QMS).",
    tr: "Veriye dayalı metrikler kullanarak ve Kalite Yönetim Sistemimizi (QMS) düzenli olarak gözden geçirerek performansımızı sürekli izliyor ve geliştiriyoruz.",
    fr: "Nous surveillons et améliorons en permanence notre performance grâce à l'utilisation de métriques basées sur les données et à des examens réguliers de notre système de gestion de la qualité (QMS).",
    it: "Monitoriamo e miglioriamo continuamente le nostre prestazioni attraverso l'uso di metriche basate sui dati e revisioni regolari del nostro sistema di gestione della qualità (QMS).",
    es: "Continuamente monitoreamos y mejoramos nuestro rendimiento mediante el uso de métricas basadas en datos y revisiones regulares de nuestro Sistema de Gestión de Calidad (QMS).",
    pt: "Monitoramos e melhoramos continuamente nosso desempenho por meio do uso de métricas baseadas em dados e revisões regulares de nosso Sistema de Gerenciamento da Qualidade (QMS).",
    gr: "Συνεχώς παρακολουθούμε και βελτιώνουμε την απόδοσή μας μέσω της χρήσης μετρήσεων που βασίζονται στα δεδομένα και της τακτικής αξιολόγησης του Συστήματος Διαχείρισης της Ποιότητας μας (QMS).",
    ru: "Мы постоянно контролируем и улучшаем нашу производительность с помощью метрик, основанных на данных, и регулярных проверок нашей системы управления качеством (QMS).",
    rs: "Neprekidno pratimo i poboljšavamo našu izvedbu korišćenjem metrika zasnovanih na podacima i redovnim pregledima našeg Sistema upravljanja kvalitetom (QMS)."

  },
  QualityP4: {
    en: "We foster a culture of excellence and continuous improvement by empowering our employees to take ownership of their work and contribute to the success of the company.",
    al: "Ne kultivojmë një kulturë shkëlqimi dhe përmirësim të vazhdueshëm duke fuqizuar punonjësit tanë të marrin përgjegjësinë për punën e tyre dhe të kontribuojnë në suksesin e kompanisë.",
    de: "Wir fördern eine Kultur der Exzellenz und kontinuierlichen Verbesserung, indem wir unsere Mitarbeiter befähigen, die Verantwortung für ihre Arbeit zu übernehmen und zum Erfolg des Unternehmens beizutragen.",
    tr: "Çalışanlarımıza işlerinin sahipliğini almaları ve şirketin başarısına katkıda bulunmaları için bir mükemmellik ve sürekli gelişim kültürü geliştiriyoruz.",
    fr: "Nous favorisons une culture d'excellence et d'amélioration continue en permettant à nos employés de prendre en charge leur travail et de contribuer au succès de l'entreprise.",
    it: "Promuoviamo una cultura dell'eccellenza e del miglioramento continuo, dando potere ai nostri dipendenti di prendere in mano il proprio lavoro e contribuire al successo dell'azienda.",
    es: "Fomentamos una cultura de excelencia y mejora continua al empoderar a nuestros empleados para que se hagan cargo de su trabajo y contribuyan al éxito de la empresa.",
    pt: "Fomentamos uma cultura de excelência e melhoria contínua, capacitando nossos funcionários a assumir a responsabilidade por seu trabalho e contribuir para o sucesso da empresa.",
    gr: "Προωθούμε μια κουλτούρα αριστείας και συνεχούς βελτίωσης ενισχύοντας τους εργαζομένους μας να αναλάβουν την ευθύνη για τη δουλειά τους και να συνεισφέρουν στην επιτυχία της εταιρείας.",
    ru: "Мы содействуем культуре превосходства и непрерывного совершенствования, давая нашим сотрудникам возможность брать на себя ответственность за свою работу и вносить вклад в успех компании.",
    rs: "Podstičemo kulturu izvrsnosti i kontinuiranog unapređenja omogućavajući našim zaposlenima da preuzmu odgovornost za svoj rad i doprinesu uspehu kompanije."

  },
  QualityP5: {
    en: "We provide our employees with the resources, training, and support they need to excel in their roles and deliver high-quality products and services.",
    al: "Ne ofrojmë punonjësve tanë burimet, trajnimet, dhe mbështetjen që kanë nevojë për të shkëlqyer në rolet e tyre dhe për të ofruar produkte dhe shërbime të cilësisë së lartë.",
    de: "Wir bieten unseren Mitarbeitern die Ressourcen, Schulungen und Unterstützung, die sie benötigen, um in ihren Rollen zu glänzen und hochwertige Produkte und Dienstleistungen zu liefern.",
    tr: "Çalışanlarımıza rollerinde başarılı olmaları ve yüksek kaliteli ürünler ve hizmetler sunmaları için ihtiyaçları olan kaynakları, eğitimleri ve desteği sağlıyoruz.",
    fr: "Nous fournissons à nos employés les ressources, la formation et le soutien dont ils ont besoin pour exceller dans leurs rôles et fournir des produits et services de haute qualité.",
    it: "Forniamo ai nostri dipendenti le risorse, la formazione e il supporto di cui hanno bisogno per eccellere nei loro ruoli e fornire prodotti e servizi di alta qualità.",
    es: "Proporcionamos a nuestros empleados los recursos, la formación y el apoyo que necesitan para destacarse en sus funciones y ofrecer productos y servicios de alta calidad.",
    pt: "Fornecemos aos nossos funcionários os recursos, treinamento e suporte necessários para se destacarem em seus papéis e entregarem produtos e serviços de alta qualidade.",
    gr: "Παρέχουμε στους εργαζομένους μας τους πόρους, την εκπαίδευση και την υποστήριξη που χρειάζονται για να ξεχωρίσουν στους ρόλους τους και να παρέχουν προϊόντα και υπηρεσίες υψηλής ποιότητας.",
    ru: "Мы предоставляем нашим сотрудникам ресурсы, обучение и поддержку, необходимые для превосходства в своих ролях и предоставления продукции и услуг высокого качества.",
    rs: "Prilagođavamo našim zaposlenima resurse, obuke i podršku koje im trebaju kako bi se istakli u svojim ulogama i pružili visokokvalitetne proizvode i usluge."

  },
  QualityP6: {
    en: "To achieve these goals, we have developed a comprehensive set of quality objectives that guide our day-to-day operations. These objectives are designed to ensure that we are always striving for excellence in everything we do, and that we are continuously improving our processes and systems to meet the changing needs of our customers.",
    al: "Për të arritur këto qëllime, kemi zhvilluar një set të gjerë objektivash cilësie që udhëhoqin operacionet tona ditore. Këto objektiva janë projektuar për të siguruar që gjithmonë po përpiqemi për shkëlqimin në çdo gjë që bëjmë, dhe që po përmirësojmë vazhdimisht proceset dhe sistemet tona për të përmbushur nevojat e ndryshueshme të klientëve tanë.",
    de: "Um diese Ziele zu erreichen, haben wir eine umfassende Reihe von Qualitätszielen entwickelt, die unsere täglichen Operationen leiten. Diese Ziele sind darauf ausgelegt, sicherzustellen, dass wir in allem, was wir tun, ständig nach Exzellenz streben, und dass wir unsere Prozesse und Systeme kontinuierlich verbessern, um den sich ändernden Bedürfnissen unserer Kunden gerecht zu werden.",
    tr: "Bu hedeflere ulaşmak için günlük operasyonlarımızı yönlendiren kapsamlı bir kalite hedefleri seti geliştirdik. Bu hedefler, yaptığımız her şeyde mükemmeliyeti her zaman hedeflediğimizden ve müşterilerimizin değişen ihtiyaçlarını karşılamak için sürekli olarak süreçlerimizi ve sistemlerimizi iyileştirdiğimizden emin olmak için tasarlanmıştır.",
    fr: "Pour atteindre ces objectifs, nous avons développé un ensemble complet d'objectifs qualité qui guident nos opérations quotidiennes. Ces objectifs sont conçus pour garantir que nous aspirons toujours à l'excellence dans tout ce que nous faisons, et que nous améliorons continuellement nos processus et systèmes pour répondre aux besoins changeants de nos clients.",
    it: "Per raggiungere questi obiettivi, abbiamo sviluppato un insieme completo di obiettivi di qualità che guidano le nostre operazioni quotidiane. Questi obiettivi sono progettati per garantire che cerchiamo sempre l'eccellenza in tutto ciò che facciamo e che miglioriamo continuamente i nostri processi e sistemi per soddisfare le mutevoli esigenze dei nostri clienti.",
    es: "Para lograr estos objetivos, hemos desarrollado un conjunto integral de objetivos de calidad que guían nuestras operaciones diarias. Estos objetivos están diseñados para garantizar que siempre estamos buscando la excelencia en todo lo que hacemos, y que estamos mejorando continuamente nuestros procesos y sistemas para satisfacer las cambiantes necesidades de nuestros clientes.",
    pt: "Para alcançar esses objetivos, desenvolvemos um conjunto abrangente de objetivos de qualidade que orientam nossas operações diárias. Esses objetivos são projetados para garantir que estamos sempre buscando a excelência em tudo o que fazemos e que estamos melhorando continuamente nossos processos e sistemas para atender às necessidades em constante mudança de nossos clientes.",
    gr: "Για να επιτύχουμε αυτούς τους στόχους, έχουμε αναπτύξει έναν περιεκτικό σύνολο ποιοτικών στόχων που καθοδηγούν τις καθημερινές μας εργασίες. Αυτοί οι στόχοι σχεδιάστηκαν για να διασφαλίσουν ότι πάντα προσπαθούμε για την αριστεία σε ό,τι κάνουμε και ότι συνεχώς βελτιώνουμε τις διαδικασίες και τα συστήματά μας για να ανταποκρινόμαστε στις μεταβαλλόμενες ανάγκες των πελατών μας.",
    ru: "Для достижения этих целей мы разработали комплексный набор целей качества, которые направляют нашу повседневную деятельность. Эти цели призваны обеспечить постоянное стремление к совершенству во всем, что мы делаем, и постоянно улучшать наши процессы и системы для удовлетворения меняющихся потребностей наших клиентов.",
    rs: "Da bismo postigli ove ciljeve, razvili smo sveobuhvatan skup ciljeva kvaliteta koji usmeravaju naše svakodnevne operacije. Ovi ciljevi su osmišljeni kako bi osigurali da uvek težimo izvrsnosti u svemu što radimo i da neprekidno unapređujemo naše procese i sisteme kako bismo zadovoljili promenljive potrebe naših klijenata."
  },
  QualityP7: {
    en: "One of our key quality objectives is to maintain a high level of customer satisfaction. This involves regularly soliciting feedback from our customers and using this feedback to make improvements to our products and services. We also aim to provide exceptional customer service at every point of contact, from the first phone call or email to the final delivery of our products or completion of our services.",
    al: "Një nga objektivat kryesore të cilësisë për ne është të ruajmë një nivel të lartë të kënaqësisë së klientëve. Kjo përfshin marrjen e rregullt të komenteve nga klientët tanë dhe përdorimin e tyre për të bërë përmirësime në produktet dhe shërbimet tona. Ne gjithashtu synojmë të ofrojmë shërbim të jashtëzakonshëm për klientët në çdo pikë kontakti, nga thirrja e parë ose email-i deri te dorëzimi i fundit i produkteve tona ose përfundimi i shërbimeve tona.",

    de: "Eines unserer wichtigsten Qualitätsziele ist es, ein hohes Maß an Kundenzufriedenheit aufrechtzuerhalten. Dies beinhaltet regelmäßige Einholung von Feedback von unseren Kunden und die Verwendung dieses Feedbacks, um Verbesserungen an unseren Produkten und Dienstleistungen vorzunehmen. Außerdem streben wir danach, bei jedem Kontaktpunkt außergewöhnlichen Kundenservice zu bieten, angefangen bei dem ersten Telefonanruf oder E-Mail bis zur endgültigen Lieferung unserer Produkte oder Abschluss unserer Dienstleistungen.",
    tr: "Ana kalite hedeflerimizden biri yüksek müşteri memnuniyet seviyesini korumaktır. Bunun için düzenli olarak müşterilerimizden geri bildirim alıyor ve bu geri bildirimi ürünlerimizde ve hizmetlerimizde iyileştirmeler yapmak için kullanıyoruz. Ayrıca, ilk telefon görüşmesinden veya e-postadan son ürün teslimatına veya hizmetlerimizin tamamlanmasına kadar her temas noktasında olağanüstü müşteri hizmeti sunmayı hedefliyoruz.",
    fr: "L'un de nos principaux objectifs qualité est de maintenir un niveau élevé de satisfaction client. Cela implique de solliciter régulièrement des retours de nos clients et d'utiliser ces retours pour apporter des améliorations à nos produits et services. Nous visons également à fournir un service client exceptionnel à chaque point de contact, du premier appel téléphonique ou e-mail à la livraison finale de nos produits ou à l'achèvement de nos services.",
    it: "Uno dei nostri principali obiettivi di qualità è mantenere un elevato livello di soddisfazione del cliente. Questo comporta il raccogliere regolarmente feedback dai nostri clienti e utilizzare questi feedback per apportare miglioramenti ai nostri prodotti e servizi. Miriamo inoltre a fornire un servizio clienti eccezionale in ogni punto di contatto, dal primo telefono o email alla consegna finale dei nostri prodotti o al completamento dei nostri servizi.",
    es: "Uno de nuestros principales objetivos de calidad es mantener un alto nivel de satisfacción del cliente. Esto implica solicitar regularmente comentarios de nuestros clientes y utilizar estos comentarios para realizar mejoras en nuestros productos y servicios. También buscamos proporcionar un servicio al cliente excepcional en cada punto de contacto, desde la primera llamada telefónica o correo electrónico hasta la entrega final de nuestros productos o la finalización de nuestros servicios.",
    pt: "Um dos nossos principais objetivos de qualidade é manter um alto nível de satisfação do cliente. Isso envolve solicitar regularmente feedback dos nossos clientes e usar esse feedback para fazer melhorias nos nossos produtos e serviços. Também visamos fornecer um serviço ao cliente excepcional em todos os pontos de contato, desde a primeira ligação telefônica ou e-mail até a entrega final dos nossos produtos ou a conclusão dos nossos serviços.",

    gr: "Ένας από τους κύριους στόχους μας ποιότητας είναι να διατηρούμε ένα υψηλό επίπεδο ικανοποίησης του πελάτη. Αυτό περιλαμβάνει την τακτική ανάκτηση σχολίων από τους πελάτες μας και τη χρήση αυτών των σχολίων για να προβούμε σε βελτιώσεις στα προϊόντα και τις υπηρεσίες μας. Στοχεύουμε επίσης στο να παρέχουμε εξαιρετική εξυπηρέτηση πελατών σε κάθε σημείο επαφής, από το πρώτο τηλεφώνημα ή email μέχρι την τελική παράδοση των προϊόντων",
    ru: "Один из наших ключевых целей качества - поддерживать высокий уровень удовлетворенности клиентов. Это включает регулярный сбор обратной связи от наших клиентов и использование этой информации для улучшения наших продуктов и услуг. Мы также стремимся обеспечить исключительное обслуживание клиентов на каждом этапе контакта, от первого телефонного звонка или электронной почты до финальной доставки наших продуктов",
    rs: "Jedan od naših ključnih ciljeva kvaliteta je održavanje visokog nivoa zadovoljstva kupaca. To uključuje redovno prikupljanje povratnih informacija od naših kupaca i korišćenje tih informacija za poboljšanje naših proizvoda i usluga. Takođe ciljamo da pružimo izuzetnu uslugu kupcima na svakom kontaktu, od prvog telefonskog poziva ili e-pošte do konačne dostave naših proizvoda"

  },
  QualityP8: {
    en: "Another important quality objective for our company is to ensure the safety and reliability of our products and services. This includes conducting rigorous testing and inspections of all of our products before they leave our facility, as well as providing ongoing maintenance and repair services to ensure that our customers' vehicles remain safe and reliable over time.",
    al: "Një objektiv cilësor tjetër i rëndësishëm për kompaninë tonë është të sigurojmë sigurinë dhe besueshmërinë e produkteve dhe shërbimeve tona. Kjo përfshin realizimin e testimeve dhe inspektimeve të rrepta të të gjitha produkteve tona para se të largohen nga objekti ynë, si dhe ofrimin e shërbimeve të vazhdueshme të mirëmbajtjes dhe riparimit për të siguruar që makinat e klientëve tanë të mbeten të sigurta dhe të besueshme në kohë.",
    de: "Ein weiteres wichtiges Qualitätsziel für unser Unternehmen ist die Sicherheit und Zuverlässigkeit unserer Produkte und Dienstleistungen. Dazu gehört die Durchführung rigoroser Tests und Inspektionen aller unserer Produkte, bevor sie unsere Einrichtung verlassen, sowie die Bereitstellung kontinuierlicher Wartungs- und Reparaturdienste, um sicherzustellen, dass die Fahrzeuge unserer Kunden im Laufe der Zeit sicher und zuverlässig bleiben.",
    tr: "Şirketimiz için bir diğer önemli kalite hedefi, ürünlerimizin ve hizmetlerimizin güvenliğini ve güvenilirliğini sağlamaktır. Bu, tesisimizden ayrılmadan önce tüm ürünlerimizin titiz testler ve kontrollerden geçirilmesini içerir, ayrıca müşterilerimizin araçlarının zamanla güvenli ve güvenilir kalmasını sağlamak için sürekli bakım ve onarım hizmetleri sunulur.",
    fr: "Un autre objectif qualité important pour notre entreprise est d'assurer la sécurité et la fiabilité de nos produits et services. Cela comprend la réalisation de tests rigoureux et d'inspections de tous nos produits avant leur départ de nos installations, ainsi que la fourniture de services de maintenance et de réparation continus pour garantir que les véhicules de nos clients restent sûrs et fiables au fil du temps.",
    it: "Un altro importante obiettivo di qualità per la nostra azienda è garantire la sicurezza e l'affidabilità dei nostri prodotti e servizi. Ciò include la conduzione di rigorosi test e ispezioni di tutti i nostri prodotti prima che lascino il nostro stabilimento, oltre alla fornitura di servizi di manutenzione e riparazione continuativi per garantire che i veicoli dei nostri clienti rimangano sicuri e affidabili nel tempo.",
    es: "Otro objetivo de calidad importante para nuestra empresa es garantizar la seguridad y fiabilidad de nuestros productos y servicios. Esto incluye realizar pruebas rigurosas e inspecciones de todos nuestros productos antes de que salgan de nuestras instalaciones, así como proporcionar servicios de mantenimiento y reparación continuos para asegurar que los vehículos de nuestros clientes permanezcan seguros y fiables con el tiempo.",
    pt: "Outro objetivo de qualidade importante para nossa empresa é garantir a segurança e confiabilidade de nossos produtos e serviços. Isso inclui a realização de testes rigorosos e inspeções de todos os nossos produtos antes de deixarem nossas instalações, bem como a prestação de serviços de manutenção e reparo contínuos para garantir que os veículos de nossos clientes permaneçam seguros e confiáveis ao longo do tempo.",
    gr: "Ένας άλλος σημαντικός στόχος ποιότητας για την εταιρεία μας είναι να διασφαλίσουμε την ασφάλεια και την αξιοπιστία των προϊόντων και των υπηρεσιών μας. Αυτό περιλαμβάνει τη διενέργεια αυστηρών ελέγχων και επιθεωρήσεων όλων των προϊόντων μας πριν αποχωρήσουν από τις εγκαταστάσεις μας, καθώς και την παροχή συνεχούς συντήρησης και επισκευής για να εξασφαλίσουμε ότι τα οχήματα των πελατών μας παραμένουν ασφαλή και αξιόπιστα με την πάροδο του χρόνου.",
    ru: "Еще одна важная цель качества для нашей компании - обеспечить безопасность и надежность наших продуктов и услуг. Это включает проведение строгих испытаний и проверок всех наших продуктов перед их отправкой из наших объектов, а также предоставление непрерывного технического обслуживания и ремонтных работ для обеспечения того, чтобы автомобили наших клиентов оставались безопасными и надежными со временем.",
    rs: "Jo jedan važan cilj kvaliteta za našu kompaniju je osigurati sigurnost i pouzdanost naših proizvoda i usluga. To uključuje sprovođenje rigoroznih testova i inspekcija svih naših proizvoda pre nego što napuste naše pogone, kao i pružanje stalnog održavanja i popravki kako bismo osigurali da vozila naših kupaca ostanu sigurna i pouzdana tokom vremena."

  },
  QualityP9: {
    en: "To support these quality objectives, we have established a number of key performance indicators (KPIs) that we use to measure our success and identify areas where we can make improvements. These KPIs include metrics related to customer satisfaction, product quality, employee performance, and overall business performance.",
    al: "Për të mbështetur këto objektiva cilësie, kemi vendosur një numër të ndikuesve kryesorë të performancës (KPI) që i përdorim për të matur suksesin tonë dhe për të identifikuar fushat ku mund të bëjmë përmirësime. Këto KPI përfshijnë metrika lidhur me kënaqësinë e klientit, cilësinë e produktit, performancën e punonjësve dhe performancën e përgjithshme të biznesit.",
    de: "Um diese Qualitätsziele zu unterstützen, haben wir eine Reihe von wichtigen Leistungsindikatoren (KPIs) festgelegt, die wir zur Messung unseres Erfolgs und zur Identifizierung von Bereichen, in denen wir Verbesserungen vornehmen können, verwenden. Diese KPIs umfassen Metriken, die sich auf die Kundenzufriedenheit, Produktqualität, Mitarbeiterleistung und allgemeine Geschäftsleistung beziehen.",
    tr: "Bu kalite hedeflerini desteklemek için, başarılarımızı ölçmek ve iyileştirmeler yapabileceğimiz alanları belirlemek için kullandığımız bir dizi önemli performans göstergesi (KPI) belirledik. Bu KPI'lar, müşteri memnuniyeti, ürün kalitesi, çalışan performansı ve genel iş performansı ile ilgili ölçümleri içerir.",
    fr: "Pour soutenir ces objectifs qualité, nous avons établi un certain nombre d'indicateurs clés de performance (KPI) que nous utilisons pour mesurer notre succès et identifier les domaines dans lesquels nous pouvons apporter des améliorations. Ces KPI comprennent des métriques liées à la satisfaction client, la qualité des produits, la performance des employés et la performance globale de l'entreprise.",
    it: "Per sostenere questi obiettivi di qualità, abbiamo stabilito una serie di indicatori chiave di prestazione (KPI) che utilizziamo per misurare il nostro successo e identificare aree in cui possiamo apportare miglioramenti. Questi KPI includono metriche legate alla soddisfazione del cliente, alla qualità del prodotto, alle prestazioni dei dipendenti e alle prestazioni complessive dell'azienda.",
    es: "Para apoyar estos objetivos de calidad, hemos establecido una serie de indicadores clave de rendimiento (KPI) que utilizamos para medir nuestro éxito e identificar áreas en las que podemos realizar mejoras. Estos KPI incluyen métricas relacionadas con la satisfacción del cliente, la calidad del producto, el rendimiento de los empleados y el rendimiento general del negocio.",
    pt: "Para apoiar esses objetivos de qualidade, estabelecemos uma série de indicadores-chave de desempenho (KPIs) que usamos para medir nosso sucesso e identificar áreas onde podemos fazer melhorias. Esses KPIs incluem métricas relacionadas à satisfação do cliente, qualidade do produto, desempenho dos funcionários e desempenho geral do negócio.",
    gr: "Για να υποστηρίξουμε αυτούς τους στόχους ποιότητας, έχουμε καθιερώσει μια σειρά από βασικούς δείκτες απόδοσης (KPIs) που χρησιμοποιούμε για να μετρήσουμε την επιτυχία μας και να αναγνωρίσουμε περιοχές όπου μπορούμε να κάνουμε βελτιώσεις. Αυτοί οι KPI περιλαμβάνουν μετρήσεις που σχετίζονται με την ικανοποίηση του πελάτη, την ποιότητα του προϊόντος, την απόδοση των υπαλλήλων και τη συνολική απόδοση της επιχείρησης.",
    ru: "Для поддержки этих целей качества мы установили ряд ключевых показателей эффективности (KPI), которые мы используем для измерения нашего успеха и выявления областей, где мы можем внести улучшения. Эти KPI включают метрики, связанные с удовлетворенностью клиентов, качеством продукции, производительностью сотрудников и общей деловой производительностью.",
    rs: "Za podršku ovim ciljevima kvaliteta, postavili smo niz ključnih pokazatelja uspeha (KPI) koje koristimo za merenje našeg uspeha i identifikaciju oblasti u kojima možemo napraviti poboljšanja. Ovi KPI-ji uključuju metrike povezane s zadovoljstvom korisnika, kvalitetom proizvoda, performansama zaposlenih i ukupnim poslovnim performansama."

  },
  QualityP10: {
    en: "At the heart of our QMS is our commitment to continuous improvement. We believe that there is always room for improvement, and we are constantly seeking out new ways to enhance our products and services. By staying focused on our quality objectives and continually monitoring our performance, we are able to identify areas for improvement and implement changes that help us achieve our goal of delivering high-quality products and services to our customers.",
    al: "Në qendër të QMS tonë është përkushtimi ynë për përmirësimin e vazhdueshëm. Ne besojmë se gjithmonë ka hapësirë për përmirësime, dhe ne vazhdimisht po kërkojmë mënyra të reja për të përmirësuar produktet dhe shërbimet tona. Duke mbajtur fokus në objektivat tona të cilësisë dhe duke monitoruar vazhdimisht performancën tonë, ne jemi në gjendje të identifikojmë fusha për përmirësim dhe të zbatohemi ndryshime që na ndihmojnë të arrijmë qëllimin tonë për të ofruar produkte dhe shërbime të cilësisë së lartë për klientët tanë.",
    de: "Im Herzen unseres QMS steht unser Engagement für kontinuierliche Verbesserung. Wir glauben, dass es immer Raum für Verbesserungen gibt, und wir suchen ständig nach neuen Wegen, um unsere Produkte und Dienstleistungen zu verbessern. Indem wir uns auf unsere Qualitätsziele konzentrieren und unsere Leistung kontinuierlich überwachen, können wir Bereiche für Verbesserungen identifizieren und Änderungen umsetzen, die uns helfen, unser Ziel zu erreichen, hochwertige Produkte und Dienstleistungen für unsere Kunden zu liefern.",
    tr: "QM sistemimizin kalbinde sürekli iyileştirme taahhüdümüz bulunmaktadır. Her zaman iyileştirme için yer olduğuna inanıyoruz ve ürünlerimizi ve hizmetlerimizi geliştirmenin yeni yollarını sürekli arıyoruz. Kalite hedeflerimize odaklanarak ve performansımızı sürekli izleyerek, iyileştirme alanları belirleyebilir ve müşterilerimize yüksek kaliteli ürünler ve hizmetler sunma hedefimize ulaşmamıza yardımcı olacak değişiklikler yapabiliriz.",
    fr: "Au cœur de notre SMQ se trouve notre engagement envers l'amélioration continue. Nous croyons qu'il y a toujours place à l'amélioration, et nous cherchons constamment de nouvelles façons d'améliorer nos produits et services. En restant concentrés sur nos objectifs de qualité et en surveillant continuellement notre performance, nous sommes en mesure d'identifier des domaines d'amélioration et de mettre en œuvre des changements qui nous aident à atteindre notre objectif de fournir des produits et services de haute qualité à nos clients.",
    it: "Al centro del nostro QMS c'è il nostro impegno per il miglioramento continuo. Crediamo che ci sia sempre spazio per il miglioramento, e cerchiamo costantemente nuovi modi per migliorare i nostri prodotti e servizi. Mantenendoci concentrati sui nostri obiettivi di qualità e monitorando continuamente le nostre performance, siamo in grado di individuare aree di miglioramento e implementare cambiamenti che ci aiutano a raggiungere il nostro obiettivo di offrire prodotti e servizi di alta qualità ai nostri clienti.",
    es: "En el corazón de nuestro SGC se encuentra nuestro compromiso con la mejora continua. Creemos que siempre hay espacio para mejorar, y estamos constantemente buscando nuevas formas de mejorar nuestros productos y servicios. Al mantenernos enfocados en nuestros objetivos de calidad y monitorear continuamente nuestro desempeño, podemos identificar áreas para mejorar e implementar cambios que nos ayuden a alcanzar nuestro objetivo de ofrecer productos y servicios de alta calidad a nuestros clientes.",
    pt: "No centro do nosso SGQ está o nosso compromisso com a melhoria contínua. Acreditamos que sempre há espaço para melhorias e estamos constantemente buscando novas formas de aprimorar nossos produtos e serviços. Ao nos concentrarmos em nossos objetivos de qualidade e monitorarmos continuamente nosso desempenho, conseguimos identificar áreas para melhorias e implementar mudanças que nos ajudem a alcançar nosso objetivo de oferecer produtos e serviços de alta qualidade aos nossos clientes.",
    gr: "Στην καρδιά του ΣΔΠ μας βρίσκεται η δέσμευσή μας για διαρκή βελτίωση. Πιστεύουμε ότι υπάρχει πάντα χώρος για βελτίωση, και αναζητούμε διαρκώς νέους τρόπους για να βελτιώσουμε τα προϊόντα και τις υπηρεσίες μας. Κρατώντας την προσοχή μας στους στόχους ποιότητας μας και παρακολουθώντας διαρκώς την απόδοσή μας, μπορούμε να εντοπίσουμε περιοχές βελτίωσης και να υλοποιήσουμε αλλαγές που μας βοηθούν να επιτύχουμε τον στόχο μας να παρέχουμε υψηλής ποιότητας προϊόντα και υπηρεσίες στους πελάτες μας.",
    ru: "В основе нашей СМК лежит наше обязательство к непрерывному совершенствованию. Мы считаем, что всегда есть место для улучшений, и постоянно ищем новые способы улучшить наши продукты и услуги. Сосредотачиваясь на наших целях качества и непрерывно контролируя нашу производительность, мы можем выявить области для улучшения и внедрить изменения, которые помогут нам достичь нашей цели по предоставлению высококачественных продуктов и услуг нашим клиентам.",
    rs: "У основи нашег СМС-а је наше обавезивање ка континуираном унапређењу. Сматрамо да увек има простора за унапређења и стално тражимо нове начине да унапредимо наше производе и услуге. Фокусирајући се на нашим циљевима квалитета и константно контролишући нашу перформансу, способни смо да идентификујемо области за унапређење и спроведемо промене које ће нам помоћи да постигнемо наш циљ представљања висококвалитетних производа и услуга нашим клијентима."

  },
  QualityP11: {
    en: "In conclusion, our quality policy is centered around our commitment to excellence in everything we do. We strive to exceed customer expectations, comply with all applicable regulations and standards, continually improve our performance, empower our employees, and provide exceptional products and services that are safe, reliable, and meet the needs of our customers. Through these efforts, we are able to build lasting relationships with our customers and position ourselves as a leader in the auto parts and mechanic industry.",
    al: "Në përfundim, politika jonë e cilësisë është e përqendruar rreth përkushtimit tonë për shkëlqimin në çdo gjë që bëjmë. Ne përpiqemi të tejkalojmë pritjet e klientëve, të përmbushim të gjitha rregulloret dhe standardet e aplikueshme, të përmirësojmë vazhdimisht performancën tonë, të fuqizojmë punonjësit tanë, dhe të ofrojmë produkte dhe shërbime të jashtëzakonshme që janë të sigurta, të besueshme, dhe përmbushin nevojat e klientëve tanë. Përmes këtyre përpjekjeve, ne jemi në gjendje të ndërtojmë marrëdhënie të qëndrueshme me klientët tanë dhe të na pozicionojmë si një lider në industrinë e pjesëve automobilistike dhe mekanike.",
    de: "Zusammenfassend basiert unsere Qualitätsrichtlinie auf unserem Engagement für Exzellenz in allem, was wir tun. Wir streben danach, die Erwartungen der Kunden zu übertreffen, alle geltenden Vorschriften und Standards einzuhalten, unsere Leistung kontinuierlich zu verbessern, unsere Mitarbeiter zu stärken und außergewöhnliche Produkte und Dienstleistungen anzubieten, die sicher, zuverlässig sind und den Bedürfnissen unserer Kunden entsprechen. Durch diese Bemühungen sind wir in der Lage, dauerhafte Beziehungen zu unseren Kunden aufzubauen und uns als führendes Unternehmen in der Auto- und Mechanikerbranche zu positionieren.",
    tr: "Sonuç olarak, kalite politikamız, yaptığımız her şeyde mükemmelliğe olan taahhütümüze dayanmaktadır. Müşteri beklentilerini aşmayı, tüm ilgili düzenlemelere ve standartlara uymayı, performansımızı sürekli olarak iyileştirmeyi, çalışanlarımızı güçlendirmeyi ve müşterilerimizin ihtiyaçlarını karşılayan güvenli, güvenilir ve olağanüstü ürünler ve hizmetler sunmayı hedefliyoruz. Bu çabalarımızla, müşterilerimizle kalıcı ilişkiler kuruyor ve otomobil ve mekanik sektöründe lider bir konuma geliyoruz.",
    fr: "En conclusion, notre politique qualité est centrée sur notre engagement envers l'excellence dans tout ce que nous faisons. Nous nous efforçons de dépasser les attentes des clients, de respecter toutes les réglementations et normes applicables, d'améliorer continuellement notre performance, de renforcer nos employés et de fournir des produits et services exceptionnels qui sont sûrs, fiables et répondent aux besoins de nos clients. Grâce à ces efforts, nous sommes en mesure de construire des relations durables avec nos clients et de nous positionner comme un leader dans l'industrie des pièces automobiles et mécaniques.",
    it: "In conclusione, la nostra politica della qualità si basa sul nostro impegno per l'eccellenza in tutto ciò che facciamo. Cerchiamo di superare le aspettative dei clienti, di rispettare tutte le normative e gli standard applicabili, di migliorare continuamente le nostre prestazioni, di potenziare i nostri dipendenti e di fornire prodotti e servizi eccezionali che siano sicuri, affidabili e che soddisfino le esigenze dei nostri clienti. Attraverso questi sforzi, siamo in grado di costruire relazioni durature con i nostri clienti e di posizionarci come leader nell'industria dei ricambi auto e meccanica.",
    es: "En conclusión, nuestra política de calidad se centra en nuestro compromiso con la excelencia en todo lo que hacemos. Nos esforzamos por superar las expectativas de los clientes, cumplir con todas las regulaciones y normas aplicables, mejorar continuamente nuestro rendimiento, empoderar a nuestros empleados y ofrecer productos y servicios excepcionales que sean seguros, confiables y satisfagan las necesidades de nuestros clientes. A través de estos esfuerzos, podemos construir relaciones duraderas con nuestros clientes y posicionarnos como líderes en la industria de repuestos para automóviles y mecánica.",
    pt: "Em conclusão, nossa política de qualidade é centrada em nosso compromisso com a excelência em tudo o que fazemos. Nos esforçamos para superar as expectativas dos clientes, cumprir com todas as regulamentações e normas aplicáveis, melhorar continuamente nosso desempenho, capacitar nossos funcionários e fornecer produtos e serviços excepcionais que sejam seguros, confiáveis e atendam às necessidades de nossos clientes. Através desses esforços, somos capazes de construir relacionamentos duradouros com nossos clientes e nos posicionar como líderes na indústria de peças automotivas e mecânicas.",
    gr: "Συνοψίζοντας, η πολιτική ποιότητάς μας επικεντρώνεται στη δέσμευσή μας για την αριστεία σε κάθε πράγμα που κάνουμε. Προσπαθούμε να υπερβούμε τις προσδοκίες των πελατών, να συμμορφωθούμε με όλους τους ισχύοντες κανονισμούς και πρότυπα, να βελτιώσουμε συνεχώς την απόδοσή μας, να ενισχύουμε τους υπαλλήλους μας και να παρέχουμε εξαιρετικά προϊόντα και υπηρεσίες που είναι ασφαλή, αξιόπιστα και καλύπτουν τις ανάγκες των πελατών μας. Μέσω αυτών των προσπαθειών, είμαστε σε θέση να δημιουργήσουμε διαρκείς σχέσεις με τους πελάτες μας και να μας θέσουμε ως ηγέτες στη βιομηχανία ανταλλακτικών αυτοκινήτων και μηχανικής.",
    ru: "В заключение, наша политика качества сосредоточена на нашем стремлении к превосходству во всем, что мы делаем. Мы стремимся превзойти ожидания клиентов, соблюдать все применимые нормативные требования и стандарты, непрерывно улучшать нашу производительность, укреплять наших сотрудников и предоставлять исключительные продукты и услуги, которые безопасны, надежны и удовлетворяют потребности наших клиентов. Благодаря этим усилиям мы способны создавать долгосрочные отношения с нашими клиентами и позиционировать себя как лидеров в индустрии автозапчастей и механики.",
    rs: "Закључно, наша политика квалитета је усмерена на наше посвећење изврсности у свему што радимо. Те желимо да превазиђемо очекивања клијената, да поштујемо све применљиве регулативе и стандарде, да непрестано унапређујемо нашу перформансу, да омогућимо нашим запосленима и да обезбедимо изузетне производе и услуге који су безбедни, поуздани и задовољавају потребе наших клијената. Путем ових напора, можемо изградити трајне односе са нашим клијентима и позиционирати се као лидери у индустрији аутоделова и механике."
  },
  FooterDesc: {
    en: "TAFA-S L.L.C is the leading company in its sector with the wholesale and retail sale and distribution of spare parts for passenger cars and commercial vehicles since 1969.",
    al: "TAFA-S L.L.C është kompania lider në sektorin e saj me shitjen dhe shpërndarjen me shumicë dhe pakic të pjesëve të këmbimit për makinat e pasagjerëve dhe automjetet komerciale që nga viti 1969.",
    de: "TAFA-S L.L.C ist das führende Unternehmen in seinem Sektor mit dem Groß- und Einzelhandel sowie der Distribution von Ersatzteilen für Personenkraftwagen und Nutzfahrzeuge seit 1969.",
    tr: "TAFA-S L.L.C, 1969'dan beri yolcu araçları ve ticari araçlar için yedek parça toptan ve perakende satış ve dağıtımında sektöründe öncü bir şirkettir.",
    fr: "TAFA-S L.L.C est la société leader dans son secteur avec la vente en gros et au détail ainsi que la distribution de pièces de rechange pour voitures de tourisme et véhicules utilitaires depuis 1969.",
    it: "TAFA-S L.L.C è l'azienda leader nel suo settore con la vendita all'ingrosso e al dettaglio e la distribuzione di ricambi per autovetture e veicoli commerciali dal 1969.",
    es: "TAFA-S L.L.C es la empresa líder en su sector en la venta al por mayor y al por menor y distribución de repuestos para automóviles de pasajeros y vehículos comerciales desde 1969.",
    pt: "A TAFA-S L.L.C é a empresa líder no seu setor com a venda por atacado e varejo e distribuição de peças de reposição para carros de passageiros e veículos comerciais desde 1969.",
    gr: "Η TAFA-S L.L.C είναι η κορυφαία εταιρεία στον τομέα της με την χονδρική και λιανική πώληση και διανομή ανταλλακτικών για επιβατικά αυτοκίνητα και εμπορικά οχήματα από το 1969.",
    ru: "TAFA-S L.L.C является ведущей компанией в своем секторе с оптовой и розничной продажей и распространением запасных частей для легковых автомобилей и коммерческих транспортных средств с 1969 года.",
    rs: "TAFA-S L.L.C је вођа у свом сектору у великопродаји и ретајл продажби и дистрибуцији резервних делова за путничка возила и комерцијална возила од 1969. године.",
  },
  CompanyProjectTitle: {
    en: "Project",
    al: "Projekti",
    de: "Projekt",
    tr: "Proje",
    fr: "Projet",
    it: "Progetto",
    es: "Proyecto",
    pt: "Projeto",
    gr: "Έργο",
    ru: "Проект",
    rs: "Projekat"
  },
  CompanyProjectDesc: {
    en: "Our project is projected to conclude in 2025 and is a joint commitment to achieving sustainable and innovative success in our field. With an advanced and customer-focused approach, our project aims to provide solutions that will positively transform the experience of our clients. On our website, you will find complete details about the project's progress, the products and services we offer, as well as opportunities for collaboration and participation. We are dedicated to ensuring that our project is a leading force in our field and brings about positive changes in our community and beyond.",
    al: "Projekti ynë parashikohet të përfundojë në vitin 2025 dhe është një angazhim i përbashkët për të arritur një sukses të qëndrueshëm dhe inovativ në fushën tonë. Me një qasje të përparuar dhe të përqendruar në nevojat e klientëve tanë, projekti ynë synon të ofrojë zgjidhje të cilat do të ndryshojnë për të mirë përvojën e klienteve tanë. Në webfaqen tonë, do të gjeni detaje të plotë rreth progresit të projektit, produktet dhe shërbimet që ofrojmë, si dhe mundësitë për bashkëpunim dhe pjesëmarrje. Jemi të përkushtuar për të siguruar që projekti ynë të jetë një rrugë udhëheqëse në fushën tonë dhe të sjellë ndryshime pozitive në komunitetin tonë dhe përtej tij.",
    de: "Unser Projekt soll im Jahr 2025 abgeschlossen werden und ist ein gemeinsames Engagement, um nachhaltigen und innovativen Erfolg in unserem Bereich zu erzielen. Mit einem fortschrittlichen und kundenorientierten Ansatz zielt unser Projekt darauf ab, Lösungen anzubieten, die die Erfahrung unserer Kunden positiv verändern werden. Auf unserer Website finden Sie vollständige Details zum Fortschritt des Projekts, den von uns angebotenen Produkten und Dienstleistungen sowie Möglichkeiten zur Zusammenarbeit und Beteiligung. Wir sind darauf spezialisiert, sicherzustellen, dass unser Projekt eine führende Kraft in unserem Bereich ist und positive Veränderungen in unserer Gemeinschaft und darüber hinaus bewirkt.",
    tr: "Projemizin 2025 yılında tamamlanması planlanmaktadır ve sektörümüzde sürdürülebilir ve yenilikçi başarıya ulaşma konusunda ortak bir taahhüttür. Gelişmiş ve müşteri odaklı bir yaklaşımla, projemiz müşterilerimizin deneyimini olumlu şekilde dönüştürecek çözümler sunmayı amaçlamaktadır. Web sitemizde, projenin ilerlemesi, sunduğumuz ürünler ve hizmetler ile işbirliği ve katılım fırsatları hakkında tam detayları bulacaksınız. Projemizin sektörümüzde öncü bir güç olduğundan ve toplumumuzda ve ötesinde olumlu değişikliklere neden olduğundan emin olmaya adanmışız.",
    fr: "Notre projet est prévu pour se conclure en 2025 et constitue un engagement commun pour atteindre un succès durable et innovant dans notre domaine. Avec une approche avancée et axée sur le client, notre projet vise à fournir des solutions qui transformeront positivement l'expérience de nos clients. Sur notre site Web, vous trouverez tous les détails sur l'avancement du projet, les produits et services que nous proposons, ainsi que des opportunités de collaboration et de participation. Nous nous engageons à faire en sorte que notre projet soit une force de premier plan dans notre domaine et entraîne des changements positifs dans notre communauté et au-delà.",
    it: "Il nostro progetto è previsto per concludersi nel 2025 ed è un impegno condiviso per raggiungere un successo sostenibile e innovativo nel nostro settore. Con un approccio avanzato e orientato al cliente, il nostro progetto mira a fornire soluzioni che trasformeranno positivamente l'esperienza dei nostri clienti. Sul nostro sito web troverai tutti i dettagli sul progresso del progetto, sui prodotti e servizi che offriamo, nonché opportunità di collaborazione e partecipazione. Siamo impegnati a garantire che il nostro progetto sia una forza trainante nel nostro settore e porti a cambiamenti positivi nella nostra comunità e oltre.",
    es: "Nuestro proyecto está previsto para concluir en 2025 y es un compromiso conjunto para lograr un éxito sostenible e innovador en nuestro campo. Con un enfoque avanzado y centrado en el cliente, nuestro proyecto tiene como objetivo proporcionar soluciones que transformen positivamente la experiencia de nuestros clientes. En nuestro sitio web, encontrará todos los detalles sobre el progreso del proyecto, los productos y servicios que ofrecemos, así como oportunidades de colaboración y participación. Estamos dedicados a garantizar que nuestro proyecto sea una fuerza líder en nuestro campo y genere cambios positivos en nuestra comunidad y más allá.",
    pt: "Nosso projeto está previsto para ser concluído em 2025 e é um compromisso conjunto para alcançar um sucesso sustentável e inovador em nosso campo. Com uma abordagem avançada e focada no cliente, nosso projeto visa fornecer soluções que transformarão positivamente a experiência de nossos clientes. Em nosso site, você encontrará todos os detalhes sobre o progresso do projeto, os produtos e serviços que oferecemos, bem como oportunidades de colaboração e participação. Estamos dedicados a garantir que nosso projeto seja uma força líder em nosso campo e traga mudanças positivas em nossa comunidade e além.",
    gr: "Το έργο μας προβλέπεται να ολοκληρωθεί το 2025 και αποτελεί μια κοινή δέσμευση για την επίτευξη βιώσιμης και καινοτόμου επιτυχίας στον τομέα μας. Με μια προηγμένη και εστιασμένη στον πελάτη προσέγγιση, το έργο μας στοχεύει στο να παρέχει λύσεις που θα μετασχηματίσουν θετικά την εμπειρία των πελατών μας. Στην ιστοσελίδα μας, θα βρείτε πλήρεις λεπτομέρειες σχετικά με την πρόοδο του έργου, τα προϊόντα και τις υπηρεσίες που προσφέρουμε, καθώς και ευκαιρίες για συνεργασία και συμμετοχή. Είμαστε αφοσιωμένοι στο να διασφαλίσουμε ότι το έργο μας είναι μια κορυφαία δύναμη στον τομέα μας και φέρνει θετικές αλλαγές στην κοινότητά μας και πέρα από αυτήν.",
    ru: "Наш проект запланирован на завершение в 2025 году и представляет собой совместное обязательство достижения устойчивого и инновационного успеха в нашей области. С применением передового и ориентированного на клиента подхода наш проект нацелен на предоставление решений, которые положительно изменят опыт наших клиентов. На нашем веб-сайте вы найдете полные сведения о ходе проекта, о продукции и услугах, которые мы предлагаем, а также о возможностях сотрудничества и участия. Мы стремимся обеспечить, чтобы наш проект был ведущей силой в нашей области и привел к положительным изменениям в нашем сообществе и за его пределами.",
    rs: "Naš projekat planiran je da se završi do 2025. godine i predstavlja zajedničku posvećenost postizanju održivog i inovativnog uspeha u našoj oblasti. Sa naprednim i usmerenim pristupom ka kupcima, naš projekat ima za cilj da pruži rešenja koja će pozitivno transformisati iskustvo naših klijenata. Na našem veb-sajtu pronaći ćete sve detalje o napretku projekta, proizvodima i uslugama koje nudimo, kao i prilike za saradnju i učešće. Posvećeni smo tome da se osiguramo da naš projekat bude vodeća snaga u našoj oblasti i da donese pozitivne promene u našoj zajednici i šire."

  },
  imageDesc: {
    en: "(Mustafa Shabani, whose company also carries the name Tafa-S)",
    al: "(Mustafë Shabani qe edhe firma e mban emrin e ti Tafa-S)",
    de: "(Mustafa Shabani, dessen Firma auch den Namen Tafa-S trägt)",
    tr: "(Mustafa Shabani, firması da Tafa-S adını taşıyor)",
    fr: "(Mustafa Shabani, dont l'entreprise porte également le nom de Tafa-S)",
    it: "(Mustafa Shabani, la cui azienda porta anche il nome Tafa-S)",
    es: "(Mustafa Shabani, cuya empresa también lleva el nombre de Tafa-S)",
    pt: "(Mustafa Shabani, cuja empresa também carrega o nome Tafa-S)",
    gr: "(Μουσταφά Σαμπάνι, η εταιρεία του οποίου φέρει επίσης το όνομα Tafa-S)",
    ru: "(Мустафа Шабани, чья компания также носит название Tafa-S)",
    rs: "(Mustafa Šabani, čija kompanija takođe nosi ime Tafa-S)"
  },
  brandExclusive: {
    en: "Our Exclusive Partners",
    al: "Partnerët ekskluziv",
    de: "Unsere Exklusiven Partner",
    tr: "Özel Ortaklarımız",
    fr: "Nos Partenaires Exclusifs",
    it: "I Nostri Partner Esclusivi",
    es: "Nuestros Socios Exclusivos",
    pt: "Nossos Parceiros Exclusivos",
    gr: "Οι Αποκλειστικοί Μας Συνεργάτες",
    ru: "Наши Эксклюзивные Партнеры",
    rs: "Naši ekskluzivni partneri"

  },
  brandOther: {
    en: "Brands",
    al: "Brendet",
    de: "Marken",
    tr: "Markalar",
    fr: "Marques",
    it: "Marchi",
    es: "Marcas",
    pt: "Marcas",
    gr: "Μάρκες",
    ru: "Бренды",
    rs: "Brendovi"
  },
  contactUsFirstName: {
    en: "First Name",
    al: "Emri",
    de: "Vorname",
    tr: "Ad",
    fr: "Prénom",
    it: "Nome",
    es: "Nombre",
    pt: "Nome",
    gr: "Όνομα",
    ru: "Имя",
    rs: "Ime"
  },
  contactUsLastName: {
    en: "Last Name",
    al: "Mbiemri",
    de: "Nachname",
    tr: "Soyadı",
    fr: "Nom de famille",
    it: "Cognome",
    es: "Apellido",
    pt: "Sobrenome",
    gr: "Επίθετο",
    ru: "Фамилия",
    rs: "Prezime"
  },
  contactUsCompany: {
    en: "Company",
    al: "Kompania",
    de: "Firma",
    tr: "Şirket",
    fr: "Société",
    it: "Azienda",
    es: "Empresa",
    pt: "Empresa",
    gr: "Εταιρεία",
    ru: "Компания",
    rs: "Kompanija"
  },
  contactUsEmail: {
    en: "Email",
    al: "Emaili",
    de: "E-Mail",
    tr: "E-posta",
    fr: "E-mail",
    it: "Email",
    es: "Correo electrónico",
    pt: "E-mail",
    gr: "Ηλεκτρονικό ταχυδρομείο",
    ru: "Электронная почта",
    rs: "E-pošta"
  },
  contactUsPhoneNumber: {
    en: "Phone Number",
    al: "Numri Telefonit",
    de: "Telefonnummer",
    tr: "Telefon Numarası",
    fr: "Numéro de téléphone",
    it: "Numero di telefono",
    es: "Número de teléfono",
    pt: "Número de telefone",
    gr: "Αριθμός τηλεφώνου",
    ru: "Номер телефона",
    rs: "Broj telefona"
  },
  contactUsMessage: {
    en: "Message",
    al: "Mesazhi",
    de: "Nachricht",
    tr: "Mesaj",
    fr: "Message",
    it: "Messaggio",
    es: "Mensaje",
    pt: "Mensagem",
    gr: "Μήνυμα",
    ru: "Сообщение",
    rs: "Poruka"
  },
  contactUsSend: {
    en: "Let's talk",
    al: "Dergo",
    de: "Kontaktieren Sie uns",
    tr: "Gönder",
    fr: "Envoyer",
    it: "Invia",
    es: "Enviar",
    pt: "Enviar",
    gr: "Ας συνομιλήσουμε",
    ru: "Поговорим",
    rs: "Razgovarajmo"
  },
  contactUsRequirement: {
    en: "Please fill out at least the First Name, Email, and Message fields.",
    al: "Ju lutemi plotësoni të paktën fushat për Emrin, Emailin dhe Mesazhin.",
    de: "Bitte füllen Sie mindestens die Felder Vorname, E-Mail und Nachricht aus.",
    tr: "Lütfen En Az İsim, E-posta ve Mesaj Alanlarını Doldurun.",
    fr: "Veuillez remplir au moins les champs Prénom, E-mail et Message.",
    it: "Si prega di compilare almeno i campi Nome, Email e Messaggio.",
    es: "Por favor, complete al menos los campos de Nombre, Correo electrónico y Mensaje.",
    pt: "Por favor, preencha pelo menos os campos de Nome, Email e Mensagem.",
    gr: "Παρακαλώ συμπληρώστε τουλάχιστον τα πεδία Όνομα, Email και Μήνυμα.",
    ru: "Пожалуйста, заполните хотя бы поля Имя, Электронная почта и Сообщение.",
    rs: "Molimo popunite barem polja Ime, Email i Poruka.",

  },

  tributeToEneaKoliqi: {
    en: "One Jersey, Many Names: Enea Koliqi from SC Gjilani Honoring us",
    al: "Një Fanelë, Shumë Emra: Nderimi i Enea Koliqit nga SC Gjilani",
    de: "Ein Trikot, Viele Namen: Enea Koliqi von SC Gjilani ehrt uns",
    tr: "Bir Forma, Birçok İsim: SC Gjilani'den Enea Koliqi bize Onur Veriyor",
    fr: "Un Maillot, Plusieurs Noms : Enea Koliqi de SC Gjilani nous Honore",
    it: "Una Maglia, Molti Nomi: Enea Koliqi dalla SC Gjilani ci Onora",
    es: "Una Camiseta, Muchos Nombres: Enea Koliqi del SC Gjilani nos Honra",
    pt: "Uma Camisa, Muitos Nomes: Enea Koliqi do SC Gjilani nos Honrando",
    gr: "Ένα Μπλουζάκι, Πολλά Ονόματα: Ο Ένεα Κολίκι από την SC Gjilani μας τιμά",
    ru: "Один Джерси, Много Имен: Энеа Колики из SC Gjilani Почетает нас",
    rs: "Jedan Dres, Mnogo Imena: Enea Koliqi iz SC Gjilani nas Poštuje",
  },
  signedJersey: {
    en: "The former SC Gjilani goalkeeper, Enea Koliqi, honors the staff with a jersey signed by all team members for Tafa-S, for the services they have performed on his vehicle.",
    al: "Ish-portieri i SC Gjilanit, Enea Koliqi, nderon stafin me një fanelë të nënshkruar nga të gjithë pjesëtarët e ekipit për Tafa-S, për shërbimet që kanë kryer në mjetin e tij.",
    de: "Der ehemalige Torwart von SC Gjilani, Enea Koliqi, ehrt das Personal mit einem von allen Teammitgliedern signierten Trikot für Tafa-S, für die geleisteten Dienste an seinem Fahrzeug.",
    tr: "Eski SC Gjilani kalecisi Enea Koliqi, aracına verdikleri hizmetler için Tafa-S için tüm takım üyeleri tarafından imzalı bir forma ile personele onur verir.",
    fr: "L'ancien gardien de but du SC Gjilani, Enea Koliqi, honore le personnel avec un maillot signé par tous les membres de l'équipe pour Tafa-S, pour les services qu'ils ont rendus à son véhicule.",
    it: "L'ex portiere dello SC Gjilani, Enea Koliqi, onora il personale con una maglia firmata da tutti i membri della squadra per Tafa-S, per i servizi resi al suo veicolo.",
    es: "El ex portero del SC Gjilani, Enea Koliqi, honra al personal con una camiseta firmada por todos los miembros del equipo para Tafa-S, por los servicios que han realizado en su vehículo.",
    pt: "O ex-goleiro do SC Gjilani, Enea Koliqi, honra a equipe com uma camisa assinada por todos os membros da equipe para a Tafa-S, pelos serviços prestados em seu veículo.",
    gr: "Ο πρώην τερματοφύλακας της SC Gjilani, Ένεα Κολίκι, τιμά το προσωπικό με ένα μπλουζάκι που έχει υπογραφεί από όλα τα μέλη της ομάδας για την Tafa-S, για τις υπηρεσίες που έχουν προσφέρει στο όχημά του.",
    ru: "Бывший вратарь SC Gjilani, Энеа Колики, почетным образом награждает персонал майкой, подписанной всеми членами команды для Tafa-S, за предоставленные услуги на его автомобиле.",
    rs: "Bivši golman SC Gjilani, Enea Koliqi, časti osoblje potpisanim dresom svih članova tima za Tafa-S, za usluge koje su obavili na njegovom vozilu.",
  },
  signedJerseySeason: {
    en: "Signed Jersey: Season 2022/2023",
    al: "Fanela e Nënshkruar: Sezoni 2022/2023",
    de: "Signiertes Trikot: Saison 2022/2023",
    tr: "İmzalı Forma: 2022/2023 Sezonu",
    fr: "Maillot signé : Saison 2022/2023",
    it: "Maglia Firmata: Stagione 2022/2023",
    es: "Camiseta firmada: Temporada 2022/2023",
    pt: "Camisa assinada: Temporada 2022/2023",
    gr: "Υπογεγραμμένη μπλούζα: Σεζόν 2022/2023",
    ru: "Подписанный джерси: Сезон 2022/2023 года",
    rs: "Potpisani dres: Sezona 2022/2023",
  },
  scGjilaniJersey: {
    en: "SC Gjilani Jersey signed by all team players - Season 2022/2023",
    al: "Fanella e SC Gjilanit e nënshkruar nga të gjithë lojtarët e ekipit - Sezona 2022/2023",
    de: "SC Gjilani Trikot von allen Teamspielern unterschrieben - Saison 2022/2023",
    tr: "Tüm takım oyuncuları tarafından imzalı SC Gjilani Forması - 2022/2023 Sezonu",
    fr: "Maillot SC Gjilani signé par tous les joueurs de l'équipe - Saison 2022/2023",
    it: "Maglia SC Gjilani firmata da tutti i giocatori della squadra - Stagione 2022/2023",
    es: "Camiseta SC Gjilani firmada por todos los jugadores del equipo - Temporada 2022/2023",
    pt: "Camisa SC Gjilani assinada por todos os jogadores da equipe - Temporada 2022/2023",
    gr: "Η μπλούζα της SC Gjilani υπογράφεται από όλους τους παίκτες της ομάδας - Σεζόν 2022/2023",
    ru: "Футболка SC Gjilani, подписанная всеми игроками команды - Сезон 2022/2023",
    rs: "Dres SC Gjilani potpisan od svih igrača tima - Sezona 2022/2023",

  },
  companySupport: {
    en: "Our company alongside S.C. Gjilani on the football field",
    al: "Kompania jonë në krah të S.C. Gjilani në fushën e futbollit",
    de: "Unsere Firma an der Seite von S.C. Gjilani auf dem Fußballplatz",
    tr: "Futbol sahasında S.C. Gjilani ile yan yana olan şirketimiz",
    fr: "Notre entreprise aux côtés de S.C. Gjilani sur le terrain de football",
    it: "La nostra azienda insieme a S.C. Gjilani sul campo da calcio",
    es: "Nuestra empresa junto a S.C. Gjilani en el campo de fútbol",
    pt: "Nossa empresa ao lado do S.C. Gjilani no campo de futebol",
    gr: "Η εταιρεία μας στο πλευρό της S.C. Gjilani στο ποδοσφαιρικό γήπεδο",
    ru: "Наша компания на стороне S.C. Gjilani на футбольном поле",
    rs: "Naša kompanija uz S.C. Gjilani na fudbalskom terenu",
  },
  helpForBoxer: {
    en: "Help for the Boxer: A Path in Career",
    al: "Ndihma për Boksierin: Një Udhë në Karrierë",
    de: "Hilfe für den Boxer: Ein Weg in der Karriere",
    tr: "Boksör için Yardım: Kariyer Yolculuğu",
    fr: "Aide pour le Boxeur : Un Chemin dans la Carrière",
    it: "Aiuto per il Pugile: Un Percorso nella Carriera",
    es: "Ayuda para el Boxeador: Un Camino en la Carrera",
    pt: "Ajuda para o Boxeador: Um Caminho na Carreira",
    gr: "Βοήθεια για τον Πυγμάχο: Ένα Μονοπάτι στην Καριέρα",
    ru: "Помощь Боксеру: Путь в Карьере",
    rs: "Pomoć za Boksera: Put u Karijeri",
  },
  supportForLeotrimRexhepi: {
    en: "As a sign of support for boxer Leotrim Rexhepi, for financial support and continuous assistance in his professional boxing career.",
    al: "Në shënjë përkrahjeje për boksierin Leotrim Rexhepi, për mbështetjen financiare dhe ndihmën e vazhdueshme në karrierën profesionale në boksin profesional.",
    de: "Als Zeichen der Unterstützung für den Boxer Leotrim Rexhepi, für finanzielle Unterstützung und kontinuierliche Hilfe in seiner professionellen Boxkarriere.",
    tr: "Boksör Leotrim Rexhepi'ye destek olarak, profesyonel boks kariyerindeki maddi destek ve sürekli yardımın bir işareti olarak.",
    fr: "En signe de soutien au boxeur Leotrim Rexhepi, pour un soutien financier et une assistance continue dans sa carrière de boxe professionnelle.",
    it: "In segno di sostegno al pugile Leotrim Rexhepi, per il sostegno finanziario e l'assistenza continua nella sua carriera nel pugilato professionistico.",
    es: "Como muestra de apoyo al boxeador Leotrim Rexhepi, por el apoyo financiero y la asistencia continua en su carrera profesional en el boxeo.",
    pt: "Como sinal de apoio ao boxeador Leotrim Rexhepi, pelo apoio financeiro e assistência contínua em sua carreira profissional no boxe.",
    gr: "Ως ένδειξη υποστήριξης για τον πυγμάχο Leotrim Rexhepi, για οικονομική υποστήριξη και συνεχή βοήθεια στην επαγγελματική του καριέρα στο μποξ.",
    ru: "В знак поддержки боксёру Леотриму Рексхепи, за финансовую поддержку и непрерывную помощь в его профессиональной боксерской карьере.",
    rs: "Kao znak podrške bokseru Leotrimu Rexhepiju, za finansijsku podršku i kontinuiranu pomoć u njegovoj profesionalnoj bokserskoj karijeri.",
  },
  gratitudeFromLeotrimRexhepi: {
    en: "Gratitude from Leotrim Rexhepi",
    al: "Mirënjohje nga Leotrim Rexhepi",
    de: "Dankbarkeit von Leotrim Rexhepi",
    tr: "Leotrim Rexhepi'den Minnettarlık",
    fr: "Gratitude de Leotrim Rexhepi",
    it: "Riconoscenza da Leotrim Rexhepi",
    es: "Gratitud de Leotrim Rexhepi",
    pt: "Gratidão de Leotrim Rexhepi",
    gr: "Ευγνωμοσύνη από τον Leotrim Rexhepi",
    ru: "Благодарность от Леотрима Рексхепи",
    rs: "Zahvalnost od Leotrim Rexhepi",
  },
  footerCopyright: {
    en: "TAFA-S, Inc. All rights reserved.",
    al: "TAFA-S, Inc. Të gjitha të drejtat e rezervuara.",
    de: "TAFA-S, Inc. Alle Rechte vorbehalten.",
    tr: "TAFA-S, Inc. Tüm hakları saklıdır.",
    fr: "TAFA-S, Inc. Tous droits réservés.",
    it: "TAFA-S, Inc. Tutti i diritti riservati.",
    es: "TAFA-S, Inc. Todos los derechos reservados.",
    pt: "TAFA-S, Inc. Todos os direitos reservados.",
    gr: "TAFA-S, Inc. Με επιφύλαξη παντός δικαιώματος.",
    ru: "TAFA-S, Inc. Все права защищены.",
    rs: "TAFA-S, Inc. Sva prava zadržana.",
  },
};