import Contact from "./Contact";
import Part from "./Part";

export default function Home() {
  return (
    <div>
      <Part />
      <Contact />
    </div>
  );
}
