import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import { getMessage } from "./Util";

import project_1 from "./gallery/91.webp";
import project_2 from "./gallery/88.webp";
import project_3 from "./gallery/90.webp";
import project_4 from "./gallery/92.webp";
import project_5 from "./gallery/98.webp";

import themeluesi_1 from "./gallery/138.webp";

import themeluesi_second_1 from "./gallery/199.webp";

const themeluesData = [
  {
    image: themeluesi_1,
    className:
      "h-96 w-96 sm:h-64 sm:w-64 object-cover object-center rounded cursor-pointer",
  },
];

const themeluesDataSecond = [
  {
    image: themeluesi_second_1,
    className:
      "h-96 w-96 sm:h-64 sm:w-64 object-cover object-center rounded cursor-pointer",
  },
];

const projectData = [
  {
    image: project_1,
    className:
      "h-64 w-96 object-cover object-center rounded shadow-md cursor-pointer",
  },
  {
    image: project_2,
    className:
      "h-64 w-96 object-cover object-center rounded shadow-md cursor-pointer",
  },
  {
    image: project_3,
    className:
      "h-64 w-96 object-cover object-center rounded shadow-md cursor-pointer",
  },
  {
    image: project_4,
    className:
      "h-64 w-96 object-cover object-center rounded shadow-md cursor-pointer",
  },
  {
    image: project_5,
    className:
      "h-64 w-96 object-cover object-center rounded shadow-md cursor-pointer",
  },
];

export default function Blog() {
  return (
    <section className="text-gray-600 max-w-7xl mx-auto p-8">
      <div className="flex flex-wrap justify-center md:justify-start gap-4">
        <PhotoProvider>
          {themeluesData?.map((row, index) => {
            return (
              <PhotoView src={row.image}
                         key={index}>
                <img
                  alt="hero"
                  className={row?.className}
                  src={row?.image}
                />
              </PhotoView>
            );
          })}
        </PhotoProvider>
      </div>
      <div className="mt-3">
        <h1 className="font-bold sm:text-3xl text-2xl mb-4 text-[#ce1b1b]">
          {getMessage("HistoryFoundation")}
        </h1>
        <h1 className="font-bold sm:text-2xl text-xl mb-4 text-[#ce1b1b]">
          TAFA-S sh.p.k
        </h1>
        <p className="mb-8 leading-relaxed ">
          {getMessage("HistoryFirstParagraph")}
        </p>
      </div>
      <div className="mt-3">
        <p className="mb-8 leading-relaxed ">
          {getMessage("HistorySecondParagraph")}
        </p>
      </div>
      <div className="flex flex-wrap justify-center sm:justify-start gap-4">
        <div className="flex flex-wrap justify-center sm:justify-start gap-4">
          <PhotoProvider>
            {themeluesDataSecond?.map((row, index) => {
              return (
                <div  key={index}>
                  <PhotoView src={row.image}
                            >
                    <img
                      alt={"test" + index}
                      className={row?.className}
                      src={row?.image}
                    />
                  </PhotoView>
                  <p className="text-xs max-w-96 sm:max-w-64">
                    {getMessage("imageDesc")}
                  </p>
                </div>
              );
            })}
          </PhotoProvider>
        </div>
      </div>
      <div className="mt-3">
        <p className="leading-relaxed ">
          {getMessage("HistoryThirdParagraph")}
        </p>
      </div>
      <div className="mt-3">
        <p className="leading-relaxed ">
          {getMessage("HistoryFourthParagraph")}
        </p>
      </div>

      {/*  */}
      <div className="my-8 relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 bg-white text-sm text-gray-500"> + </span>
        </div>
      </div>
      {/*  */}

      <div className="mt-3">
        <h1 className="font-bold sm:text-2xl text-xl mb-4 text-[#ce1b1b]">
          {getMessage("Mission")}
        </h1>
        <p className="mb-8 leading-relaxed">{getMessage("MissionOne")}</p>
      </div>

      <div className="mt-3">
        <p className="mb-8 leading-relaxed">{getMessage("MissionTwo")}</p>
      </div>
      <div className="mt-3">
        <p className="mb-8 leading-relaxed">{getMessage("MissionThree")}</p>
      </div>
      <div className="mt-3">
        <p className="mb-8 leading-relaxed">{getMessage("MissionFour")}</p>
      </div>
      <div className="mt-3">
        <p className="mb-8 leading-relaxed">{getMessage("MissionFive")}</p>
      </div>

      {/*  */}
      <div className="my-8 relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 bg-white text-sm text-gray-500"> + </span>
        </div>
      </div>
      {/*  */}

      <div className="mt-3">
        <h1 className="font-bold sm:text-2xl text-xl mb-4 text-[#ce1b1b]">
          Vision
        </h1>
        <p className="mb-8 leading-relaxed ">{getMessage("VisionOne")}</p>
      </div>

      <div className="mt-3">
        <p className="mb-8 leading-relaxed ">{getMessage("VisionTwo")}</p>
      </div>
      <div className="mt-3">
        <p className="mb-8 leading-relaxed ">{getMessage("VisionThree")}</p>
      </div>
      <div className="mt-3">
        <p className="mb-8 leading-relaxed ">{getMessage("VisionFour")}</p>
      </div>
      <div className="mt-3">
        <p className="mb-8 leading-relaxed ">{getMessage("VisionFive")}</p>
      </div>
      <div className="mt-3">
        <p className="mb-8 leading-relaxed ">{getMessage("VisionSix")}</p>
      </div>

      {/*  */}
      <div className="my-8 relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 bg-white text-sm text-gray-500"> + </span>
        </div>
      </div>
      {/*  */}

      <div className="mt-3">
        <h1 className="font-bold sm:text-2xl text-xl mb-4 text-[#ce1b1b]">
          {getMessage("CompanyProjectTitle")}
        </h1>
        <p className="mb-8 leading-relaxed ">
          {getMessage("CompanyProjectDesc")}
        </p>
      </div>
      <div className="flex flex-wrap justify-center sm:justify-start gap-4">
        <PhotoProvider>
          {projectData?.map((row, index) => {
            return (
              <PhotoView src={row.image}
                         key={index}>
                <img
                  alt={"test" + index}
                  className={row?.className}
                  src={row?.image}
                />
              </PhotoView>
            );
          })}
        </PhotoProvider>
      </div>
    </section>
  );
}
