import { Link } from "react-router-dom";
import tafasBanner from "./tafa-s-banner-2.jpg";
import tafasSecondBanner from "./tafa-s-banner-1.jpeg";
import autoOneBanner from "./tafa-s-banner-3.jpeg";

export default function Part() {
  return (
    <div className="relative bg-gray-50">
      <main className="relative mx-auto">
        <Link
          to="/company"
          className="mx-auto justify-between h-[calc(100vh-50px)] cursor-pointer hidden sm:flex"
        >
          <div className="transition-all ease-in w-1/2 relative overflow-hidden hover:w-full shadow-[0px_0px_8px_#232931]">
            <img
              src={tafasSecondBanner}
              alt={"Image 1"}
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
          <div className="transition-all w-1/2 relative overflow-hidden hover:w-full shadow-[0px_0px_8px_#232931]">
            <img
              src={tafasBanner}
              alt="Image 2"
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
          <div className="transition-all w-1/2 relative overflow-hidden hover:w-full shadow-[0px_0px_8px_#232931]">
            <img
              src={autoOneBanner}
              alt="Image 3"
              className="absolute inset-0 w-full h-full object-cover object-top"
            />
          </div>
        </Link>

        {/* MOBILE */}
        <Link
          to="/company"
          className="h-[calc(100vh-75px)] cursor-pointer sm:hidden flex flex-col"
        >
          <img
            src={tafasSecondBanner}
            alt={"Image 1"}
            className="h-1/3 w-full object-cover object-center shadow-2xl"
          />
          <img
            src={tafasBanner}
            alt="Image 2"
            className="h-1/3 w-full object-cover object-top shadow-2xl"
          />
          <img
            src={autoOneBanner}
            alt="Image 3"
            className="h-1/3 w-full object-cover object-top shadow-2xl"
          />
        </Link>
      </main>
    </div>
  );
}
